;(function(){
'use strict';

Object.defineProperty(exports, "__esModule", {
  value: true
});

var _baseListsmall = require('../../../../base/baseListsmall');

var _baseListsmall2 = _interopRequireDefault(_baseListsmall);

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

exports.default = {
  name: 'inz-admin-onlineuser-list',
  extends: _baseListsmall2.default,
  methods: {
    open: function open(id) {
      this.$emit('open', id);
    }
  },
  computed: {
    fields: function fields() {
      return [{ key: 'name', sortable: true, label: 'th.name' }, { key: 'email', sortable: true, label: 'th.email' }, { key: 'date_last_hit', sortable: true, label: 'th.dateLatestLogin' }, { key: 'rm', sortable: true, class: 'i-cell-icon' }];
    }
  }
};
})()
if (module.exports.__esModule) module.exports = module.exports.default
var __vue__options__ = (typeof module.exports === "function"? module.exports.options: module.exports)
if (__vue__options__.functional) {console.error("[vueify] functional components are not supported and should be defined in plain js files using render functions.")}
__vue__options__.render = function render () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return (_vm.filtered.length)?_c('b-table',{staticClass:"inz-table-list i-listsmall",attrs:{"responsive":"","hover":"","items":_vm.filtered,"fields":_vm.fieldsTr},scopedSlots:_vm._u([{key:"cell(name)",fn:function(data){return [_c('div',{staticClass:"clickable",domProps:{"innerHTML":_vm._s(data.value)},on:{"click":function($event){return _vm.open(data.item.id)}}})]}},{key:"cell(email)",fn:function(data){return [_c('div',{staticClass:"clickable",domProps:{"innerHTML":_vm._s(data.value)},on:{"click":function($event){return _vm.open(data.item.id)}}})]}},{key:"cell(date_last_hit)",fn:function(data){return [_c('div',{staticClass:"clickable",on:{"click":function($event){return _vm.open(data.item.id)}}},[_vm._v("\n      "+_vm._s(_vm._f("iDateTime")(data.value))+"\n    ")])]}}],null,false,155751838)}):_vm._e()}
__vue__options__.staticRenderFns = []
if (module.hot) {(function () {  var hotAPI = require("vue-hot-reload-api")
  hotAPI.install(require("vue"), true)
  if (!hotAPI.compatible) return
  module.hot.accept()
  if (!module.hot.data) {
    hotAPI.createRecord("data-v-9e796ec6", __vue__options__)
  } else {
    hotAPI.reload("data-v-9e796ec6", __vue__options__)
  }
})()}