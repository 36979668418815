;(function(){
'use strict';

Object.defineProperty(exports, "__esModule", {
  value: true
});

var _logo = require('../components/logo');

var _logo2 = _interopRequireDefault(_logo);

var _cf = require('../config/cf');

var _xhr = require('../service/xhr');

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

exports.default = {
  name: 'inz-about',
  components: { InzLogo: _logo2.default },
  methods: {
    load: function load() {
      var _this = this;

      _xhr.general.usage().then(function (r) {
        return _this.$set(_this, 'loadData', r.data.load);
      });
    }
  },
  data: function data() {
    return {
      loadData: null
    };
  },
  mounted: function mounted() {
    var _this2 = this;

    this.$root.$on('bv::modal::show', function () {
      return _this2.load();
    });
  },

  computed: {
    infos: function infos() {
      return [{ label: 'label.version', value: this.$store.state.version }, { label: 'label.lastUpdated', value: this.$store.state.lastUpdate, type: 'date' }, { label: 'label.license', value: _cf.cf.licenseFor }, { label: 'label.machineLoad', value: this.loadData, type: 'load' }];
    }
  }
};
})()
if (module.exports.__esModule) module.exports = module.exports.default
var __vue__options__ = (typeof module.exports === "function"? module.exports.options: module.exports)
if (__vue__options__.functional) {console.error("[vueify] functional components are not supported and should be defined in plain js files using render functions.")}
__vue__options__.render = function render () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('b-modal',{attrs:{"id":"modalAbout","title":"About INZURO ADMIN","ok-only":"","button-size":"sm"}},[_c('div',{staticClass:"i-t-center mb-2"},[_c('inz-logo',{attrs:{"w":190}})],1),_vm._v(" "),_c('inz-infolist',{attrs:{"infos":_vm.infos}}),_vm._v(" "),_c('p',{staticClass:"mv-1",domProps:{"innerHTML":_vm._s(_vm.$t('about'))}})],1)}
__vue__options__.staticRenderFns = []
if (module.hot) {(function () {  var hotAPI = require("vue-hot-reload-api")
  hotAPI.install(require("vue"), true)
  if (!hotAPI.compatible) return
  module.hot.accept()
  if (!module.hot.data) {
    hotAPI.createRecord("data-v-c1d79af2", __vue__options__)
  } else {
    hotAPI.reload("data-v-c1d79af2", __vue__options__)
  }
})()}