/**
 * Permission check based on user role
 */
import utils from './utils';

const adminRoles = ['developer', 'super_admin'];
const devRoles = ['developer'];

export const security = {
	role() {
		const r = ((utils.u() || {}).role) + '';
		return r.toLowerCase().replace('role_', '');
	},
	mayView() {
		return true
	},
	mayAdd() {
		return adminRoles.indexOf(this.role()) > -1;
	},
	mayEdit() {
		return adminRoles.indexOf(this.role()) > -1;
	},
	mayRm() {
		return adminRoles.indexOf(this.role()) > -1;
	},
	isAdmin() {
		return adminRoles.indexOf(this.role()) > -1;
	},
	isDev() {
		return devRoles.indexOf(this.role()) > -1;
	}
}