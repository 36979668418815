;(function(){
'use strict';

Object.defineProperty(exports, "__esModule", {
  value: true
});

var _icons = require('../config/icons');

exports.default = {
  name: 'inzModuleHeader',
  props: {
    btnBackShow: { default: false },
    btnAddShow: { default: false },
    btnAddAction: String,
    btnAddTooltip: String,
    title: {},
    subtitle: {},
    type: String
  },
  methods: {
    evAdd: function evAdd() {
      this.$emit('add');
    }
  },
  computed: {
    typeIcon: function typeIcon() {
      var cases = _icons.icons;
      if (this.type in cases) {
        return cases[this.type];
      }
      return null;
    }
  }
};
})()
if (module.exports.__esModule) module.exports = module.exports.default
var __vue__options__ = (typeof module.exports === "function"? module.exports.options: module.exports)
if (__vue__options__.functional) {console.error("[vueify] functional components are not supported and should be defined in plain js files using render functions.")}
__vue__options__.render = function render () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"inz-module-header"},[_c('div',{staticClass:"inz-module-header--back"},[(_vm.btnBackShow)?_c('inz-btn',{attrs:{"icon":"arrow-left"}}):_vm._e(),_vm._v(" "),_vm._t("left")],2),_vm._v(" "),_c('div',{staticClass:"inz-module-header--title f-auto"},[(_vm.title)?_c('h2',[(_vm.typeIcon)?_c('span',[_c('fa',{attrs:{"icon":_vm.typeIcon}})],1):_vm._e(),_vm._v("\n      "+_vm._s(_vm.title)+"\n      "),(_vm.subtitle)?_c('small',{staticClass:"i-hl"},[_vm._v(" "+_vm._s(_vm.subtitle))]):_vm._e()]):_vm._e(),_vm._v(" "),_vm._t("title")],2),_vm._v(" "),_c('div',{staticClass:"inz-module-header--right"},[_vm._t("right")],2),_vm._v(" "),(_vm.btnAddShow)?_c('div',{staticClass:"inz-module-header--actions ml-1"},[(_vm.btnAddShow)?_c('inz-btn',{directives:[{name:"b-tooltip",rawName:"v-b-tooltip.hover",modifiers:{"hover":true}}],attrs:{"variant":"primary","title":_vm.btnAddTooltip,"icon":"plus"},on:{"click":_vm.evAdd}}):_vm._e()],1):_vm._e()])}
__vue__options__.staticRenderFns = []
if (module.hot) {(function () {  var hotAPI = require("vue-hot-reload-api")
  hotAPI.install(require("vue"), true)
  if (!hotAPI.compatible) return
  module.hot.accept()
  if (!module.hot.data) {
    hotAPI.createRecord("data-v-50e2be66", __vue__options__)
  } else {
    hotAPI.reload("data-v-50e2be66", __vue__options__)
  }
})()}