;(function(){
'use strict';

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = {
  name: 'inz-records',
  props: {
    num: {},
    label: String,
    withLabel: { default: true }
  },
  computed: {
    defaultLabel: function defaultLabel() {
      return this.$i18n.t('records');
    }
  }
};
})()
if (module.exports.__esModule) module.exports = module.exports.default
var __vue__options__ = (typeof module.exports === "function"? module.exports.options: module.exports)
if (__vue__options__.functional) {console.error("[vueify] functional components are not supported and should be defined in plain js files using render functions.")}
__vue__options__.render = function render () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"inz-records"},[_c('span',{staticClass:"v"},[_vm._v(_vm._s(_vm._f("iNumber")(_vm.num)))]),_vm._v(" "),(_vm.withLabel)?_c('span',{staticClass:"l",domProps:{"innerHTML":_vm._s(_vm.label||_vm.defaultLabel)}}):_vm._e()])}
__vue__options__.staticRenderFns = []
if (module.hot) {(function () {  var hotAPI = require("vue-hot-reload-api")
  hotAPI.install(require("vue"), true)
  if (!hotAPI.compatible) return
  module.hot.accept()
  if (!module.hot.data) {
    hotAPI.createRecord("data-v-24718685", __vue__options__)
  } else {
    hotAPI.reload("data-v-24718685", __vue__options__)
  }
})()}