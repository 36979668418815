;(function(){
'use strict';

Object.defineProperty(exports, "__esModule", {
  value: true
});

var _baseModal = require('../../../../base/baseModal');

var _baseModal2 = _interopRequireDefault(_baseModal);

var _xhr = require('../../../../service/xhr');

var _utils = require('../../../../service/utils');

var _utils2 = _interopRequireDefault(_utils);

var _lodash = require('lodash');

var _ = _interopRequireWildcard(_lodash);

var _validator = require('./validator');

var _validator2 = _interopRequireDefault(_validator);

function _interopRequireWildcard(obj) { if (obj && obj.__esModule) { return obj; } else { var newObj = {}; if (obj != null) { for (var key in obj) { if (Object.prototype.hasOwnProperty.call(obj, key)) newObj[key] = obj[key]; } } newObj.default = obj; return newObj; } }

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

exports.default = {
  name: 'd-insurance-type-edit',
  extends: _baseModal2.default,
  props: {
    insuranceTypes: Array
  },
  data: function data() {
    return {
      rec: null,
      slugValid: null,
      slugError: null
    };
  },

  computed: {
    slugs: function slugs() {
      var _this = this;

      if (_.size(this.insuranceTypes)) {
        return this.insuranceTypes.filter(function (it) {
          return it.id !== _this.rec.id;
        }).map(function (it) {
          return it.slug;
        });
      }
      return [];
    }
  },
  methods: {
    validateSlug: function validateSlug() {
      var err = _validator2.default.validateSlug(this.rec.slug, this.slugs);
      var isValid = err.length === 0;

      this.$set(this, 'slugValid', isValid);
      this.$set(this, 'slugError', err.join(', '));

      return isValid;
    },
    validate: function validate() {
      return this.validateSlug();
    },
    open: function open(it) {
      if (!it) {
        it = {
          id: null,
          name: 'neu',
          slug: 'neu'
        };
      }

      this.isVisible = true;
      this.$set(this, 'rec', it);
    },
    submit: function submit() {
      var _this2 = this;

      if (!this.validate()) {
        return;
      }

      this.close();

      (this.rec.id > 0 ? _xhr.admin.insuranceTypeUpdate(this.rec.id, this.rec) : _xhr.admin.insuranceTypeCeate(this.rec)).then(function () {
        _utils2.default.toast(_this2.$i18n.t('toastSuccess.save'), true);
        _this2.$emit('reload');
      }).catch(function (e) {
        return _utils2.default.handleXhrError(e);
      });
    }
  }
};
})()
if (module.exports.__esModule) module.exports = module.exports.default
var __vue__options__ = (typeof module.exports === "function"? module.exports.options: module.exports)
if (__vue__options__.functional) {console.error("[vueify] functional components are not supported and should be defined in plain js files using render functions.")}
__vue__options__.render = function render () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('b-modal',{attrs:{"title":"Versicherungs Art"},scopedSlots:_vm._u([{key:"modal-footer",fn:function(){return [_c('inz-btn',{attrs:{"size":"sm","variant":"default","label":_vm.$t('btn.cancel')},on:{"click":_vm.close}}),_vm._v(" "),_c('inz-btn',{attrs:{"size":"sm","variant":"primary","label":_vm.$t('btn.ok'),"icon":"check"},on:{"click":_vm.submit}})]},proxy:true}]),model:{value:(_vm.isVisible),callback:function ($$v) {_vm.isVisible=$$v},expression:"isVisible"}},[(_vm.rec)?_c('div',[_c('inz-control-input',{ref:"slug",attrs:{"label":"Slug","required":true,"state":_vm.slugValid},on:{"blur":_vm.validateSlug},model:{value:(_vm.rec.slug),callback:function ($$v) {_vm.$set(_vm.rec, "slug", $$v)},expression:"rec.slug"}},[_c('span',{attrs:{"slot":"error"},domProps:{"innerHTML":_vm._s(_vm.slugError)},slot:"error"})]),_vm._v(" "),_c('inz-control-input',{ref:"name",attrs:{"label":"Display Name","required":true},model:{value:(_vm.rec.name),callback:function ($$v) {_vm.$set(_vm.rec, "name", $$v)},expression:"rec.name"}})],1):_vm._e()])}
__vue__options__.staticRenderFns = []
if (module.hot) {(function () {  var hotAPI = require("vue-hot-reload-api")
  hotAPI.install(require("vue"), true)
  if (!hotAPI.compatible) return
  module.hot.accept()
  if (!module.hot.data) {
    hotAPI.createRecord("data-v-cd6fd704", __vue__options__)
  } else {
    hotAPI.reload("data-v-cd6fd704", __vue__options__)
  }
})()}