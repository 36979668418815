;(function(){
'use strict';

Object.defineProperty(exports, "__esModule", {
  value: true
});

var _R = require('../../../../../service/R');

exports.default = {
  name: 'inz-contact-line',
  props: { rec: Object },
  computed: {
    icon: function icon() {
      return this.rec.isCompany === true ? 'building' : 'user';
    },
    displayName: function displayName() {
      var maxLen = 30;
      var name = this.rec.displayName || this.rec.name;
      return name.length > maxLen ? name.substring(0, maxLen) + '…' : name;
    }
  },
  methods: {
    open: function open() {
      _R.R.contactDetail(this.rec.id);
    }
  }
};
})()
if (module.exports.__esModule) module.exports = module.exports.default
var __vue__options__ = (typeof module.exports === "function"? module.exports.options: module.exports)
if (__vue__options__.functional) {console.error("[vueify] functional components are not supported and should be defined in plain js files using render functions.")}
__vue__options__.render = function render () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return (_vm.rec)?_c('span',{staticClass:"inz-contact-line",on:{"click":_vm.open}},[_c('fa',{attrs:{"icon":_vm.icon}}),_vm._v(" "+_vm._s(_vm.displayName)+"\n")],1):_vm._e()}
__vue__options__.staticRenderFns = []
if (module.hot) {(function () {  var hotAPI = require("vue-hot-reload-api")
  hotAPI.install(require("vue"), true)
  if (!hotAPI.compatible) return
  module.hot.accept()
  if (!module.hot.data) {
    hotAPI.createRecord("data-v-611d5e58", __vue__options__)
  } else {
    hotAPI.reload("data-v-611d5e58", __vue__options__)
  }
})()}