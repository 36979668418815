;(function(){
'use strict';

Object.defineProperty(exports, "__esModule", {
  value: true
});

var _baseControl = require('../../../../../base/baseControl');

var _baseControl2 = _interopRequireDefault(_baseControl);

var _form = require('./form');

var _form2 = _interopRequireDefault(_form);

var _contact = require('../contact');

var _contact2 = _interopRequireDefault(_contact);

var _basics = require('../contact/form/basics');

var _basics2 = _interopRequireDefault(_basics);

var _contact3 = require('../contact/form/contact');

var _contact4 = _interopRequireDefault(_contact3);

var _address = require('../contact/form/address');

var _address2 = _interopRequireDefault(_address);

var _birthday = require('../contact/form/birthday');

var _birthday2 = _interopRequireDefault(_birthday);

var _expandable = require('../../../../../components/expandable');

var _expandable2 = _interopRequireDefault(_expandable);

var _iban = require('../contact/form/iban');

var _iban2 = _interopRequireDefault(_iban);

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

exports.default = {
  extends: _baseControl2.default,
  name: 'inz-export-claim-editor',
  components: {
    InzContactFormIban: _iban2.default,
    InzExpandable: _expandable2.default,
    InzContactFormBirthday: _birthday2.default,
    InzContactFormAddress: _address2.default, InzContactFormContact: _contact4.default, InzContactFormBasics: _basics2.default, InzExportContactEditor: _contact2.default, InzClaimForm: _form2.default
  }
};
})()
if (module.exports.__esModule) module.exports = module.exports.default
var __vue__options__ = (typeof module.exports === "function"? module.exports.options: module.exports)
if (__vue__options__.functional) {console.error("[vueify] functional components are not supported and should be defined in plain js files using render functions.")}
__vue__options__.render = function render () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return (_vm.val)?_c('div',{staticClass:"inz-export-claim-editor"},[_c('inz-box',{attrs:{"variant":"white"}},[_c('inz-expandable',{attrs:{"title":"Partner"}},[_c('template',{slot:"content"},[_c('inz-contact-form-contact',{model:{value:(_vm.val.partner),callback:function ($$v) {_vm.$set(_vm.val, "partner", $$v)},expression:"val.partner"}}),_vm._v(" "),_c('inz-contact-form-iban',{model:{value:(_vm.val.partner),callback:function ($$v) {_vm.$set(_vm.val, "partner", $$v)},expression:"val.partner"}})],1)],2),_vm._v(" "),_c('inz-expandable',{attrs:{"title":"Kunde"}},[_c('template',{slot:"content"},[_c('inz-contact-form-basics',{model:{value:(_vm.val.customer),callback:function ($$v) {_vm.$set(_vm.val, "customer", $$v)},expression:"val.customer"}}),_vm._v(" "),_c('inz-contact-form-contact',{model:{value:(_vm.val.customer),callback:function ($$v) {_vm.$set(_vm.val, "customer", $$v)},expression:"val.customer"}}),_vm._v(" "),_c('inz-contact-form-address',{model:{value:(_vm.val.customer),callback:function ($$v) {_vm.$set(_vm.val, "customer", $$v)},expression:"val.customer"}}),_vm._v(" "),_c('inz-contact-form-birthday',{model:{value:(_vm.val.customer),callback:function ($$v) {_vm.$set(_vm.val, "customer", $$v)},expression:"val.customer"}})],1)],2),_vm._v(" "),_c('inz-expandable',{attrs:{"title":"Details zum Schaden"}},[_c('template',{slot:"content"},[_c('inz-claim-form',{model:{value:(_vm.val),callback:function ($$v) {_vm.val=$$v},expression:"val"}})],1)],2)],1)],1):_vm._e()}
__vue__options__.staticRenderFns = []
if (module.hot) {(function () {  var hotAPI = require("vue-hot-reload-api")
  hotAPI.install(require("vue"), true)
  if (!hotAPI.compatible) return
  module.hot.accept()
  if (!module.hot.data) {
    hotAPI.createRecord("data-v-cb2d980c", __vue__options__)
  } else {
    hotAPI.reload("data-v-cb2d980c", __vue__options__)
  }
})()}