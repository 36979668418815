/**
 * DEV use this to adjust bootstrap behaviour
 * while development. this file is *NOT* going live!
 */
import $router from './config/router'
import * as _ from 'lodash';
import {R} from './service/R';

const start = 'podium';

export default {
	init() {
		console.warn('DEV INIT');
	},
	gotoStart() {
		$router.push({name: start});
	},
	imageEditor() {
		R.exportEdit({
			type: 'claimDamagedObject',
			id: 1924,
			remoteId: 47639,
		})
	},
	loginWithNew(fn) {
		account.login({email: "jvo.maurer@nxtlvl.ch", password: "552024"}, () => {
			$router.push({name: start});
			if (_.isFunction(fn)) {
				fn();
			}
		});
	},
}