;(function(){
'use strict';

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = {
  name: 'inz-title',
  props: {
    title: {},
    subtitle: {},
    size: { default: 'default' },
    showGlobe: { default: false }
  },
  computed: {
    hasSubtitleSlot: function hasSubtitleSlot() {
      return !!this.$slots.title;
    },
    hasTitleSlot: function hasTitleSlot() {
      return !!this.$slots.title;
    },
    hasRightSlot: function hasRightSlot() {
      return !!this.$slots.right;
    },
    hasActionsSlot: function hasActionsSlot() {
      return !!this.$slots.actions;
    }
  }
};
})()
if (module.exports.__esModule) module.exports = module.exports.default
var __vue__options__ = (typeof module.exports === "function"? module.exports.options: module.exports)
if (__vue__options__.functional) {console.error("[vueify] functional components are not supported and should be defined in plain js files using render functions.")}
__vue__options__.render = function render () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"inz-title d-flex justify-content-center align-items-center",class:'inz-title--'+_vm.size},[_c('div',{staticClass:"f-auto"},[_vm._v(_vm._s(_vm.title)+"\n    "),(_vm.hasTitleSlot)?_vm._t("title"):_vm._e(),_vm._v(" "),_c('small',{staticClass:"i-hl"},[_vm._v("\n      "+_vm._s(_vm.subtitle)+"\n      "),(_vm.hasSubtitleSlot)?_vm._t("subtitle"):_vm._e()],2),_vm._v(" "),(_vm.showGlobe)?_c('fa',{staticClass:"lang-globe",attrs:{"icon":"globe"}}):_vm._e()],2),_vm._v(" "),(_vm.hasRightSlot)?_c('div',{staticClass:"right"},[_vm._t("right")],2):_vm._e(),_vm._v(" "),(_vm.hasActionsSlot)?_c('div',{staticClass:"actions ml-1 d-flex"},[_vm._t("actions")],2):_vm._e()])}
__vue__options__.staticRenderFns = []
if (module.hot) {(function () {  var hotAPI = require("vue-hot-reload-api")
  hotAPI.install(require("vue"), true)
  if (!hotAPI.compatible) return
  module.hot.accept()
  if (!module.hot.data) {
    hotAPI.createRecord("data-v-ff5b834a", __vue__options__)
  } else {
    hotAPI.reload("data-v-ff5b834a", __vue__options__)
  }
})()}