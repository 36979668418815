;(function(){
'use strict';

Object.defineProperty(exports, "__esModule", {
  value: true
});

var _utils = require('../../service/utils');

var _utils2 = _interopRequireDefault(_utils);

var _lodash = require('lodash');

var _ = _interopRequireWildcard(_lodash);

function _interopRequireWildcard(obj) { if (obj && obj.__esModule) { return obj; } else { var newObj = {}; if (obj != null) { for (var key in obj) { if (Object.prototype.hasOwnProperty.call(obj, key)) newObj[key] = obj[key]; } } newObj.default = obj; return newObj; } }

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

exports.default = {
  name: 'inz-question-list',
  props: {
    recs: Array
  },
  methods: {
    icon: function icon(type) {
      var t = {
        range: 'exchange-alt',
        text: 'bold',
        bool: 'check'
      };
      return t[type];
    },
    edit: function edit(item) {
      this.$emit('edit', item);
    },
    confirmDelete: function confirmDelete() {
      var cf = _.defaults({
        title: 'Please Confirm'
      }, cf.defaultModal);
      this.$bvModal.msgBoxConfirm('Please confirm that you want to delete everything.', cf);
    },
    test: function test() {
      _utils2.default.confirm();
    }
  }
};
})()
if (module.exports.__esModule) module.exports = module.exports.default
var __vue__options__ = (typeof module.exports === "function"? module.exports.options: module.exports)
if (__vue__options__.functional) {console.error("[vueify] functional components are not supported and should be defined in plain js files using render functions.")}
__vue__options__.render = function render () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return (_vm.recs && _vm.recs.length>0)?_c('div',{staticClass:"inz-question-list"},[_c('div',{staticClass:"inz-question-list-item"},[_c('div',{staticClass:"inz-question-list-item--main",domProps:{"innerHTML":_vm._s(_vm.$t('th.question'))}}),_vm._v(" "),_c('div',{staticClass:"inz-question-list-item--right",domProps:{"innerHTML":_vm._s(_vm.$t('th.type'))}}),_vm._v(" "),_c('div',{staticClass:"inz-question-list-item--menu"})]),_vm._v(" "),_vm._l((_vm.recs),function(q,$i){return _c('div',{key:$i,staticClass:"inz-question-list-item i-hover",on:{"click":function($event){return _vm.edit(q)}}},[_c('div',{staticClass:"inz-question-list-item--icon"},[_c('fa',{attrs:{"icon":"question"}})],1),_vm._v(" "),_c('div',{staticClass:"inz-question-list-item--main"},[_c('p',{staticClass:"i-t-mute",domProps:{"innerHTML":_vm._s(q.question)}})]),_vm._v(" "),_c('div',{staticClass:"inz-question-list-item--right",domProps:{"innerHTML":_vm._s(_vm.$t('answerType.' + q.questionType))}}),_vm._v(" "),_c('div',{staticClass:"inz-question-list-item--menu"},[_c('fa',{attrs:{"icon":"ellipsis-v"}})],1)])})],2):_vm._e()}
__vue__options__.staticRenderFns = []
if (module.hot) {(function () {  var hotAPI = require("vue-hot-reload-api")
  hotAPI.install(require("vue"), true)
  if (!hotAPI.compatible) return
  module.hot.accept()
  if (!module.hot.data) {
    hotAPI.createRecord("data-v-381e84a6", __vue__options__)
  } else {
    hotAPI.reload("data-v-381e84a6", __vue__options__)
  }
})()}