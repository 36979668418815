;(function(){
'use strict';

Object.defineProperty(exports, "__esModule", {
  value: true
});

var _xhr = require('../../../../service/xhr');

var _header = require('../../../../components/panels/header');

var _header2 = _interopRequireDefault(_header);

var _utils = require('../../../../service/utils');

var _utils2 = _interopRequireDefault(_utils);

var _listsmall = require('../listsmall');

var _listsmall2 = _interopRequireDefault(_listsmall);

var _dView = require('../dView');

var _dView2 = _interopRequireDefault(_dView);

var _bus = require('../../../../service/bus');

var _dExport = require('../dExport');

var _dExport2 = _interopRequireDefault(_dExport);

var _filter = require('../filter');

var _filter2 = _interopRequireDefault(_filter);

var _modalDf = require('../../../../config/modalDf');

var _modalDf2 = _interopRequireDefault(_modalDf);

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

exports.default = {
  components: { InzFilterExport: _filter2.default, DExport: _dExport2.default, DExportlog: _dView2.default, InzAdminExportlogList: _listsmall2.default, InzPanelHeader: _header2.default },
  name: 'inz-panel-admin-exportlog',
  methods: {
    load: function load() {
      var _this = this;

      _xhr.exportLog.log().then(function (r) {
        return _this.$set(_this, 'records', r.data);
      }).catch(function (e) {
        return console.log(e);
      });
    },
    open: function open(it) {
      this.$refs.modal.open(it);
    },
    openExport: function openExport() {
      this.$refs.exporter.open();
    },
    redo: function redo(id) {
      var _this2 = this;

      _xhr.exportLog.redo(id).then(function (r) {
        return _this2.runCallback(r);
      }).catch(function (e) {
        return _utils2.default.handleXhrError(e);
      });
    },
    runCallback: function runCallback(r) {
      this.load();
      _bus.bus.$emit('adminSummaryReload');

      var d = r.data.total;
      var msg = d.processed + ' verarbeitet, davon ' + d.success + ' erfolgreich und ' + d.error + ' fehlerhaft.';
      _utils2.default.toast(msg, true);
    },
    remove: function remove(id) {
      var _this3 = this;

      _xhr.exportLog.remove(id).then(function () {
        _this3.load();
        _utils2.default.toast('Success', true);
      }).catch(function (e) {
        return _utils2.default.handleXhrError(e, 'delete');
      });
    },
    clear: function clear() {
      var _this4 = this;

      this.$bvModal.msgBoxConfirm('Alle export error reports löschen?', _modalDf2.default.confirm()).then(function (res) {
        if (_utils2.default.parseBool(res) !== true) {
          return false;
        }
        _this4.clearConfirmed();
      });
    },
    clearConfirmed: function clearConfirmed() {
      var _this5 = this;

      return _xhr.exportLog.clear().then(function () {
        _this5.load();
        _utils2.default.toast('Error reports gelöscht', true);
      }).catch(function (e) {
        return _utils2.default.handleXhrError(e, 'delete');
      });
    }
  },
  computed: {
    openLog: function openLog() {
      this.$router.push({ name: 'adminExport' });
    },
    canRun: function canRun() {
      return _utils2.default.xhr() !== true;
    },
    filteredRecords: function filteredRecords() {
      var _this6 = this;

      var records = this.records;
      if (this.filter.type) {
        records = records.filter(function (it) {
          return it.type === _this6.filter.type;
        });
      }
      return _utils2.default.filterRecords(records, this.filter.input);
    }
  },
  mounted: function mounted() {
    this.load();
  },
  data: function data() {
    return {
      records: null,
      filter: {
        input: '',
        type: null
      }
    };
  }
};
})()
if (module.exports.__esModule) module.exports = module.exports.default
var __vue__options__ = (typeof module.exports === "function"? module.exports.options: module.exports)
if (__vue__options__.functional) {console.error("[vueify] functional components are not supported and should be defined in plain js files using render functions.")}
__vue__options__.render = function render () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return (_vm.filteredRecords)?_c('div',{staticClass:"inz-admin-exportlog"},[_c('inz-module-header',{attrs:{"type":"export","title":"S2R Export Fehler"}},[_c('inz-actionbar',{attrs:{"slot":"right"},slot:"right"},[_c('inz-records',{staticClass:"mr-1",attrs:{"num":_vm.filteredRecords.length}}),_vm._v(" "),_c('inz-btn',{attrs:{"label":"Reports löschen…","icon":"remove","disabled":_vm.canRun===false,"size":"sm","variant":"secondary"},on:{"click":_vm.clear}}),_vm._v(" "),_c('inz-btn',{attrs:{"label":"Export starten…","icon":"rocket","disabled":_vm.canRun===false,"size":"sm","variant":"primary"},on:{"click":_vm.openExport}})],1)],1),_vm._v(" "),_c('d-export',{ref:"exporter",on:{"load":_vm.load}}),_vm._v(" "),_c('d-exportlog',{ref:"modal",on:{"redo":_vm.redo,"remove":_vm.remove}}),_vm._v(" "),_c('inz-filter-export',{model:{value:(_vm.filter),callback:function ($$v) {_vm.filter=$$v},expression:"filter"}}),_vm._v(" "),_c('inz-admin-exportlog-list',{attrs:{"recs":_vm.filteredRecords},on:{"open":_vm.open}})],1):_vm._e()}
__vue__options__.staticRenderFns = []
if (module.hot) {(function () {  var hotAPI = require("vue-hot-reload-api")
  hotAPI.install(require("vue"), true)
  if (!hotAPI.compatible) return
  module.hot.accept()
  if (!module.hot.data) {
    hotAPI.createRecord("data-v-da75aac8", __vue__options__)
  } else {
    hotAPI.reload("data-v-da75aac8", __vue__options__)
  }
})()}