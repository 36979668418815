;(function(){
'use strict';

Object.defineProperty(exports, "__esModule", {
  value: true
});

var _logo = require('../../components/logo');

var _logo2 = _interopRequireDefault(_logo);

var _langswitch = require('../../skeleton/langswitch');

var _langswitch2 = _interopRequireDefault(_langswitch);

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

exports.default = {
  components: { InzLangswitch: _langswitch2.default, InzLogo: _logo2.default }
};
})()
if (module.exports.__esModule) module.exports = module.exports.default
var __vue__options__ = (typeof module.exports === "function"? module.exports.options: module.exports)
if (__vue__options__.functional) {console.error("[vueify] functional components are not supported and should be defined in plain js files using render functions.")}
__vue__options__.render = function render () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"inz-public"},[_c('router-view')],1)}
__vue__options__.staticRenderFns = []
if (module.hot) {(function () {  var hotAPI = require("vue-hot-reload-api")
  hotAPI.install(require("vue"), true)
  if (!hotAPI.compatible) return
  module.hot.accept()
  if (!module.hot.data) {
    hotAPI.createRecord("data-v-44ff3a20", __vue__options__)
  } else {
    hotAPI.reload("data-v-44ff3a20", __vue__options__)
  }
})()}