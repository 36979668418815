/**
 * @deprecated
 */
import * as _ from 'lodash';
import val from './val';


export default {
    rules() {
        return {
            // name: [{name: 'required'}, {name: 'minlen', val: 3}]
        }
    },
    validateFields(data) {
        return _.filter(this.rules(), (it, fieldName) => this.validateField(fieldName, data) === false);
    },
    isValid(data) {
        return 0 === this.validateFields(data).length;
    },
    validateField(fieldName, data) {
        let isValid = true;
        if (this.rules()[fieldName]) {
            let rules = this.rules()[fieldName];
            _.each(rules, rule => isValid = isValid === true && this.applyRule(rule, data))
        }

        return isValid;
    },
    applyRule(rule, data) {
        let isValid = true;

        switch (rule.name) {
            case 'required':
                isValid = (data && data.length > 0);
                break;
            case 'minlen':
                isValid = (data && data.length >= rule.val);
                break;
            case 'maxlen':
                isValid = (data && data.length <= rule.val);
                break;
            case 'url':
                isValid = val.validateUrl(data);
                break;
            case 'email':
                isValid = val.validateEmail(data);
                break;
            case 'mobile':
                isValid = val.validateMobile(data);
                break;
            case 'phone':
                isValid = val.validatePhone(data);
                break;
        }

        return isValid;
    }
}