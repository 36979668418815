;(function(){
'use strict';

Object.defineProperty(exports, "__esModule", {
  value: true
});

var _baseControl = require('../../../../../base/baseControl');

var _baseControl2 = _interopRequireDefault(_baseControl);

var _form = require('./form');

var _form2 = _interopRequireDefault(_form);

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

exports.default = {
  extends: _baseControl2.default,
  name: 'inz-export-file-editor',
  components: {
    InzFileForm: _form2.default
  },
  computed: {
    fileInfos: function fileInfos() {
      return [{ label: 'Grösse', value: this.val.fileSize, type: 'filesize' }, { label: 'Dateiname', value: this.val.title }, { label: 'Datum', value: this.val.dateCreated, type: 'date' }, { label: 'Hash', value: this.val.uniqueHash }];
    }
  }
};
})()
if (module.exports.__esModule) module.exports = module.exports.default
var __vue__options__ = (typeof module.exports === "function"? module.exports.options: module.exports)
if (__vue__options__.functional) {console.error("[vueify] functional components are not supported and should be defined in plain js files using render functions.")}
__vue__options__.render = function render () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return (_vm.val)?_c('div',{staticClass:"inz-export-file-editor"},[_c('inz-box',{attrs:{"variant":"white"}},[_c('inz-infolist',{attrs:{"infos":_vm.fileInfos}}),_vm._v(" "),_c('inz-file-form',{model:{value:(_vm.val),callback:function ($$v) {_vm.val=$$v},expression:"val"}})],1)],1):_vm._e()}
__vue__options__.staticRenderFns = []
if (module.hot) {(function () {  var hotAPI = require("vue-hot-reload-api")
  hotAPI.install(require("vue"), true)
  if (!hotAPI.compatible) return
  module.hot.accept()
  if (!module.hot.data) {
    hotAPI.createRecord("data-v-54555c20", __vue__options__)
  } else {
    hotAPI.reload("data-v-54555c20", __vue__options__)
  }
})()}