import Vue from 'vue';
import numeral from 'numeral';

Vue.filter('iFilesize', function (value, format) {
	if (!value) value = 0;
	let ext = 'B';
	let f = value;
	if (value > 1024) {
		ext = 'KB'
		f = value / 1024;
	} else if (value > 1024 * 1024) {
		ext = 'MB';
		f = value / (1024 * 1024)
	}

	if (!format) format = '0,0';
	return numeral(f).format(format).replace(',', '\'') + ' ' + ext;
})