;(function(){
'use strict';

Object.defineProperty(exports, "__esModule", {
  value: true
});

var _modalDf = require('../config/modalDf');

var _modalDf2 = _interopRequireDefault(_modalDf);

var _bus = require('../service/bus');

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

exports.default = {
  name: 'inz-toaster',
  methods: {
    toast: function toast(label, type) {
      this.$bvToast.toast(this.$i18n.t(label), _modalDf2.default.toast(type));
    }
  },
  mounted: function mounted() {
    var _this = this;

    _bus.bus.$on('toast', function (a) {
      return _this.toast(a.label, a.success === true);
    });
  }
};
})()
if (module.exports.__esModule) module.exports = module.exports.default
var __vue__options__ = (typeof module.exports === "function"? module.exports.options: module.exports)
if (__vue__options__.functional) {console.error("[vueify] functional components are not supported and should be defined in plain js files using render functions.")}
__vue__options__.render = function render () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div')}
__vue__options__.staticRenderFns = []
if (module.hot) {(function () {  var hotAPI = require("vue-hot-reload-api")
  hotAPI.install(require("vue"), true)
  if (!hotAPI.compatible) return
  module.hot.accept()
  if (!module.hot.data) {
    hotAPI.createRecord("data-v-1187780e", __vue__options__)
  } else {
    hotAPI.reload("data-v-1187780e", __vue__options__)
  }
})()}