;(function(){
'use strict';

Object.defineProperty(exports, "__esModule", {
  value: true
});

var _lodash = require('lodash');

var _ = _interopRequireWildcard(_lodash);

var _gmap = require('../gmap');

var _gmap2 = _interopRequireDefault(_gmap);

var _cf = require('../../config/cf');

var _baseControl = require('../../base/baseControl');

var _baseControl2 = _interopRequireDefault(_baseControl);

var _utils = require('../../service/utils');

var _utils2 = _interopRequireDefault(_utils);

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

function _interopRequireWildcard(obj) { if (obj && obj.__esModule) { return obj; } else { var newObj = {}; if (obj != null) { for (var key in obj) { if (Object.prototype.hasOwnProperty.call(obj, key)) newObj[key] = obj[key]; } } newObj.default = obj; return newObj; } }

exports.default = {
  name: 'inz-location-selector',
  components: { Gmap: _gmap2.default },
  extends: _baseControl2.default,
  props: {
    size: { default: 'default' },
    label: { default: null }
  },
  data: function data() {
    return {
      show: false,
      searchInput: '',
      result: null,
      curLoc: null,
      map: null,
      mapConfig: {
        zoom: 8,
        center: { lat: -33, lng: 151 },
        zoomControl: true,
        mapTypeControl: false,
        fullscreenControl: false,
        scaleControl: true,
        streetViewControl: false,
        rotateControl: false
      }
    };
  },

  computed: {
    parsedInput: {
      get: function get() {
        return this.stringify(this.val);
      },
      set: function set(v) {}
    },
    resultData: function resultData() {
      return this.createResultData(this.result[0]) || this.curLoc || {};
    },
    previewText: function previewText() {
      var curLoc = _.isObject(this.curLoc) ? this.curLoc.addressF : '';
      return this.value ? this.parsedInput : curLoc;
    },
    google: function google() {
      return _cf.cf.getGmapsInstance();
    }
  },
  mounted: function mounted() {
    if (this.value) {
      this.searchInput = this.parsedInput ? this.parsedInput : '';
    }
  },

  methods: {
    stringify: function stringify(V) {
      if (_.isObject(V)) {
        var r = [];
        if (V.address) r.push(V.address);
        if (V.plz) r.push(V.plz);
        if (V.city) r.push(V.city);
        return _.filter(r).join(', ');
      }
      return '';
    },
    maybeSearch: function maybeSearch($event) {
      if ($event.keyCode === 13) {
        this.search();
      }
    },
    acceptLocation: function acceptLocation() {
      var address = _utils2.default.parseGoogleAddress(this.curLoc.address_components);
      this.$emit('input', address);
      this.$emit('select', this.curLoc);
      this.close();
    },
    close: function close() {
      this.show = false;
    },
    cancelSelector: function cancelSelector() {
      this.show = false;
    },
    open: function open() {
      this.show = true;
      this.search();
    },
    search: function search() {
      var _this = this;

      this.$set(this, 'result', null);

      var coder = new this.google.maps.Geocoder();
      coder.geocode({ address: this.searchInput }, function (r) {
        if (r) {
          _this.$set(_this, 'result', r);
          _this.$set(_this, 'curLoc', _this.createResultData(r[0]));
        }
      });
    },
    createResultData: function createResultData(d) {
      if (!_.isObject(d)) {
        return false;
      }
      return {
        location: _.isObject(d.geometry) ? d.geometry.location : {},
        address_components: d.address_components,
        addressF: d.formatted_address
      };
    }
  }
};
})()
if (module.exports.__esModule) module.exports = module.exports.default
var __vue__options__ = (typeof module.exports === "function"? module.exports.options: module.exports)
if (__vue__options__.functional) {console.error("[vueify] functional components are not supported and should be defined in plain js files using render functions.")}
__vue__options__.render = function render () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"inz-location-selector"},[_c('fieldset',{staticClass:"form-group"},[(_vm.label)?_c('legend',{staticClass:"bv-no-focus-ring col-form-label pt-0"},[_vm._v(_vm._s(_vm.label))]):_vm._e(),_vm._v(" "),_c('div',{staticClass:"clickable form-control preview-line",on:{"click":_vm.open}},[_c('fa',{attrs:{"icon":"map"}}),_vm._v("\n      "+_vm._s(_vm.previewText)+"\n    ")],1)]),_vm._v(" "),_c('b-modal',{attrs:{"title":_vm.$t('locationFinder.title'),"button-size":"sm"},scopedSlots:_vm._u([{key:"modal-footer",fn:function(){return [_c('div',{staticClass:"d-flex align-items-center justify-content-between w-100"},[_c('div',{staticClass:"f-auto"}),_vm._v(" "),_c('div',{staticClass:"mr-1"},[_c('inz-btn',{staticClass:"float-right",attrs:{"size":"sm","label":_vm.$t('btn.cancel')},on:{"click":_vm.cancelSelector}})],1),_vm._v(" "),_c('div',[(_vm.result===null)?_c('inz-btn',{staticClass:"float-right",attrs:{"variant":"primary","size":"sm","labelt":"btn.search","icon":"search"},on:{"click":_vm.search}}):_c('inz-btn',{staticClass:"float-right",attrs:{"variant":"primary","size":"sm","labelt":"btn.accept","icon":"check"},on:{"click":_vm.acceptLocation}})],1)])]},proxy:true}]),model:{value:(_vm.show),callback:function ($$v) {_vm.show=$$v},expression:"show"}},[_c('form',{on:{"submit":function($event){$event.preventDefault();return _vm.search.apply(null, arguments)}}},[_c('inz-control-input',{attrs:{"placeholder":"locationFinder.placeholderSearch","type":"text"},on:{"keyup":_vm.search},model:{value:(_vm.searchInput),callback:function ($$v) {_vm.searchInput=$$v},expression:"searchInput"}})],1),_vm._v(" "),(_vm.curLoc && _vm.curLoc.location)?_c('div',[_c('gmap',{attrs:{"markers":[_vm.curLoc.location]}}),_vm._v(" "),_c('div',{staticClass:"p-result pv-3 uk-border p-bg-white mv-1",domProps:{"innerHTML":_vm._s(_vm.curLoc.addressF)}})],1):_vm._e()])],1)}
__vue__options__.staticRenderFns = []
if (module.hot) {(function () {  var hotAPI = require("vue-hot-reload-api")
  hotAPI.install(require("vue"), true)
  if (!hotAPI.compatible) return
  module.hot.accept()
  if (!module.hot.data) {
    hotAPI.createRecord("data-v-f20b9bfa", __vue__options__)
  } else {
    hotAPI.reload("data-v-f20b9bfa", __vue__options__)
  }
})()}