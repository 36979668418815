/**
 * Formats: '0,0'
 * '0,0.00'
 */
import Vue from 'vue';
import numeral from 'numeral';

Vue.filter('iNumber', function (value, format) {
    if (!value) value = 0;
    if (!format) format = '0,0';
    return numeral(value).format(format).replace(',', '\'');
})