;(function(){
'use strict';

Object.defineProperty(exports, "__esModule", {
  value: true
});

var _baseListsmall = require('../../../base/baseListsmall');

var _baseListsmall2 = _interopRequireDefault(_baseListsmall);

var _line = require('./entity/contact/line');

var _line2 = _interopRequireDefault(_line);

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

exports.default = {
  name: 'inz-admin-exportlog-list',
  components: { InzContactLine: _line2.default },
  extends: _baseListsmall2.default,
  data: function data() {
    return {
      currentPage: 1,
      perPage: 30
    };
  },

  methods: {
    open: function open(id) {
      this.$emit('open', id);
    }
  },
  computed: {
    slice: function slice() {
      var cur = this.currentPage;
      var perP = this.perPage;
      return this.recs.slice(cur * perP, (cur + 1) * perP);
    },
    fields: function fields() {
      return [{ key: 'id', sortable: true, label: 'th.id', class: 'i-cell-id' }, { key: 'dateCreated', sortable: true, label: 'th.dateExported', class: 'i-cell-date' }, { key: 'type', sortable: true, label: 'th.type' }, { key: 'errorMessage', sortable: true, label: 'th.errorMessage' }];
    }
  }
};
})()
if (module.exports.__esModule) module.exports = module.exports.default
var __vue__options__ = (typeof module.exports === "function"? module.exports.options: module.exports)
if (__vue__options__.functional) {console.error("[vueify] functional components are not supported and should be defined in plain js files using render functions.")}
__vue__options__.render = function render () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"inz-admin-exportlog-list"},[_c('b-table',{staticClass:"inz-table-list",attrs:{"hover":"","items":_vm.recs,"fields":_vm.fieldsTr,"current-page":_vm.currentPage,"per-page":_vm.perPage},scopedSlots:_vm._u([{key:"cell(id)",fn:function(data){return [_c('div',{staticClass:"clickable",on:{"click":function($event){return _vm.open(data.item)}}},[_vm._v(_vm._s(data.value))])]}},{key:"cell(dateCreated)",fn:function(data){return [_c('div',{staticClass:"clickable",on:{"click":function($event){return _vm.open(data.item)}}},[_vm._v(_vm._s(_vm._f("iDateTime")(data.value)))])]}},{key:"cell(type)",fn:function(data){return [_c('div',{staticClass:"clickable",on:{"click":function($event){return _vm.open(data.item)}}},[_vm._v("\n        "+_vm._s(_vm.$t('exportType.'+data.value))+" #"+_vm._s(data.item.remoteId)+"\n      ")])]}},{key:"cell(errorMessage)",fn:function(data){return [_c('div',{staticClass:"clickable",on:{"click":function($event){return _vm.open(data.item)}}},[_vm._v("\n        "+_vm._s(data.value)+"\n      ")])]}}])}),_vm._v(" "),_c('b-pagination',{staticClass:"inz-pagination mr-1 mb-1",attrs:{"total-rows":_vm.recs.length,"per-page":_vm.perPage,"align":"right","size":"sm"},model:{value:(_vm.currentPage),callback:function ($$v) {_vm.currentPage=$$v},expression:"currentPage"}})],1)}
__vue__options__.staticRenderFns = []
if (module.hot) {(function () {  var hotAPI = require("vue-hot-reload-api")
  hotAPI.install(require("vue"), true)
  if (!hotAPI.compatible) return
  module.hot.accept()
  if (!module.hot.data) {
    hotAPI.createRecord("data-v-374e9adc", __vue__options__)
  } else {
    hotAPI.reload("data-v-374e9adc", __vue__options__)
  }
})()}