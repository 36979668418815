import modalDf from '../config/modalDf';
import {general} from '../service/xhr';
import {R} from '../service/R';
import {security} from '../service/security';
import utils from '../service/utils';

export default {
    props: {
        rec: Object
    },
    methods: {
        toOverview() {
            R.home()
        },
        toastRmError() {
            utils.toast('toastError.rm', false);
        },
        toastRmSuccess() {
            utils.toast('toastSuccess.rm', true);
        },
        reload() {
            this.$emit('reload');
        },
        confirmRm() {
            this.$bvModal.msgBoxConfirm(this.$i18n.t('confirmRm.text'), modalDf.confirm())
                .then(res => {
                    if (utils.parseBool(res) !== true) {
                        return false;
                    }
                    this.rmConfirmed()
                });
        },
        rmConfirmed() {
            this.api.rm(this.rec.id)
                .then(() => {
                    this.toastRmSuccess();
                    this.toOverview();
                })
                .catch(err => {
                    if (!err.response.data.message) {
                        this.toastRmError();
                    } else {
                        utils.toast(err.response.data.message, false);
                    }
                });
        }
    },
    computed: {
        userIsAdmin() {
            return security.isAdmin();
        },
        S() {
            return security;
        },
        R() {
            return R;
        },
        api() {
            return general
        }
    }
}