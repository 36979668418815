export default {
    validateEmail(string) {
        const exp = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
        return !!exp.exec(string);
    },
    validateUrl(string) {
        const exp = /[-a-zA-Z0-9@:%._+~#=]{1,256}\.[a-zA-Z0-9()]{1,6}\b([-a-zA-Z0-9()@:%_+.~#?&//=]*)?/gi;
        return !!exp.exec(string);
    },
    validateMobile(string) {
        let reg = /^\+(41|423)(7[5-9]{1})([0-9]{7})/;
        return (string + '').length >= 10 && !!reg.exec(string);
    },
    validatePhone(string) {
        let reg = /^\+(41|423)([0-9]{9})/;
        return (string + '').length >= 10 && !!reg.exec(string);
    }
}