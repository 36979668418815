/**
 * INZURO ONE ADMIN
 */
import Vue from 'vue'
import App from './App'
import 'vueify/lib/insert-css' // required for .vue file <style> tags
import Vuex from 'vuex'
import 'chart.js'

import router from './config/router';
import store from './store';
import i18n from './config/i18n';

import './config/icons';
import './config/components';

import {BootstrapVue} from 'bootstrap-vue'
import {accountService} from './service/account';
import utils from "./service/utils";

Vue.use(BootstrapVue)
Vue.use(Vuex)

new Vue({
	el: '#app',
	store,
	i18n,
	router,
	render: h => h(App),
	mounted() {
		utils.init();
		//accountService.load(() => dev.imageEditor());
		accountService.load();
		// setTimeout(() => GoogleMapsApiLoader({apiKey: cf.googleMapApiKey}).then(g => cf.setGmapsInstance(g)), 2000);
	},
});