;(function(){
'use strict';

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = {
  name: 'inz-filter',
  props: {
    value: {},
    placeholder: { default: 'search' }
  },
  computed: {
    hasFilterSlot: function hasFilterSlot() {
      return !!this.$slots['filter'];
    },

    val: {
      get: function get() {
        return this.value;
      },
      set: function set(v) {
        this.$emit('input', v);
      }
    }
  },
  data: function data() {
    return {
      show: false
    };
  },

  methods: {
    toggleFilter: function toggleFilter() {
      this.show = !this.show;
    }
  }
};
})()
if (module.exports.__esModule) module.exports = module.exports.default
var __vue__options__ = (typeof module.exports === "function"? module.exports.options: module.exports)
if (__vue__options__.functional) {console.error("[vueify] functional components are not supported and should be defined in plain js files using render functions.")}
__vue__options__.render = function render () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"inz-filter"},[_c('div',{staticClass:"inz-filter--head d-flex"},[_c('div',{staticClass:"inz-filter--left"},[_c('b-form-input',{staticClass:"inz-filter--input",attrs:{"placeholder":_vm.$t('placeholder.'+_vm.placeholder),"size":"md"},model:{value:(_vm.val),callback:function ($$v) {_vm.val=$$v},expression:"val"}})],1),_vm._v(" "),_c('div',{staticClass:"inz-filter--right"},[_c('inz-btn',{staticClass:"inz-filter--btn-search",attrs:{"size":"md","variant":"light","icon":"search"},on:{"click":_vm.toggleFilter}},[(_vm.hasFilterSlot)?_c('fa',{attrs:{"icon":_vm.show?'chevron-up':'chevron-down'}}):_vm._e()],1)],1)]),_vm._v(" "),(_vm.hasFilterSlot && _vm.show===true)?_c('div',{staticClass:"inz-filter--body"},[_vm._t("default")],2):_vm._e()])}
__vue__options__.staticRenderFns = []
if (module.hot) {(function () {  var hotAPI = require("vue-hot-reload-api")
  hotAPI.install(require("vue"), true)
  if (!hotAPI.compatible) return
  module.hot.accept()
  if (!module.hot.data) {
    hotAPI.createRecord("data-v-cc914bf6", __vue__options__)
  } else {
    hotAPI.reload("data-v-cc914bf6", __vue__options__)
  }
})()}