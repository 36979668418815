;(function(){
"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});

var _R = require("../../../../../service/R");

exports.default = {
  name: 'inz-contract-line',
  props: {
    rec: Object
  },
  methods: {
    open: function open() {
      _R.R.exportEdit(this.rec);
    }
  }
};
})()
if (module.exports.__esModule) module.exports = module.exports.default
var __vue__options__ = (typeof module.exports === "function"? module.exports.options: module.exports)
if (__vue__options__.functional) {console.error("[vueify] functional components are not supported and should be defined in plain js files using render functions.")}
__vue__options__.render = function render () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('span',{staticClass:"inz-contract-line",on:{"click":_vm.open}},[_c('fa',{attrs:{"icon":"flash"}}),_vm._v(" "+_vm._s(_vm.rec.name)+"\n")],1)}
__vue__options__.staticRenderFns = []
if (module.hot) {(function () {  var hotAPI = require("vue-hot-reload-api")
  hotAPI.install(require("vue"), true)
  if (!hotAPI.compatible) return
  module.hot.accept()
  if (!module.hot.data) {
    hotAPI.createRecord("data-v-6b920fcc", __vue__options__)
  } else {
    hotAPI.reload("data-v-6b920fcc", __vue__options__)
  }
})()}