;(function(){
'use strict';

Object.defineProperty(exports, "__esModule", {
  value: true
});

var _xhr = require('../service/xhr');

var _history = require('./history');

var _history2 = _interopRequireDefault(_history);

var _utils = require('../service/utils');

var _utils2 = _interopRequireDefault(_utils);

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

exports.default = {
  name: 'inz-entity-history',
  components: { InzHistory: _history2.default },
  props: {
    boxType: { type: String, default: 'white' },
    entity: String,
    remoteId: Number,
    showTitle: { type: Boolean, default: true },
    collapsable: { type: Boolean, default: false },
    collapsableOpen: { type: Boolean, default: true }
  },
  data: function data() {
    return {
      open: true,
      isLoading: false,
      isLoaded: false,
      records: null
    };
  },
  mounted: function mounted() {
    console.log('this.collapsableOpen', this.collapsableOpen);
    this.$set(this, 'open', this.collapsableOpen === true);
    this.load();
  },

  computed: {
    showCollapsable: function showCollapsable() {
      return this.collapsable === false || this.open === true;
    }
  },
  methods: {
    load: function load() {
      var _this = this;

      this.$set(this, 'isLoading', true);
      _xhr.history.byEntity(this.entity, this.remoteId).then(function (r) {
        _this.$set(_this, 'records', r.data);
        _this.$set(_this, 'isLoading', false);
        _this.$set(_this, 'isLoaded', true);
      }).catch(function (error) {
        return _utils2.default.handleXhrError(error);
      });
    }
  }
};
})()
if (module.exports.__esModule) module.exports = module.exports.default
var __vue__options__ = (typeof module.exports === "function"? module.exports.options: module.exports)
if (__vue__options__.functional) {console.error("[vueify] functional components are not supported and should be defined in plain js files using render functions.")}
__vue__options__.render = function render () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return (_vm.isLoaded)?_c('inz-box',{staticClass:"inz-entity-history ph-0",attrs:{"variant":_vm.boxType}},[(_vm.showTitle)?_c('b-card-header',[_c('inz-title',{attrs:{"title":_vm.$t('history.title')}},[(_vm.collapsable===true)?_c('template',{slot:"actions"},[_c('inz-btn',{attrs:{"icon":_vm.open?'caret-up':'caret-down',"size":"sm"},on:{"click":function($event){_vm.open=!_vm.open}}})],1):_vm._e()],2)],1):_vm._e(),_vm._v(" "),(_vm.collapsable===false || _vm.showCollapsable===true)?[_c('inz-history',{attrs:{"records":_vm.records}})]:_vm._e()],2):_vm._e()}
__vue__options__.staticRenderFns = []
if (module.hot) {(function () {  var hotAPI = require("vue-hot-reload-api")
  hotAPI.install(require("vue"), true)
  if (!hotAPI.compatible) return
  module.hot.accept()
  if (!module.hot.data) {
    hotAPI.createRecord("data-v-4ae56fb4", __vue__options__)
  } else {
    hotAPI.reload("data-v-4ae56fb4", __vue__options__)
  }
})()}