;(function(){
'use strict';

Object.defineProperty(exports, "__esModule", {
  value: true
});

var _baseControl = require('../../../../../base/baseControl');

var _baseControl2 = _interopRequireDefault(_baseControl);

var _basics = require('./form/basics');

var _basics2 = _interopRequireDefault(_basics);

var _contact = require('./form/contact');

var _contact2 = _interopRequireDefault(_contact);

var _address = require('./form/address');

var _address2 = _interopRequireDefault(_address);

var _settings = require('./form/settings');

var _settings2 = _interopRequireDefault(_settings);

var _attributes = require('./form/attributes');

var _attributes2 = _interopRequireDefault(_attributes);

var _iban = require('./form/iban');

var _iban2 = _interopRequireDefault(_iban);

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

exports.default = {
  extends: _baseControl2.default,
  name: 'inz-export-contact-editor',
  components: {
    InzContactFormIban: _iban2.default,
    InzContactFormAttributes: _attributes2.default,
    InzContactFormSettings: _settings2.default, InzContactFormAddress: _address2.default, InzContactFormContact: _contact2.default, InzContactFormBasics: _basics2.default
  }
};
})()
if (module.exports.__esModule) module.exports = module.exports.default
var __vue__options__ = (typeof module.exports === "function"? module.exports.options: module.exports)
if (__vue__options__.functional) {console.error("[vueify] functional components are not supported and should be defined in plain js files using render functions.")}
__vue__options__.render = function render () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return (_vm.val)?_c('b-form-row',{staticClass:"inz-export-contact-editor"},[_c('inz-box',{attrs:{"variant":"white"}},[_c('inz-contact-form-basics',{model:{value:(_vm.val),callback:function ($$v) {_vm.val=$$v},expression:"val"}}),_vm._v(" "),_c('inz-contact-form-contact',{model:{value:(_vm.val),callback:function ($$v) {_vm.val=$$v},expression:"val"}}),_vm._v(" "),_c('inz-contact-form-address',{model:{value:(_vm.val),callback:function ($$v) {_vm.val=$$v},expression:"val"}}),_vm._v(" "),_c('inz-contact-form-iban',{model:{value:(_vm.val),callback:function ($$v) {_vm.val=$$v},expression:"val"}})],1)],1):_vm._e()}
__vue__options__.staticRenderFns = []
if (module.hot) {(function () {  var hotAPI = require("vue-hot-reload-api")
  hotAPI.install(require("vue"), true)
  if (!hotAPI.compatible) return
  module.hot.accept()
  if (!module.hot.data) {
    hotAPI.createRecord("data-v-001ac61e", __vue__options__)
  } else {
    hotAPI.reload("data-v-001ac61e", __vue__options__)
  }
})()}