;(function(){
'use strict';

Object.defineProperty(exports, "__esModule", {
  value: true
});

var _baseControl = require('../../../../../../base/baseControl');

var _baseControl2 = _interopRequireDefault(_baseControl);

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

exports.default = {
  name: 'inz-price-element',
  extends: _baseControl2.default
};
})()
if (module.exports.__esModule) module.exports = module.exports.default
var __vue__options__ = (typeof module.exports === "function"? module.exports.options: module.exports)
if (__vue__options__.functional) {console.error("[vueify] functional components are not supported and should be defined in plain js files using render functions.")}
__vue__options__.render = function render () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return (_vm.val)?_c('b-row',{staticClass:"inz-price-element"},[_c('b-col',{staticClass:"list-label",attrs:{"md":"5","sm":"12"}},[_vm._v("\n    "+_vm._s(_vm.val.block.nameTr.de)+"\n  ")]),_vm._v(" "),_c('b-col',{staticClass:"list-label",attrs:{"md":"3","sm":"12"}},[_vm._v("\n    "+_vm._s(_vm._f("iPrice")(_vm.val.priceMin))+" – "+_vm._s(_vm._f("iPricePlain")(_vm.val.priceMax))+"\n  ")]),_vm._v(" "),_c('b-col',{staticClass:"list-label",attrs:{"md":"2","sm":"12"}},[_vm._v("\n    "+_vm._s(_vm._f("iPrice")(_vm.val.bruttoPraemie))+"\n  ")]),_vm._v(" "),_c('b-col',{attrs:{"md":"2","sm":"12"}},[_c('inz-control-input',{attrs:{"name":"warrantyProductId","size":"sm"},model:{value:(_vm.val.warrantyProductId),callback:function ($$v) {_vm.$set(_vm.val, "warrantyProductId", $$v)},expression:"val.warrantyProductId"}})],1)],1):_vm._e()}
__vue__options__.staticRenderFns = []
if (module.hot) {(function () {  var hotAPI = require("vue-hot-reload-api")
  hotAPI.install(require("vue"), true)
  if (!hotAPI.compatible) return
  module.hot.accept()
  if (!module.hot.data) {
    hotAPI.createRecord("data-v-646756db", __vue__options__)
  } else {
    hotAPI.reload("data-v-646756db", __vue__options__)
  }
})()}