import baseModal from './baseModal';
import {opts} from '../config/opts';

export default {
    extends: baseModal,
    computed: {
        optsLanguages() {
            return opts.languages.map(it => ({id: it.code, label: it.label}));
        }
    }
}