;(function(){
'use strict';

Object.defineProperty(exports, "__esModule", {
  value: true
});

var _assign = require('babel-runtime/core-js/object/assign');

var _assign2 = _interopRequireDefault(_assign);

var _abstractPanel = require('./abstractPanel');

var _abstractPanel2 = _interopRequireDefault(_abstractPanel);

var _xhr = require('../../service/xhr');

var _R2 = require('../../service/R');

var _header = require('./header');

var _header2 = _interopRequireDefault(_header);

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

exports.default = {
  name: 'inz-panel-contacts-new',
  components: { InzPanelHeader: _header2.default },
  extends: _abstractPanel2.default,
  data: function data() {
    return {
      fields: [{ key: 'displayName', sortable: true, label: 'th.name', class: 'i-cell-auto' }, { key: 'contactType', sortable: true, label: 'th.contactType', class: 'i-cell-type' }, { key: 'dateCreated', label: 'th.dateCreated', class: 'i-cell-date' }]
    };
  },

  computed: {
    fieldsTr: function fieldsTr() {
      var _this = this;

      return this.fields.map(function (it) {
        return (0, _assign2.default)(it, { label: _this.$i18n.t(it.label) });
      });
    },
    R: function R() {
      return _R2.R;
    },
    apiUrl: function apiUrl() {
      return _xhr.panel.contactsNew;
    }
  },
  methods: {
    click: function click(id) {
      _R2.R.contactDetail(id);
    }
  }
};
})()
if (module.exports.__esModule) module.exports = module.exports.default
var __vue__options__ = (typeof module.exports === "function"? module.exports.options: module.exports)
if (__vue__options__.functional) {console.error("[vueify] functional components are not supported and should be defined in plain js files using render functions.")}
__vue__options__.render = function render () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return (_vm.panelData)?_c('div',{staticClass:"inz-panel inz-panel-contacts-new"},[_c('b-card',[_c('inz-panel-header',{attrs:{"slot":"header","title-label":"contactsNew"},slot:"header"}),_vm._v(" "),_c('b-table',{staticClass:"inz-table-list",attrs:{"responsive":"","hover":"","items":_vm.panelData.values,"fields":_vm.fieldsTr},scopedSlots:_vm._u([{key:"cell()",fn:function(data){return [_c('div',{staticClass:"clickable",on:{"click":function($event){return _vm.R.contactDetail(data.item.id)}}},[_vm._v(_vm._s(data.value))])]}},{key:"cell(contactType)",fn:function(data){return [_c('div',{staticClass:"clickable",on:{"click":function($event){return _vm.R.contactDetail(data.item.id)}}},[_vm._v(_vm._s(_vm.$t('contactType.' + data.value))+"\n          "),(data.item.isAdmin)?_c('fa',{staticClass:"icon-is-admin",attrs:{"icon":"star"}}):_vm._e()],1)]}},{key:"cell(dateCreated)",fn:function(data){return [_c('div',{staticClass:"clickable",on:{"click":function($event){return _vm.R.contactDetail(data.item.id)}}},[_vm._v("\n          "+_vm._s(_vm._f("iDateTime")(data.value))+"\n        ")])]}}],null,false,3040212503)})],1)],1):_vm._e()}
__vue__options__.staticRenderFns = []
if (module.hot) {(function () {  var hotAPI = require("vue-hot-reload-api")
  hotAPI.install(require("vue"), true)
  if (!hotAPI.compatible) return
  module.hot.accept()
  if (!module.hot.data) {
    hotAPI.createRecord("data-v-1efb95ba", __vue__options__)
  } else {
    hotAPI.reload("data-v-1efb95ba", __vue__options__)
  }
})()}