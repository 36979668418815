import moment from "moment";

let ix = 0;

export default {
    color() {
        ix += 1;
        const colors = [
            '#063970',
            '#1e81b0',
            '#1eb095',
            '#23b01e',
            '#008000',
            '#738000',
            '#ab7d01',
            '#d56201',
            '#d52801',
            '#d50159',
            '#d501ca',
            '#6f01d5',
            '#3d01d5',
            '#0113d5',
            '#0184d5',
            '#01b5d5',
            '#01d595',
            '#0fd501',
            '#a7d501',
        ];
        if (ix >= colors.length) {
            ix = 0;
        }

        return colors[ix];
    },
    dateRange(filter) {
        switch(filter.type) {
            case 'week': return this.dateRangeWeek(filter);
            case 'month': return this.dateRangeMonth(filter);
        }
    },
    dateRangeWeek(filter) {
        const start = moment()
            .add(filter.start, 'weeks')
            .startOf('week')
            .add(1, 'weeks');
        const end = moment().add(filter.start, 'weeks');

        let res = [];
        let $i = 0;
        while ($i < 7) {
            $i += 1;
            let cur = moment(start).add($i, 'day');
            res.push(cur)
        }

        return res;
    },
    dateRangeMonth(filter) {
        let start = moment()
            .add(filter.start, 'months');

        let cur = start.clone();
        const end = start.clone().endOf('month');
        let range = [];
        while (cur < end) {
            range.push(moment(cur));
            cur.add(1, 'day');
        }

        return range;
    },
}