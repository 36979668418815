;(function(){
'use strict';

Object.defineProperty(exports, "__esModule", {
  value: true
});

var _R = require('../../service/R');

exports.default = {
  name: 'inz-insurance-line',
  props: { rec: Object },
  methods: {
    open: function open() {
      _R.R.insuranceDetail(this.rec.id);
    }
  }
};
})()
if (module.exports.__esModule) module.exports = module.exports.default
var __vue__options__ = (typeof module.exports === "function"? module.exports.options: module.exports)
if (__vue__options__.functional) {console.error("[vueify] functional components are not supported and should be defined in plain js files using render functions.")}
__vue__options__.render = function render () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return (_vm.rec)?_c('span',{staticClass:"inz-insurance-line",on:{"click":_vm.open}},[_c('fa',{attrs:{"icon":"certificate"}}),_vm._v(" "+_vm._s(_vm.rec.name)+"\n")],1):_vm._e()}
__vue__options__.staticRenderFns = []
if (module.hot) {(function () {  var hotAPI = require("vue-hot-reload-api")
  hotAPI.install(require("vue"), true)
  if (!hotAPI.compatible) return
  module.hot.accept()
  if (!module.hot.data) {
    hotAPI.createRecord("data-v-ec96a9d4", __vue__options__)
  } else {
    hotAPI.reload("data-v-ec96a9d4", __vue__options__)
  }
})()}