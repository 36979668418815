;(function(){
'use strict';

Object.defineProperty(exports, "__esModule", {
  value: true
});

var _footer = require('./skeleton/footer');

var _footer2 = _interopRequireDefault(_footer);

var _utils = require('./service/utils');

var _utils2 = _interopRequireDefault(_utils);

var _toaster = require('./skeleton/toaster');

var _toaster2 = _interopRequireDefault(_toaster);

var _about = require('./skeleton/about');

var _about2 = _interopRequireDefault(_about);

var _R = require('./service/R');

var _apiswitch = require('./skeleton/apiswitch');

var _apiswitch2 = _interopRequireDefault(_apiswitch);

var _logo = require('./components/logo');

var _logo2 = _interopRequireDefault(_logo);

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

exports.default = {
  name: 'app',
  components: { Logo: _logo2.default, InzApiswitch: _apiswitch2.default, InzAbout: _about2.default, InzToaster: _toaster2.default, InzFooter: _footer2.default },
  computed: {
    isAuthorized: function isAuthorized() {
      return _utils2.default.isAuthorized();
    },
    isLoading: function isLoading() {
      return this.$store.state.isLoading;
    }
  },
  methods: {
    home: function home() {
      _R.R.home();
    }
  }
};
})()
if (module.exports.__esModule) module.exports = module.exports.default
var __vue__options__ = (typeof module.exports === "function"? module.exports.options: module.exports)
if (__vue__options__.functional) {console.error("[vueify] functional components are not supported and should be defined in plain js files using render functions.")}
__vue__options__.render = function render () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"inz-maingrid inz-admin",attrs:{"id":"app"}},[_c('inz-toaster'),_vm._v(" "),_c('div',{staticClass:"inz-loader"},[(_vm.isLoading)?_c('b-spinner'):_vm._e()],1),_vm._v(" "),(_vm.isAuthorized)?_c('inz-about'):_vm._e(),_vm._v(" "),(_vm.isAuthorized)?_c('div',{staticClass:"inz-maingrid-top"},[_c('div',{staticClass:"inz-struct-logo clickable no-select"},[_c('img',{staticClass:"inz-logo",attrs:{"src":"/build/images/logo.svg","width":80,"alt":"INZURO"},on:{"click":_vm.home}})]),_vm._v(" "),_c('div',{staticClass:"inz-struct-api-switch no-select"},[_c('inz-apiswitch')],1)]):_vm._e(),_vm._v(" "),_c('div',{staticClass:"inz-maingrid-main container"},[_c('router-view',{key:_vm.$route.fullPath})],1),_vm._v(" "),_c('div',{staticClass:"inz-maingrid-foot"},[_c('inz-footer')],1)],1)}
__vue__options__.staticRenderFns = []
if (module.hot) {(function () {  var hotAPI = require("vue-hot-reload-api")
  hotAPI.install(require("vue"), true)
  if (!hotAPI.compatible) return
  module.hot.accept()
  if (!module.hot.data) {
    hotAPI.createRecord("data-v-2e015f16", __vue__options__)
  } else {
    hotAPI.reload("data-v-2e015f16", __vue__options__)
  }
})()}