import {R} from '../service/R';
import utils from '../service/utils';

export default {
    data() {
        return {
            rec: null,
            editUrl: null,
        }
    },
    methods: {
        toastError(name) {
            utils.toast(`toastError.${name}`, false);
        },
        toastSuccess(name) {
            utils.toast(`toastSuccess.${name}`, true);
        },
        edit() {
            this.$router.push({name: this.editUrl, params: {id: this.rec.id}});
        },
        rm() {
            return this.api.rm(this.$route.params.id);
        },
        up(d) {
            this.$set(this, 'rec', d);
        },
        load() {
            if (!this.api) {
                return false;
            }
            this.api.view(this.$route.params.id)
                .then(res => this.up(res.data))
                .catch(e => {
                    utils.handleXhrError(e, 'load');
                    this.$router.back();
                })
        },
        reload() {
            this.load();
        },
    },
    mounted() {
        this.load();
    },
    computed: {
        // Overwrite this method with your API, like contact, claim,...
        api() {
            return null;
            // return contact;
        },
        R() {
            return R;
        },
        canEdit() {
            return true;
        },
        canAdd() {
            return true;
        },
        canDelete() {
            return true;
        }
    }
}