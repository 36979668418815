;(function(){
'use strict';

Object.defineProperty(exports, "__esModule", {
  value: true
});

var _xhr = require('../service/xhr');

var _opts = require('../config/opts');

exports.default = {
  name: 'inz-langswitch',
  methods: {
    changeLang: function changeLang(l) {
      this.$i18n.locale = l;
      _xhr.account.setLanguage(l);
    }
  },
  computed: {
    currentLocale: function currentLocale() {
      return (this.$i18n.locale || 'de').toUpperCase();
    },
    langs: function langs() {
      return _opts.opts.languages;
    }
  }
};
})()
if (module.exports.__esModule) module.exports = module.exports.default
var __vue__options__ = (typeof module.exports === "function"? module.exports.options: module.exports)
if (__vue__options__.functional) {console.error("[vueify] functional components are not supported and should be defined in plain js files using render functions.")}
__vue__options__.render = function render () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('b-nav',{staticClass:"inz-langswitch-container"},[(_vm.langs)?_c('b-nav-item-dropdown',{staticClass:"inz-langswitch",attrs:{"text":_vm.currentLocale,"dropup":"","no-caret":""}},_vm._l((_vm.langs),function(lang){return _c('b-dropdown-item',{key:lang.code,attrs:{"href":"#"},on:{"click":function($event){return _vm.changeLang(lang.code)}}},[_vm._v("\n      "+_vm._s(_vm.$t(lang.label))+"\n    ")])}),1):_vm._e()],1)}
__vue__options__.staticRenderFns = []
if (module.hot) {(function () {  var hotAPI = require("vue-hot-reload-api")
  hotAPI.install(require("vue"), true)
  if (!hotAPI.compatible) return
  module.hot.accept()
  if (!module.hot.data) {
    hotAPI.createRecord("data-v-5a35c298", __vue__options__)
  } else {
    hotAPI.reload("data-v-5a35c298", __vue__options__)
  }
})()}