export const icons = {
	performance: 'bar-chart',
	config: 'cogs',
	overview: 'dashboard',
	export: 'cloud',
	backups: 'database',
	logs: 'bars',
	serverlogs: 'bars',
	dev: 'microchip',
	block: 'cubes',
	insurance: 'certificate',
	contact: 'users',
	lead: 'bullseye',
	evaluation: 'bars',
	contract: 'flash',
	event: 'calendar',
	question: 'question-circle',
	claim: 'exclamation',
	podium: 'home',
	leadsDashboard: 'bullseye',
	contactOverview: 'users',
	contractOverview: 'flash',
	blockOverview: 'cubes',
	insuranceOverview: 'certificate',
	claimOverview: 'exclamation',
	evaluationOverview: 'bars',
	pos: 'magic',
	admin: 'wrench',
	test: 'question',
}