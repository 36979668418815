;(function(){
'use strict';

Object.defineProperty(exports, "__esModule", {
  value: true
});

var _baseActions = require('../../../base/baseActions');

var _baseActions2 = _interopRequireDefault(_baseActions);

var _modalDf = require('../../../config/modalDf');

var _modalDf2 = _interopRequireDefault(_modalDf);

var _utils = require('../../../service/utils');

var _utils2 = _interopRequireDefault(_utils);

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

exports.default = {
  name: 'inz-block-list-small',
  extends: _baseActions2.default,
  props: {
    recs: Array,
    showEdit: { default: false }
  },
  methods: {
    accept: function accept(res) {},
    confirmRm: function confirmRm($i) {
      var _this = this;

      this.$bvModal.msgBoxConfirm(this.$i18n.t('confirmRm.text'), _modalDf2.default.confirm()).then(function (res) {
        if (_utils2.default.parseBool(res) !== true) {
          return false;
        }
        _this.$emit('rm', $i);
      });
    }
  }
};
})()
if (module.exports.__esModule) module.exports = module.exports.default
var __vue__options__ = (typeof module.exports === "function"? module.exports.options: module.exports)
if (__vue__options__.functional) {console.error("[vueify] functional components are not supported and should be defined in plain js files using render functions.")}
__vue__options__.render = function render () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"inz-block-list-small"},[_c('div',{staticClass:"inz-block-list-small--item"},[_c('div',{staticClass:"inz-block-list-small--item--main",domProps:{"innerHTML":_vm._s(_vm.$t('th.product'))}}),_vm._v(" "),_c('div',{staticClass:"inz-block-list-small--item--right",domProps:{"innerHTML":_vm._s(_vm.$t('th.isRenewable'))}}),_vm._v(" "),(_vm.showEdit===true)?_c('div',{staticClass:"inz-block-list-small--item--menu"}):_vm._e()]),_vm._v(" "),_vm._l((_vm.recs),function(p,$i){return _c('div',{key:$i,staticClass:"inz-block-list-small--item i-hover",class:{'removed':p.isActive===false}},[_c('div',{staticClass:"inz-block-list-small--item--icon"},[_c('fa',{attrs:{"icon":"cubes"}})],1),_vm._v(" "),_c('div',{staticClass:"inz-block-list-small--item--main"},[_c('div',{domProps:{"innerHTML":_vm._s(p.block.nameTr[_vm.$i18n.locale])}}),_vm._v(" "),(!p.selectedDuration)?_c('inz-records',{attrs:{"num":p.block.duration,"label":_vm.$t('monthsPeriod')}}):_c('inz-records',{attrs:{"num":p.selectedDuration*12,"label":_vm.$t('monthsPeriod')}}),_vm._v(" "),(p.connections.length>0)?_c('div',{staticClass:"inz-block-relations"},[_c('fa',{directives:[{name:"b-tooltip",rawName:"v-b-tooltip:hover",arg:"hover"}],attrs:{"icon":"link","title":_vm.$t('tooltip.blockRelations')}}),_vm._v(" "),_vm._l((p.connections),function(c,$i){return _c('b-badge',{key:$i,attrs:{"variant":"danger"}},[_vm._v("\n          "+_vm._s(_vm._f("iProduct")(c))+"\n        ")])})],2):_vm._e()],1),_vm._v(" "),_c('div',{staticClass:"inz-block-list-small--item--right"},[_vm._v("\n      "+_vm._s(_vm.$t(p.isRenewable ? 'yes' : 'no'))+"\n    ")])])})],2)}
__vue__options__.staticRenderFns = []
if (module.hot) {(function () {  var hotAPI = require("vue-hot-reload-api")
  hotAPI.install(require("vue"), true)
  if (!hotAPI.compatible) return
  module.hot.accept()
  if (!module.hot.data) {
    hotAPI.createRecord("data-v-6432b59a", __vue__options__)
  } else {
    hotAPI.reload("data-v-6432b59a", __vue__options__)
  }
})()}