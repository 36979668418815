;(function(){
'use strict';

Object.defineProperty(exports, "__esModule", {
  value: true
});

var _modalDf = require('../../config/modalDf');

var _modalDf2 = _interopRequireDefault(_modalDf);

var _xhr = require('../../service/xhr');

var _utils = require('../../service/utils');

var _utils2 = _interopRequireDefault(_utils);

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

exports.default = {
  name: 'inz-files-list',
  props: {
    recs: Array
  },
  computed: {
    fieldsTr: function fieldsTr() {
      var $T = this.$i18n;
      return [{ key: 'icon', sortable: false, label: '', tdClass: 'i-cell-icon' }, { key: 'title', sortable: true, label: $T.t('th.fileTitle'), tdClass: 'i-cell-ellipsis' }, { key: 'fileType', sortable: true, label: $T.t('th.fileType') }, { key: 'dateCreated', sortable: true, label: $T.t('th.dateCreated'), tdClass: 'i-cell-date' }, { key: 'controls', sortable: false, label: '', tdClass: 'i-cell-controls' }];
    }
  },
  methods: {
    confirmRm: function confirmRm(fileId) {
      var _this = this;

      this.$bvModal.msgBoxConfirm(this.$i18n.t('confirmRm.text'), _modalDf2.default.confirm()).then(function (res) {
        if (_utils2.default.parseBool(res) !== true) {
          return false;
        }
        _xhr.files.rm(fileId).then(function () {
          _utils2.default.toast('toastSuccess.rm', true);
          _this.$emit('reload');
        }).catch(function (err) {
          if (!err.response.data.message) {
            _utils2.default.toast('toastError.rm', false);
          } else {
            _utils2.default.toast(err.response.data.message, false);
          }
        });
      });
    }
  }
};
})()
if (module.exports.__esModule) module.exports = module.exports.default
var __vue__options__ = (typeof module.exports === "function"? module.exports.options: module.exports)
if (__vue__options__.functional) {console.error("[vueify] functional components are not supported and should be defined in plain js files using render functions.")}
__vue__options__.render = function render () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return (_vm.recs)?_c('div',{staticClass:"i-listsmall inz-files-list"},[_c('b-table',{staticClass:"inz-table-list mb-2",attrs:{"responsive":"","hover":"","items":_vm.recs,"fields":_vm.fieldsTr},scopedSlots:_vm._u([{key:"cell()",fn:function(data){return [_c('div',{staticClass:"clickable",domProps:{"innerHTML":_vm._s(data.value)}})]}},{key:"cell(dateCreated)",fn:function(data){return [_c('div',{staticClass:"clickable"},[_vm._v(_vm._s(_vm._f("iDate")(data.value)))])]}},{key:"cell(icon)",fn:function(data){return [_c('fa',{attrs:{"icon":"file"}})]}},{key:"cell(controls)",fn:function(data){return [_c('b-dd',{attrs:{"no-caret":"","size":"sm","variant":"default","dropleft":""},scopedSlots:_vm._u([{key:"button-content",fn:function(){return [_c('fa',{attrs:{"icon":"ellipsis-v"}})]},proxy:true}],null,true)},[_vm._v(" "),_c('b-dropdown-item',{on:{"click":function($event){return _vm.confirmRm(data.item.id)}}},[_c('fa',{attrs:{"icon":"trash"}}),_vm._v("\n          "+_vm._s(_vm.$t('action.rm'))+"\n        ")],1)],1)]}}],null,false,3326599539)})],1):_vm._e()}
__vue__options__.staticRenderFns = []
if (module.hot) {(function () {  var hotAPI = require("vue-hot-reload-api")
  hotAPI.install(require("vue"), true)
  if (!hotAPI.compatible) return
  module.hot.accept()
  if (!module.hot.data) {
    hotAPI.createRecord("data-v-25cdb643", __vue__options__)
  } else {
    hotAPI.reload("data-v-25cdb643", __vue__options__)
  }
})()}