;(function(){
'use strict';

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = {
  name: 'inz-address',
  props: {
    address: Object
  }
};
})()
if (module.exports.__esModule) module.exports = module.exports.default
var __vue__options__ = (typeof module.exports === "function"? module.exports.options: module.exports)
if (__vue__options__.functional) {console.error("[vueify] functional components are not supported and should be defined in plain js files using render functions.")}
__vue__options__.render = function render () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return (_vm.address)?_c('div',{staticClass:"inz-address"},[(_vm.address.address)?_c('p',{domProps:{"innerHTML":_vm._s(_vm.address.address)}}):_vm._e(),_vm._v(" "),(_vm.address.plz || _vm.address.city)?_c('p',[_vm._v(_vm._s(_vm.address.plz)+" "+_vm._s(_vm.address.city))]):_vm._e(),_vm._v(" "),(_vm.address.country)?_c('p',{domProps:{"innerHTML":_vm._s(_vm.$t('country.'+(_vm.address.country||'').toLowerCase()))}}):_vm._e()]):_vm._e()}
__vue__options__.staticRenderFns = []
if (module.hot) {(function () {  var hotAPI = require("vue-hot-reload-api")
  hotAPI.install(require("vue"), true)
  if (!hotAPI.compatible) return
  module.hot.accept()
  if (!module.hot.data) {
    hotAPI.createRecord("data-v-fa704992", __vue__options__)
  } else {
    hotAPI.reload("data-v-fa704992", __vue__options__)
  }
})()}