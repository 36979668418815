;(function(){
'use strict';

Object.defineProperty(exports, "__esModule", {
  value: true
});

var _abstractPanel = require('./abstractPanel');

var _abstractPanel2 = _interopRequireDefault(_abstractPanel);

var _xhr = require('../../service/xhr');

var _polar = require('../charts/polar');

var _polar2 = _interopRequireDefault(_polar);

var _polarArea = require('../charts/polarArea');

var _polarArea2 = _interopRequireDefault(_polarArea);

var _header = require('./header');

var _header2 = _interopRequireDefault(_header);

var _daterangebtns = require('../control/daterangebtns');

var _daterangebtns2 = _interopRequireDefault(_daterangebtns);

var _account = require('../../service/account');

var _utils = require('../../service/utils');

var _utils2 = _interopRequireDefault(_utils);

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

exports.default = {
  name: 'inz-panel-contract-top-branches',
  components: { InzControlDaterangebtns: _daterangebtns2.default, InzPanelHeader: _header2.default, InzChartPolarArea: _polarArea2.default, InzChartPolar: _polar2.default },
  extends: _abstractPanel2.default,
  computed: {
    filterName: function filterName() {
      return 'topBranches';
    },
    opts: function opts() {
      return {
        width: 300,
        height: 300,
        responsive: false
      };
    },
    title: function title() {
      var ROLE = account.userRole();
      return 'ROLE_SHOP_ADMIN' === ROLE ? 'contractTopEmployees' : 'contractTopBranches';
    },
    labels: function labels() {
      return this.panelData.map(function (it) {
        return it.remoteName;
      });
    },
    chartWidth: function chartWidth() {
      return _utils2.default.screenWidth() < 560 ? _utils2.default.screenWidth() - 60 : 560;
    },
    chartHeight: function chartHeight() {
      return _utils2.default.screenWidth() < 560 ? 320 : 400;
    },
    values: function values() {
      return this.panelData.map(function (it) {
        return it.contractSum;
      });
    },
    chartData: function chartData() {
      return {
        labels: this.labels,
        datasets: [{
          label: "Top Filialen",
          data: this.values,
          backgroundColor: ['rgba(255, 99, 132, 0.8)', 'rgba(255, 159, 64, 0.8)', 'rgba(255, 205, 86, 0.8)', 'rgba(75, 192, 192, 0.8)', 'rgba(54, 162, 235, 0.8)', 'rgba(153, 102, 255, 0.8)', 'rgba(201, 203, 207, 0.8)']
        }]
      };
    },
    apiUrl: function apiUrl() {
      return _xhr.panel.contractTopBranches;
    }
  }
};
})()
if (module.exports.__esModule) module.exports = module.exports.default
var __vue__options__ = (typeof module.exports === "function"? module.exports.options: module.exports)
if (__vue__options__.functional) {console.error("[vueify] functional components are not supported and should be defined in plain js files using render functions.")}
__vue__options__.render = function render () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"inz-panel inz-panel-contract-policies"},[_c('b-card',[_c('inz-panel-header',{attrs:{"slot":"header","title-label":_vm.title},slot:"header"},[_c('inz-control-daterangebtns',{model:{value:(_vm.filter),callback:function ($$v) {_vm.filter=$$v},expression:"filter"}})],1),_vm._v(" "),(_vm.panelData && !_vm.isLoading)?_c('inz-chart-polar-area',{staticClass:"inz-panel-contract-policies--inner",attrs:{"chartdata":_vm.chartData,"options":_vm.opts,"width":_vm.chartWidth,"height":_vm.chartHeight}}):_vm._e()],1)],1)}
__vue__options__.staticRenderFns = []
if (module.hot) {(function () {  var hotAPI = require("vue-hot-reload-api")
  hotAPI.install(require("vue"), true)
  if (!hotAPI.compatible) return
  module.hot.accept()
  if (!module.hot.data) {
    hotAPI.createRecord("data-v-53599b40", __vue__options__)
  } else {
    hotAPI.reload("data-v-53599b40", __vue__options__)
  }
})()}