import fa from './../components/fa';
import moduleHeader from '../components/moduleHeader';
import InzActionbar from '../components/actionbar';
import InzFilter from '../components/filter';
import InzBox from '../components/box';
import InzContent from '../components/box/content';
import Vue from 'vue';
import inzControlInput from '../components/control/input';
import inzControlNumber from '../components/control/number';
import inzControlTypeahead from '../components/control/typeahead';
import inzControlSelect from '../components/control/select';
import inzControlSwitch from '../components/control/switch';
import inzControlDatepick from '../components/control/datepick';
import inzControlMd from '../components/control/md';
import inzControlTextarea from '../components/control/textarea';
import inzControlRadioGroup from '../components/control/radioGroup';
import inzBtn from '../components/control/btn';
import inzRecords from '../components/records';
import inzTitle from '../components/title';
import inzInfolist from '../components/infolist';


import '../filter/price';
import '../filter/pricePlain';
import '../filter/date';
import '../filter/dateTime';
import '../filter/time';
import '../filter/dateYear';
import '../filter/lasthit';
import '../filter/user';
import '../filter/url';
import '../filter/percentage';
import '../filter/number';
import '../filter/filesize';
import '../filter/product';

Vue.component('fa', fa)
Vue.component('inzModuleHeader', moduleHeader)
Vue.component('InzFilter', InzFilter)
Vue.component('inzActionbar', InzActionbar)
Vue.component('inzBox', InzBox)
Vue.component('inzContent', InzContent)
Vue.component('inzRecords', inzRecords)
Vue.component('inzTitle', inzTitle)

Vue.component('inzControlInput', inzControlInput)
Vue.component('inzControlNumber', inzControlNumber)
Vue.component('inzControlTextarea', inzControlTextarea)
Vue.component('inzControlTypeahead', inzControlTypeahead)
Vue.component('inzControlSelect', inzControlSelect)
Vue.component('inzControlSwitch', inzControlSwitch)
Vue.component('inzControlDatepick', inzControlDatepick)
Vue.component('inzControlMd', inzControlMd)
Vue.component('inzControlRadioGroup', inzControlRadioGroup)
Vue.component('inzInfolist', inzInfolist)
Vue.component('inzBtn', inzBtn)

import dev from '../components/dev/dev';
Vue.component('dev', dev)
