;(function(){
'use strict';

Object.defineProperty(exports, "__esModule", {
  value: true
});

var _baseForm = require('../../../../../../base/baseForm');

var _baseForm2 = _interopRequireDefault(_baseForm);

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

exports.default = {
  name: 'inz-contact-form-attributes',
  extends: _baseForm2.default,
  computed: {
    isPartner: function isPartner() {
      return this.form.contactType === 'partner';
    }
  }
};
})()
if (module.exports.__esModule) module.exports = module.exports.default
var __vue__options__ = (typeof module.exports === "function"? module.exports.options: module.exports)
if (__vue__options__.functional) {console.error("[vueify] functional components are not supported and should be defined in plain js files using render functions.")}
__vue__options__.render = function render () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return (_vm.form)?_c('b-form-row',{staticClass:"inz-contact-form-attributes"},[_c('b-col',{attrs:{"md":"6","sm":"12"}},[_c('inz-control-input',{attrs:{"labelt":"numberOfEmployees","type":"number","name":"numberOfEmployees"},model:{value:(_vm.form.numberOfEmployees),callback:function ($$v) {_vm.$set(_vm.form, "numberOfEmployees", $$v)},expression:"form.numberOfEmployees"}})],1),_vm._v(" "),_c('b-col',{attrs:{"md":"6","sm":"12"}},[_c('inz-control-input',{attrs:{"labelt":"commercRegisterNumber","name":"commercRegisterNumber","placeholder":"CH-320.3.074.699-6"},model:{value:(_vm.form.commercRegisterNumber),callback:function ($$v) {_vm.$set(_vm.form, "commercRegisterNumber", $$v)},expression:"form.commercRegisterNumber"}})],1),_vm._v(" "),(_vm.isPartner)?_c('b-col',{attrs:{"md":"6","sm":"12"}},[_c('inz-control-input',{attrs:{"labelt":"mwstNumber","name":"mwstNumber","placeholder":"CHE-123.456.789 MWST"},model:{value:(_vm.form.mwstNumber),callback:function ($$v) {_vm.$set(_vm.form, "mwstNumber", $$v)},expression:"form.mwstNumber"}})],1):_vm._e(),_vm._v(" "),(_vm.isPartner)?_c('b-col',{attrs:{"md":"6","sm":"12"}},[_c('inz-control-input',{attrs:{"labelt":"iban","name":"iban","placeholder":""},model:{value:(_vm.form.iban),callback:function ($$v) {_vm.$set(_vm.form, "iban", $$v)},expression:"form.iban"}})],1):_vm._e()],1):_vm._e()}
__vue__options__.staticRenderFns = []
if (module.hot) {(function () {  var hotAPI = require("vue-hot-reload-api")
  hotAPI.install(require("vue"), true)
  if (!hotAPI.compatible) return
  module.hot.accept()
  if (!module.hot.data) {
    hotAPI.createRecord("data-v-71ea3fce", __vue__options__)
  } else {
    hotAPI.reload("data-v-71ea3fce", __vue__options__)
  }
})()}