export const opts = {
	pageCluster() {
		return [
			{id: 20, text: '20'},
			{id: 100, text: '100'},
			{id: 500, text: '500'},
			{id: 1000, text: '1000'}
		];
	},
	languages: [
		{id: 3, label: 'lang.de', code: 'de'},
		// {id: 4, label: 'lang.en', code: 'en'},
		{id: 8, label: 'lang.fr', code: 'fr'},
		//{id: 589, label: 'lang.it', code: 'it'}
	],
	dateRange: [
		{id: 'week', text: 'daterange.week'},
		{id: 'month', text: 'daterange.month'},
		{id: 'year', text: 'daterange.year'}
	],
	// contactType: [
	//     {id: 6, label: 'contactType.super_admin', slug: 'super_admin'},
	//     {id: 1, label: 'contactType.agent', slug: 'agent'},
	//     {id: 2, label: 'contactType.partner', slug: 'partner'},
	//     {id: 3, label: 'contactType.shop', slug: 'shop'}, // No Login
	//     {id: 4, label: 'contactType.employee', slug: 'employee'},
	//     {id: 5, label: 'contactType.developer', slug: 'developer'},
	//     // {id: 7, label: 'contactType.dealer_admin', slug: 'dealer_admin'}, // @deprecated unclear
	//     // {id: 8, label: 'contactType.shop_admin', slug: 'shop_admin'},  // @deprecated unclear
	//     {id: 9, label: 'contactType.customer', slug: 'customer'},
	//     {id: 10, label: 'contactType.underwriter', slug: 'underwriter'},
	//     // {id: 11, label: 'contactType.underwriter_admin', slug: 'underwriter_admin'}, // @deprecated
	//     {id: 12, label: 'contactType.insurance', slug: 'insurance'},
	//     {id: 14, label: 'contactType.consultant', slug: 'consultant'},
	//     // {id: 16, label: 'contactType.consultant_admin', slug: 'consultant_admin'}, // @deprecated
	//     {id: 15, label: 'contactType.generalagentur', slug: 'generalagentur'},
	//     {id: 17, label: 'contactType.apb', slug: 'apb'},
	//     {id: 18, label: 'contactType.schadenregulierer', slug: 'schadenregulierer'}
	// ],
	// blockTypes: [
	//     {id: 'multi_year', label: 'blockType.multi_year'},
	//     {id: 'split_year', label:'blockType.split_year'}, // INZURO-197 Disabled while UI is not defined
	//     // {id: 'inventar', label:'blockType.inventar'}, // INZURO-211 Disabled while UI not defined
	// ],
	// contractTypes: [
	//     {slug: 'b2b'},
	//     {slug: 'b2b2c'},
	//     {slug: 'inventar'},
	// ],
	// insuranceTypes: [
	//     {slug: 'garantie3'},
	//     {slug: 'kasko'},
	//     {slug: 'haftpflichtversicherung'},
	//     {slug: 'allrisk'},
	//     {slug: 'garantie'},
	// ],
	// claimStatus: [
	//     {slug: 'open'},
	//     {slug: 'pending'},
	//     {slug: 'denied'},
	//     {slug: 'approved'}
	// ],
	// // @deprecated
	// provisionType: [
	//     {id: 1, label: 'platform', color: '#ff1a1a'},
	//     {id: 2, label: 'agent', color: '#66ffcc'},
	//     {id: 3, label: 'partner', color: '#ccff33'},
	//     {id: 4, label: 'shop', color: '#ff00ff'},
	//     {id: 5, label: 'employee', color: '#ff9933'},
	//     {id: 6, label: 'schadenregulierer', color: '#0066ff'}
	// ],
	// communicationWay: [
	//     {id: 567, slug: 'sms'},
	//     {id: 568, slug: 'mail'},
	//     {id: 576, slug: 'all'}
	// ],
	countries: ['CH', 'LI']
}