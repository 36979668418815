;(function(){
'use strict';

Object.defineProperty(exports, "__esModule", {
  value: true
});

var _xhr = require('../../../service/xhr');

var _utils = require('../../../service/utils');

var _utils2 = _interopRequireDefault(_utils);

var _header = require('../../../components/panels/header');

var _header2 = _interopRequireDefault(_header);

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

exports.default = {
  components: { InzPanelHeader: _header2.default },
  data: function data() {
    return {
      claims: [],
      claimId: null,
      receiver: _utils2.default.u().email
    };
  },
  mounted: function mounted() {
    this.loadClaims();
  },

  computed: {
    formValid: function formValid() {
      return this.claimId !== null;
    },
    opts: function opts() {
      return this.claims.map(function (it) {
        return {
          id: it.id,
          text: '#' + it.id + ', ' + it.customerName + ', ' + it.insuranceName + ' ' + (it.state === 'exported' ? '✔' : '❌')
        };
      });
    }
  },
  methods: {
    send: function send() {
      _xhr.test.exportNotification(this.claimId, { receiver: this.receiver }).then(function (r) {
        return _utils2.default.toast('Test E-Mail verschickt', true);
      }).catch(function (r) {
        return _utils2.default.toast('Fehler beim Versand der Test E-Mail', false);
      });
    },
    loadClaims: function loadClaims() {
      var _this = this;

      _xhr.claim.list().then(function (r) {
        return _this.$set(_this, 'claims', r.data.values);
      });
    }
  }
};
})()
if (module.exports.__esModule) module.exports = module.exports.default
var __vue__options__ = (typeof module.exports === "function"? module.exports.options: module.exports)
if (__vue__options__.functional) {console.error("[vueify] functional components are not supported and should be defined in plain js files using render functions.")}
__vue__options__.render = function render () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('inz-module-header',{attrs:{"type":"error","title":"Test"}}),_vm._v(" "),_c('b-row',[_c('b-col',{attrs:{"md":"6","sm":"12"}},[_c('div',{staticClass:"inz-panel"},[_c('b-card',{staticClass:"inz-panel inz-admin-summary mb-1"},[_c('inz-panel-header',{attrs:{"slot":"header","title-label":"exportMailTest"},slot:"header"}),_vm._v(" "),_c('div',{staticClass:"ph-2 pb-1"},[_c('p',[_vm._v("Sendet die Export Bestätigung-Email in der entsprechenden Sprache an den Test-Empfängers. Die in der\n              E-Mail aufgeführten Fehler stammen aus dem gespeicherten Fehlerbericht und könnten veraltet sein.")]),_vm._v(" "),_c('inz-control-typeahead',{attrs:{"labelt":"claim","required":"","name":"claim","opts":_vm.opts},model:{value:(_vm.claimId),callback:function ($$v) {_vm.claimId=$$v},expression:"claimId"}}),_vm._v(" "),_c('inz-control-input',{attrs:{"name":"receiver","label":"Empfänger E-Mail"},model:{value:(_vm.receiver),callback:function ($$v) {_vm.receiver=$$v},expression:"receiver"}}),_vm._v(" "),_c('inz-btn',{attrs:{"variant":"primary","label":"Bestätigung senden","disabled":!_vm.formValid},on:{"click":_vm.send}})],1)],1)],1)]),_vm._v(" "),_c('b-col',{attrs:{"md":"6","sm":"12"}})],1)],1)}
__vue__options__.staticRenderFns = []
if (module.hot) {(function () {  var hotAPI = require("vue-hot-reload-api")
  hotAPI.install(require("vue"), true)
  if (!hotAPI.compatible) return
  module.hot.accept()
  if (!module.hot.data) {
    hotAPI.createRecord("data-v-f33312ce", __vue__options__)
  } else {
    hotAPI.reload("data-v-f33312ce", __vue__options__)
  }
})()}