;(function(){
'use strict';

Object.defineProperty(exports, "__esModule", {
  value: true
});

var _xhr = require('../../../service/xhr');

exports.default = {
  data: function data() {
    return {
      logdetail: null,
      files: [],
      curfile: null
    };
  },
  mounted: function mounted() {
    this.load();
  },

  methods: {
    loadDetails: function loadDetails() {
      var _this = this;

      var curFile = this.files.find(function (it) {
        return it.file === _this.curfile;
      });

      if (curFile.type === 'log') {
        _xhr.serverLogs.show(curFile.file).then(function (r) {
          return _this.$set(_this, 'logdetail', r.data);
        });
      } else {
        _xhr.cronLogs.show(curFile.file).then(function (r) {
          return _this.$set(_this, 'logdetail', r.data);
        });
      }
    },
    downloadFile: function downloadFile() {
      var _this2 = this;

      var curFile = this.files.find(function (it) {
        return it.file === _this2.curfile;
      });
      var url = null;
      if (curFile.type === 'log') {
        url = (0, _xhr.currentApiHost)() + '/admin/log/' + curFile.file + '/download';
      } else {
        url = (0, _xhr.currentApiHost)() + '/admin/cronlogs/' + curFile.file + '/download';
      }

      var a = document.createElement('a');
      a.href = url;
      a.target = '_blank';
      a.click();
    },
    load: function load() {
      var _this3 = this;

      _xhr.serverLogs.load().then(function (r) {
        return r.data.sort().map(function (it) {
          return _this3.files.push({ file: it, type: 'log' });
        });
      });

      _xhr.cronLogs.load().then(function (r) {
        return r.data.sort().map(function (it) {
          return _this3.files.push({ file: it, type: 'cronlog' });
        });
      });
    },
    detail: function detail(e) {
      this.$set(this, 'error', e);
    }
  },
  computed: {
    filesH: function filesH() {
      return this.files.map(function (it) {
        return { id: it.file, label: it.file, type: it.type };
      });
    }
  }
};
})()
if (module.exports.__esModule) module.exports = module.exports.default
var __vue__options__ = (typeof module.exports === "function"? module.exports.options: module.exports)
if (__vue__options__.functional) {console.error("[vueify] functional components are not supported and should be defined in plain js files using render functions.")}
__vue__options__.render = function render () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"admin-logfiles"},[_c('inz-module-header',{attrs:{"type":"logs","title":"Logfiles"}},[_c('div',{staticClass:"d-flex",attrs:{"slot":"right"},slot:"right"},[_c('inz-control-select',{staticClass:"mr-1",attrs:{"opts":_vm.filesH},model:{value:(_vm.curfile),callback:function ($$v) {_vm.curfile=$$v},expression:"curfile"}}),_vm._v(" "),_c('inz-btn',{staticClass:"mr-1",attrs:{"label":"Load","variant":"primary"},on:{"click":_vm.loadDetails}}),_vm._v(" "),_c('inz-btn',{attrs:{"label":"Download","variant":"secondary"},on:{"click":_vm.downloadFile}})],1)]),_vm._v(" "),_c('pre',{domProps:{"innerHTML":_vm._s(_vm.logdetail)}})],1)}
__vue__options__.staticRenderFns = []
if (module.hot) {(function () {  var hotAPI = require("vue-hot-reload-api")
  hotAPI.install(require("vue"), true)
  if (!hotAPI.compatible) return
  module.hot.accept()
  if (!module.hot.data) {
    hotAPI.createRecord("data-v-51441114", __vue__options__)
  } else {
    hotAPI.reload("data-v-51441114", __vue__options__)
  }
})()}