;(function(){
'use strict';

Object.defineProperty(exports, "__esModule", {
  value: true
});

var _baseControl = require('../../../base/baseControl');

var _baseControl2 = _interopRequireDefault(_baseControl);

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

exports.default = {
  name: 'inz-filter-export',
  extends: _baseControl2.default,
  computed: {
    opts: function opts() {
      return [{ id: null, label: 'Alle' }, { id: 'contractExport', label: 'Policen export' }, { id: 'contractCancel', label: 'Policen storno' }, { id: 'partner', label: 'Partner' }, { id: 'shop', label: 'Zweigstellen' }, { id: 'claim', label: 'Schadenfälle' }, { id: 'claimDamagedObject', label: 'Bilder' }];
    },

    val: {
      get: function get() {
        return this.value ? this.value : { input: '', type: null };
      },
      set: function set(v) {
        this.$emit('input', v);
      }
    }
  }
};
})()
if (module.exports.__esModule) module.exports = module.exports.default
var __vue__options__ = (typeof module.exports === "function"? module.exports.options: module.exports)
if (__vue__options__.functional) {console.error("[vueify] functional components are not supported and should be defined in plain js files using render functions.")}
__vue__options__.render = function render () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"inz-filter inz-filter__export"},[_c('div',{staticClass:"inz-filter--head d-flex"},[_c('div',{staticClass:"inz-filter--left"},[_c('b-form-row',[_c('b-col',{attrs:{"md":"9","sm":"12"}},[_c('inz-control-input',{staticClass:"inz-filter--input",attrs:{"placeholder":"placeholder.searchExport","size":"md"},model:{value:(_vm.val.input),callback:function ($$v) {_vm.$set(_vm.val, "input", $$v)},expression:"val.input"}})],1),_vm._v(" "),_c('b-col',{attrs:{"md":"3","sm":"12"}},[_c('inz-control-select',{attrs:{"size":"md","opts":_vm.opts},model:{value:(_vm.val.type),callback:function ($$v) {_vm.$set(_vm.val, "type", $$v)},expression:"val.type"}})],1)],1)],1)])])}
__vue__options__.staticRenderFns = []
if (module.hot) {(function () {  var hotAPI = require("vue-hot-reload-api")
  hotAPI.install(require("vue"), true)
  if (!hotAPI.compatible) return
  module.hot.accept()
  if (!module.hot.data) {
    hotAPI.createRecord("data-v-a7b17342", __vue__options__)
  } else {
    hotAPI.reload("data-v-a7b17342", __vue__options__)
  }
})()}