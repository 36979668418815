;(function(){
'use strict';

Object.defineProperty(exports, "__esModule", {
  value: true
});

var _R = require('../../../../../service/R');

var _baseControl = require('../../../../../base/baseControl');

var _baseControl2 = _interopRequireDefault(_baseControl);

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

exports.default = {
  name: 'inz-file-form',
  extends: _baseControl2.default,
  data: function data() {
    return {};
  },

  computed: {
    imageUrl: function imageUrl() {
      return this.$store.state.api + this.val.fileUrl;
    }
  },
  methods: {
    cancel: function cancel() {
      _R.R.claimOverview();
    },
    save: function save() {
      this.$emit('save');
    }
  }
};
})()
if (module.exports.__esModule) module.exports = module.exports.default
var __vue__options__ = (typeof module.exports === "function"? module.exports.options: module.exports)
if (__vue__options__.functional) {console.error("[vueify] functional components are not supported and should be defined in plain js files using render functions.")}
__vue__options__.render = function render () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return (_vm.val)?_c('div',{staticClass:"i-t-center mv-1"},[_c('img',{attrs:{"src":_vm.imageUrl,"alt":"uploaded file","width":"400"}})]):_vm._e()}
__vue__options__.staticRenderFns = []
if (module.hot) {(function () {  var hotAPI = require("vue-hot-reload-api")
  hotAPI.install(require("vue"), true)
  if (!hotAPI.compatible) return
  module.hot.accept()
  if (!module.hot.data) {
    hotAPI.createRecord("data-v-cf88f5d4", __vue__options__)
  } else {
    hotAPI.reload("data-v-cf88f5d4", __vue__options__)
  }
})()}