;(function(){
'use strict';

Object.defineProperty(exports, "__esModule", {
  value: true
});

var _baseModal = require('../../../../base/baseModal');

var _baseModal2 = _interopRequireDefault(_baseModal);

var _xhr = require('../../../../service/xhr');

var _utils = require('../../../../service/utils');

var _utils2 = _interopRequireDefault(_utils);

var _moment = require('moment');

var _moment2 = _interopRequireDefault(_moment);

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

exports.default = {
  name: 'd-user-online',
  extends: _baseModal2.default,
  data: function data() {
    return {
      rec: null
    };
  },

  computed: {
    infos: function infos() {
      var rec = this.rec;

      var a = (0, _moment2.default)().diff((0, _moment2.default)(rec.lastHit));
      var mins = a / 1000 / 60;
      var showInMins = mins < 5;

      return [{ label: 'Name', value: rec.name }, { label: 'E-Mail', value: rec.email }, { label: 'Partner', value: rec.partner }, { label: 'Login', value: rec.lastLogin, type: 'dateTime' }, {
        label: 'Zuletzt aktiv',
        value: showInMins ? 'vor ' + Math.round(mins) + ' Minuten' : rec.lastHit,
        type: showInMins ? '' : 'dateTime'
      }];
    }
  },
  methods: {
    load: function load(id) {
      return _xhr.admin.userDetail(id);
    },
    open: function open(id) {
      var _this = this;

      this.load(id).then(function (r) {
        return _this.$set(_this, 'rec', r.data);
      });
      this.isVisible = true;
    },
    disable: function disable() {
      var _this2 = this;

      _xhr.admin.userDisable(this.rec.id).then(function () {
        _utils2.default.toast('userDisabled', true);
        _this2.close();
      }).catch(function (e) {
        return _utils2.default.handleXhrError(e);
      });
    }
  }
};
})()
if (module.exports.__esModule) module.exports = module.exports.default
var __vue__options__ = (typeof module.exports === "function"? module.exports.options: module.exports)
if (__vue__options__.functional) {console.error("[vueify] functional components are not supported and should be defined in plain js files using render functions.")}
__vue__options__.render = function render () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('b-modal',{attrs:{"title":"Benutzer","button-size":"sm"},scopedSlots:_vm._u([{key:"modal-footer",fn:function(){return [_c('inz-btn',{attrs:{"size":"sm","variant":"default","label":_vm.$t('btn.cancel')},on:{"click":_vm.close}})]},proxy:true}]),model:{value:(_vm.isVisible),callback:function ($$v) {_vm.isVisible=$$v},expression:"isVisible"}},[(_vm.rec)?_c('inz-infolist',{attrs:{"infos":_vm.infos}}):_vm._e()],1)}
__vue__options__.staticRenderFns = []
if (module.hot) {(function () {  var hotAPI = require("vue-hot-reload-api")
  hotAPI.install(require("vue"), true)
  if (!hotAPI.compatible) return
  module.hot.accept()
  if (!module.hot.data) {
    hotAPI.createRecord("data-v-9994c6c6", __vue__options__)
  } else {
    hotAPI.reload("data-v-9994c6c6", __vue__options__)
  }
})()}