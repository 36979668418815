;(function(){
'use strict';

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = {
  name: 'inz-icon-block',
  props: {
    label: String,
    icon: String,
    value: String
  }

};
})()
if (module.exports.__esModule) module.exports = module.exports.default
var __vue__options__ = (typeof module.exports === "function"? module.exports.options: module.exports)
if (__vue__options__.functional) {console.error("[vueify] functional components are not supported and should be defined in plain js files using render functions.")}
__vue__options__.render = function render () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"inz-icon-block"},[_c('div',{staticClass:"label"},[(_vm.icon)?_c('fa',{attrs:{"icon":_vm.icon}}):_vm._e(),_vm._v("\n    "+_vm._s(_vm.label)+"\n  ")],1),_vm._v(" "),_c('div',{staticClass:"value"},[_vm._v(_vm._s(_vm.value)+"\n    "),_vm._t("default")],2)])}
__vue__options__.staticRenderFns = []
if (module.hot) {(function () {  var hotAPI = require("vue-hot-reload-api")
  hotAPI.install(require("vue"), true)
  if (!hotAPI.compatible) return
  module.hot.accept()
  if (!module.hot.data) {
    hotAPI.createRecord("data-v-5aebec17", __vue__options__)
  } else {
    hotAPI.reload("data-v-5aebec17", __vue__options__)
  }
})()}