import axios from "axios";
import U from './utils';
import {auth} from '../config/auth';
import persistent from '../store/persistent';

axios.interceptors.request.use((cf) => {
	U.xhrOn();
	cf.headers['X-Requested-With'] = 'XMLHttpRequest';
	cf.headers['Authorization'] = 'Basic ' + btoa(auth.username + ':' + auth.password);
	return cf;
}, e => Promise.reject(e));

axios.interceptors.response.use((r) => {
	setTimeout(() => U.xhrOff(), 300);
	return r;
}, e => {
	setTimeout(() => U.xhrOff(), 300);
	return Promise.reject(e);
});

export function currentApi() {
	if (location.hostname === 'localhost') {
		return 'api';
	}
	return 'api-' + persistent.state.api;
}

export function currentApiHost() {
	if (location.hostname === 'localhost') {
		return 'http://localhost:82';
	}
	switch (persistent.state.api) {
		case 'live':
			return 'https://inzuro-one.ch';
		case 'dev':
			return 'https://dev.inzuro-one.ch';
		case 'test':
			return 'https://test.inzuro-one.ch';
	}
}

export const general = {
	init() {
		return axios.get(`/${currentApi()}/system/status`);
	}, usage() {
		return axios.get(`/${currentApi()}/system/usage`);
	}, contractsPoliciesPerShop(filterData) {
		return axios.post(`/${currentApi()}/panels/contractsPoliciesPerShop`, {filterData});
	}, opts() {
		return axios.get(`/${currentApi()}/opts`);
	}, contractSave(rec) {
		return axios.post(`/${currentApi()}/contract/save`, rec);
	},
}

export const account = {
	loadCurrent() {
		return axios.get(`/${currentApi()}/loadCurrent`);
	}, login(cr) {
		return axios.post(`/${currentApi()}/login`, cr, {withCredentials: true});
	}, logout() {
		return axios.post(`/${currentApi()}/logout`);
	}, setPassword(id, cr) {
		return axios.post(`/${currentApi()}/account/${id}/setPassword`, cr);
	}, load(id) {
		return axios.get(`/${currentApi()}/account/${id}/load`);
	},
}

export const opts = {
	manufacturers() {
		return axios.get(`/${currentApi()}/opts/manufacturers`);
	}, insuranceAgents() {
		return axios.get(`/${currentApi()}/opts/agents`);
	}, insuranceTypes() {
		return axios.get(`/${currentApi()}/opts/insuranceTypes`);
	}, contractTypes() {
		return axios.get(`/${currentApi()}/opts/contractTypes`);
	}, blocks() {
		return axios.get(`/${currentApi()}/opts/blocks`);
	},
}

export const admin = {
	insuranceTypes() {
		return axios.get(`/${currentApi()}/admin/insuranceType/list`);
	}, insuranceTypeUpdate(id, data) {
		return axios.post(`/${currentApi()}/admin/insuranceType/${id}/update`, data);
	}, insuranceTypeCeate(data) {
		return axios.post(`/${currentApi()}/admin/insuranceType/create`, data);
	}, insuranceTypeDelete(id, target) {
		return axios.post(`/${currentApi()}/admin/insuranceType/${id}/delete`, {target});
	}, insuranceTypeUpdateNumLinkedProducts(id) {
		return axios.get(`/${currentApi()}/admin/insuranceType/${id}/linkedProducts`);
	}, onlineUsers() {
		return axios.get(`/${currentApi()}/admin/user/online`);
	}, summary() {
		return axios.get(`/${currentApi()}/admin/summary`);
	}, userDetail(id) {
		return axios.get(`/${currentApi()}/admin/user/${id}/details`);
	}, userDisable(id) {
		return axios.post(`/${currentApi()}/admin/user/${id}/disable`);
	}, exportSettingsFetch() {
		return axios.get(`/${currentApi()}/admin/export/settings/fetch`);
	}, exportSettingsUpdate(settings) {
		return axios.post(`/${currentApi()}/admin/export/settings/update`, settings);
	},
}

export const exportLog = {
	stats() {
		return axios.get(`/${currentApi()}/admin/export/stats`);
	}, log() {
		return axios.get(`/${currentApi()}/admin/export/list`);
	}, edit(id) {
		return axios.get(`/${currentApi()}/admin/export/${id}/view`);
	}, redo(id) {
		return axios.post(`/${currentApi()}/admin/export/${id}/redo`);
	}, remove(id) {
		return axios.delete(`/${currentApi()}/admin/export/${id}/remove`);
	}, clear() {
		return axios.delete(`/${currentApi()}/admin/export/clearLog`);
	}, run(cf) {
		return axios.post(`/${currentApi()}/admin/export/run`, cf);
	}, updateContract(rec) {
		return axios.post(`/${currentApi()}/admin/export/contract/update`, rec);
	}, updateContact(rec) {
		return axios.post(`/${currentApi()}/admin/export/contact/update`, rec);
	}, updateClaim(rec) {
		return axios.post(`/${currentApi()}/admin/export/claim/update`, rec);
	},
}

export const contract = {
	list(filter) {
		return axios.post(`/${currentApi()}/contract/list`, filter);
	}, view(id) {
		return axios.get(`/${currentApi()}/contract/${id}/view`);
	}, edit(id) {
		return axios.get(`/${currentApi()}/contract/${id}/edit`);
	},
}

export const claim = {
	list(filter) {
		return axios.post(`/${currentApi()}/claim/list`, filter);
	}, view(id) {
		return axios.get(`/${currentApi()}/claim/${id}/view`);
	}, edit(id) {
		return axios.get(`/${currentApi()}/claim/${id}/edit`);
	},
}
export const contact = {
	list(filter) {
		return axios.post(`/${currentApi()}/contact/list`, filter);
	}, view(id) {
		return axios.get(`/${currentApi()}/contact/${id}/view`);
	}, edit(id) {
		return axios.get(`/${currentApi()}/contact/${id}/edit`);
	},
}

export const cronLogs = {
	load() {
		return axios.get(`/${currentApi()}/admin/cronlogs`);
	}, show(fileName) {
		return axios.get(`/${currentApi()}/admin/cronlogs/${fileName}`);
	},
}

export const serverLogs = {
	load() {
		return axios.get(`/${currentApi()}/admin/logs`);
	}, show(fileName) {
		return axios.get(`/${currentApi()}/admin/log/${fileName}/raw`);
	},
}

export const claimDamagedObject = {
	view(id) {
		return axios.get(`/${currentApi()}/file/${id}/view`);
	}, edit(id) {
		return axios.get(`/${currentApi()}/file/${id}/view`);
	},
}

export const priceFinder = {
	load(contractId) {
		return axios.get(`/${currentApi()}/priceFinder/${contractId}/find`);
	},
}

export const performance = {
	contracts() {
		return axios.get(`/${currentApi()}/admin/stats/contracts`);
	},
}

export const test = {
	exportNotification(claimId, data) {
		return axios.post(`/${currentApi()}/admin/test/exportNotification/${claimId}`, data);
	},
}

export {
	axios,
};