;(function(){
'use strict';

Object.defineProperty(exports, "__esModule", {
  value: true
});

var _assign = require('babel-runtime/core-js/object/assign');

var _assign2 = _interopRequireDefault(_assign);

var _lodash = require('lodash');

var _ = _interopRequireWildcard(_lodash);

var _icons = require('../config/icons');

function _interopRequireWildcard(obj) { if (obj && obj.__esModule) { return obj; } else { var newObj = {}; if (obj != null) { for (var key in obj) { if (Object.prototype.hasOwnProperty.call(obj, key)) newObj[key] = obj[key]; } } newObj.default = obj; return newObj; } }

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

exports.default = {
  name: 'inz-history',
  props: {
    records: Array
  },
  computed: {
    hasData: function hasData() {
      return this.records !== null;
    },
    fields: function fields() {
      return [{ key: 'type', label: '', tdClass: 'i-cell-icon' }, { key: 'actionTr', label: 'th.action', tdClass: 'i-cell-auto' }, { key: 'createdBy', label: 'th.user', tdClass: 'i-cell-user' }, { key: 'dateCreated', label: 'th.dateCreated', tdClass: 'i-cell-date' }];
    },
    fieldsTr: function fieldsTr() {
      var _this = this;

      return this.fields.map(function (it) {
        return (0, _assign2.default)(it, { label: _this.$i18n.t(it.label) });
      });
    },
    filteredData: function filteredData() {
      return _.isObject(this.records) ? this.records : [];
    }
  },
  methods: {
    iconMap: function iconMap(type) {
      return _icons.icons[type];
    },
    click: function click(item) {
      this.$router.push({ name: item.thumb.type + 'Detail', params: { id: item.thumb.id } });
    }
  }
};
})()
if (module.exports.__esModule) module.exports = module.exports.default
var __vue__options__ = (typeof module.exports === "function"? module.exports.options: module.exports)
if (__vue__options__.functional) {console.error("[vueify] functional components are not supported and should be defined in plain js files using render functions.")}
__vue__options__.render = function render () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return (_vm.records)?_c('b-table',{staticClass:"inz-table-list inz-history",attrs:{"hover":"","items":_vm.filteredData,"fields":_vm.fieldsTr,"small":""},scopedSlots:_vm._u([{key:"cell(type)",fn:function(data){return [_c('fa',{directives:[{name:"b-tooltip",rawName:"v-b-tooltip.hover",modifiers:{"hover":true}}],attrs:{"icon":_vm.iconMap(data.item.thumb.type),"title":data.item.thumb.title}})]}},{key:"cell(createdBy)",fn:function(data){return [_c('span',{staticClass:"clickable",on:{"click":function($event){return _vm.click(data.item)}}},[_vm._v(_vm._s(_vm._f("iUser")(data.value)))])]}},{key:"cell(dateCreated)",fn:function(data){return [_c('span',{staticClass:"clickable",on:{"click":function($event){return _vm.click(data.item)}}},[_vm._v(_vm._s(_vm._f("iDateTime")(data.value)))])]}},{key:"cell()",fn:function(data){return [_c('span',{staticClass:"clickable",domProps:{"innerHTML":_vm._s(data.value)},on:{"click":function($event){return _vm.click(data.item)}}})]}}],null,false,3906688240)}):_vm._e()}
__vue__options__.staticRenderFns = []
if (module.hot) {(function () {  var hotAPI = require("vue-hot-reload-api")
  hotAPI.install(require("vue"), true)
  if (!hotAPI.compatible) return
  module.hot.accept()
  if (!module.hot.data) {
    hotAPI.createRecord("data-v-2c4a5852", __vue__options__)
  } else {
    hotAPI.reload("data-v-2c4a5852", __vue__options__)
  }
})()}