;(function(){
'use strict';

Object.defineProperty(exports, "__esModule", {
  value: true
});

var _lodash = require('lodash');

var _ = _interopRequireWildcard(_lodash);

var _abstract = require('./abstract');

var _abstract2 = _interopRequireDefault(_abstract);

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

function _interopRequireWildcard(obj) { if (obj && obj.__esModule) { return obj; } else { var newObj = {}; if (obj != null) { for (var key in obj) { if (Object.prototype.hasOwnProperty.call(obj, key)) newObj[key] = obj[key]; } } newObj.default = obj; return newObj; } }

exports.default = {
  name: 'inz-panel-performance-chart-year',
  extends: _abstract2.default,
  computed: {
    chartData: function chartData() {
      return {
        labels: ['Jan', 'Feb', 'Mar', 'Apr', 'Mai', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'],
        datasets: this.dataSets
      };
    },
    dataSets: function dataSets() {
      var C = this.color;
      var curY = this.curDate.format('YYYY');
      var lastY = this.curDate.clone().subtract(1, 'year').format('YYYY');
      return [{
        label: curY,
        data: this.calcValues(Number(curY)),
        borderColor: C.primary,
        backgroundColor: C.primary
      }, {
        label: lastY,
        data: this.calcValues(Number(lastY)),
        borderColor: C.secondary, 'backgroundColor': C.secondary
      }];
    }
  },
  methods: {
    calcValues: function calcValues(year) {
      var D = this.panelData.chart;
      var months = _.range(1, 13);
      var res = [];
      _.each(months, function (month) {
        return res.push(_.round(_.sum(_.filter(D, { month: month, year: year }).map(function (it) {
          return it.contractSum;
        }))));
      });
      return res;
    }
  }
};
})()
if (module.exports.__esModule) module.exports = module.exports.default
var __vue__options__ = (typeof module.exports === "function"? module.exports.options: module.exports)
if (module.hot) {(function () {  var hotAPI = require("vue-hot-reload-api")
  hotAPI.install(require("vue"), true)
  if (!hotAPI.compatible) return
  module.hot.accept()
  if (!module.hot.data) {
    hotAPI.createRecord("data-v-49a49f76", __vue__options__)
  } else {
    hotAPI.reload("data-v-49a49f76", __vue__options__)
  }
})()}