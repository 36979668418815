;(function(){
'use strict';

Object.defineProperty(exports, "__esModule", {
  value: true
});

var _xhr = require('../../../../service/xhr');

var _header = require('../../../../components/panels/header');

var _header2 = _interopRequireDefault(_header);

var _utils = require('../../../../service/utils');

var _utils2 = _interopRequireDefault(_utils);

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

exports.default = {
  components: { InzPanelHeader: _header2.default },
  name: 'inz-panel-admin-backups',
  methods: {
    load: function load() {
      var _this = this;

      _xhr.admin.onlineUsers().then(function (r) {
        return _this.$set(_this, 'records', r.data);
      }).catch(function (e) {
        return _utils2.default.handleXhrError(e, 'load');
      });
    }
  },
  mounted: function mounted() {
    this.load();
  },
  data: function data() {
    return {
      records: null
    };
  }
};
})()
if (module.exports.__esModule) module.exports = module.exports.default
var __vue__options__ = (typeof module.exports === "function"? module.exports.options: module.exports)
if (__vue__options__.functional) {console.error("[vueify] functional components are not supported and should be defined in plain js files using render functions.")}
__vue__options__.render = function render () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"inz-admin-backups"},[_c('inz-title',{attrs:{"title-label":"backups"}},[_c('inz-records',{attrs:{"num":_vm.records.length}})],1),_vm._v(" "),_c('div',[_vm._v("\n    Backup List\n  ")])],1)}
__vue__options__.staticRenderFns = []
if (module.hot) {(function () {  var hotAPI = require("vue-hot-reload-api")
  hotAPI.install(require("vue"), true)
  if (!hotAPI.compatible) return
  module.hot.accept()
  if (!module.hot.data) {
    hotAPI.createRecord("data-v-da81eb02", __vue__options__)
  } else {
    hotAPI.reload("data-v-da81eb02", __vue__options__)
  }
})()}