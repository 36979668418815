import {opts} from '../../../../../config/opts';

const persons = ['dealer', 'underwriter', 'consultant', 'customer', 'developer', 'tester', 'employee', 'agent', 'dealer_admin', 'super_admin', 'apb'];
const adminRoles = ['dealer', 'underwriter', 'consultant', 'shop', 'partner', 'agent', 'super_admin'];
const creatableRoles = ['agent', 'partner', 'underwriter', 'insurance', 'consultant', 'generalagentur', 'apb', 'schadenregulierer','developer'];
const hasNoAttributes = ['underwriter', 'tester', 'super_admin', 'developer', 'customer'];

export default {
    getType(slug) {
        return opts.contactType.find(it => it.slug === slug);
    },
    hasAttributes(slug) {
        return hasNoAttributes.indexOf(slug) === -1;
    },
    isPerson(slug) {
        return persons.indexOf(slug) > -1;
    },
    isCompany(slug) {
        return this.isPerson(slug) === false;
    },
    isPartner(slug) {
        return slug === 'partner'
    },
    roleWithAdminOption(slug) {
        return adminRoles.indexOf(slug) > -1;
    },
    createableContactTypes() {
        return opts.contactType.filter(it => creatableRoles.indexOf(it.slug) > -1)
    }
}