;(function(){
'use strict';

Object.defineProperty(exports, "__esModule", {
  value: true
});

var _base = require('./base');

var _base2 = _interopRequireDefault(_base);

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

exports.default = {
  extends: _base2.default,
  name: 'inz-question-range'
};
})()
if (module.exports.__esModule) module.exports = module.exports.default
var __vue__options__ = (typeof module.exports === "function"? module.exports.options: module.exports)
if (__vue__options__.functional) {console.error("[vueify] functional components are not supported and should be defined in plain js files using render functions.")}
__vue__options__.render = function render () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"inz-question-range"},[_c('div',{staticClass:"inz-question--grid"},[_c('div',{staticClass:"inz-question--question"},[_c('label',{domProps:{"innerHTML":_vm._s(_vm.question.question)}})]),_vm._v(" "),_c('div',{staticClass:"inz-question--input"},[_c('inz-control-input',{attrs:{"type":"number"},model:{value:(_vm.val),callback:function ($$v) {_vm.val=$$v},expression:"val"}})],1),_vm._v(" "),_c('div',{staticClass:"inz-question--unit",domProps:{"innerHTML":_vm._s(_vm.question.einheit)}})]),_vm._v(" "),_c('div',{staticClass:"d-flex align-items-center justify-content-center mt-3 mb-2"},[_c('div',{staticClass:"lead",domProps:{"innerHTML":_vm._s(_vm.question.min)}}),_vm._v(" "),_c('div',{staticClass:"w-75 mh-2"},[_c('b-form-input',{attrs:{"type":"range","min":_vm.question.min,"max":_vm.question.mix},model:{value:(_vm.val),callback:function ($$v) {_vm.val=$$v},expression:"val"}})],1),_vm._v(" "),_c('div',{staticClass:"lead",domProps:{"innerHTML":_vm._s(_vm.question.max)}})])])}
__vue__options__.staticRenderFns = []
if (module.hot) {(function () {  var hotAPI = require("vue-hot-reload-api")
  hotAPI.install(require("vue"), true)
  if (!hotAPI.compatible) return
  module.hot.accept()
  if (!module.hot.data) {
    hotAPI.createRecord("data-v-8e1f4a84", __vue__options__)
  } else {
    hotAPI.reload("data-v-8e1f4a84", __vue__options__)
  }
})()}