;(function(){
'use strict';

Object.defineProperty(exports, "__esModule", {
  value: true
});

var _baseForm = require('../../../../../../base/baseForm');

var _baseForm2 = _interopRequireDefault(_baseForm);

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

exports.default = {
  name: 'inz-contact-form-iban',
  extends: _baseForm2.default
};
})()
if (module.exports.__esModule) module.exports = module.exports.default
var __vue__options__ = (typeof module.exports === "function"? module.exports.options: module.exports)
if (__vue__options__.functional) {console.error("[vueify] functional components are not supported and should be defined in plain js files using render functions.")}
__vue__options__.render = function render () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return (_vm.form)?_c('b-form-row',{staticClass:"inz-contact-form-iban"},[_c('b-col',{attrs:{"md":"6","sm":"12"}},[_c('inz-control-input',{attrs:{"labelt":"iban","name":"iban"},model:{value:(_vm.form.iban),callback:function ($$v) {_vm.$set(_vm.form, "iban", $$v)},expression:"form.iban"}})],1),_vm._v(" "),_c('b-col',{attrs:{"md":"6","sm":"12"}},[_c('inz-control-input',{attrs:{"labelt":"mwstNumber","name":"mwstNumber"},model:{value:(_vm.form.mwstNumber),callback:function ($$v) {_vm.$set(_vm.form, "mwstNumber", $$v)},expression:"form.mwstNumber"}})],1)],1):_vm._e()}
__vue__options__.staticRenderFns = []
if (module.hot) {(function () {  var hotAPI = require("vue-hot-reload-api")
  hotAPI.install(require("vue"), true)
  if (!hotAPI.compatible) return
  module.hot.accept()
  if (!module.hot.data) {
    hotAPI.createRecord("data-v-9883dd86", __vue__options__)
  } else {
    hotAPI.reload("data-v-9883dd86", __vue__options__)
  }
})()}