/**
 * Abstract for question
 */
export default {
    props: {
        question: Object,
        value: {}
    },
    computed: {
        val: {
            get() {
                return this.value;
            },
            set(v) {
                this.$emit('input', v);
            },
        }
    }
}