import utils from '../service/utils';
import * as _ from 'lodash';

export default {
    props: {
        isMulti: {default: true}
    },
    data() {
        return {
            filterInput: '',
            isVisible: false,
            selected: [],
            records: []
        }
    },
    computed: {
        filtered() {
            let res = this.records;
            if (this.filterInput.length > 0) {
                res = utils.filterRecords(res, this.filterInput)
            }

            return res;
        },
        idsOfSelected() {
            return _.map(this.selected, it => this.filtered[it].id);
        }
    },
    methods: {
        reset() {
            this.selected.splice(0, this.selected.length);
            this.filterInput = '';
            this.records.splice(0, this.records.length);
        },
        toast(label, isSuccess) {
            utils.toast(label, isSuccess);
        },
        close() {
            this.isVisible = false;
        },
        click(item) {
            if (this.isMulti === false) {
                //this.$emit('input', item.id);
                this.$emit('select', item.id);
                this.close();
            } else {
                this.toggleSelect(item);
            }
        },
        open() {
            this.isVisible = true;
        },
        acceptSelection() {
            this.$emit('select', this.idsOfSelected);
            this.reset();
            this.close();
        },
        isItemSelected(item) {
            return this.isSelected(this.filtered.indexOf(item));
        },
        isSelected(i) {
            return this.selected.indexOf(i) > -1;
        },
        toggleSelect(item) {
            const i = this.filtered.indexOf(item);
            if (this.isSelected(i)) {
                this.selected.splice(this.selected.indexOf(i), 1);
            } else {
                this.selected.push(i);
            }
        }
    }
}