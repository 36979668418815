import {opts} from '../config/opts';
import * as _ from 'lodash';

export default {
    props: {
        value: {},
        state: {default: null, type: Boolean},
        label: {default: ''},
        labelt: {default: null, type: String, required: false},
        required: {default: false, type: Boolean},
        size: {default: 'md'},
        name: {default: null, type: String},
        compId: String,
        placeholder: {default: null, type: String},
        validFeedback: String,
        invalidFeedback: String,
        validator: {type: Object, default: null},
        multilang: {type: Boolean, default: false},
        copyFrom: {default: null},
        description: {default: null},
        tabindex: {default: null},
        disabled: {default: false}
    },
    data() {
        return {
            validatorError: null,
            touched: false,
            valid: null
        }
    },
    mounted() {
        if (null !== this.copyFrom && !this.val) {
            if (this.multilang) {
                _.each(opts.languages, lang => this.$set(this.val, lang.code, this.copyFrom + ''));
            } else {
                this.$set(this, 'val', this.copyFrom);
            }
        }
    },
    computed: {
        currentLang() {
            return this.$store.state.editLang;
        },
        _label() {
            return this.labelt ? this.$i18n.t('label.' + this.labelt) : this.label;
        },
        desc() {
            return this.description ? this.$i18n.t(this.description) : '';
        },
        placeh() {
            return this.placeholder ? this.$i18n.t(this.placeholder) : '';
        },
        val: {
            get() {
                return this.value;
            },
            set(v) {
                this.$emit('input', v);
            }
        },
        labelS() {
            return (true === this.required) ? (this._label + ' *') : this._label;
        }
    },
    watch: {
        'state'() {
            this.valid = this.state;
        }
    },
    methods: {
        blur() {
            this.$emit('blur');
            this.validate();
        },
        validate() {
            const name = this.$refs.inp._props.name;
            
            if (!this.$refs.inp) {
                return false;
            }

            if (this.validator) {
                if (!name) {
                    console.warn('name of control missing for validator', this.$refs.inp);
                } else {
                    this.valid = this.validator.validateField(name, this.val);
                    this.validatorError = this.validator.getError(name, this.val);
                }
            }
        }
    }
}