;(function(){
'use strict';

Object.defineProperty(exports, "__esModule", {
  value: true
});

var _insuranceType = require('../components/insuranceType');

var _insuranceType2 = _interopRequireDefault(_insuranceType);

var _onlineUser = require('../components/onlineUser');

var _onlineUser2 = _interopRequireDefault(_onlineUser);

var _summary = require('./summary');

var _summary2 = _interopRequireDefault(_summary);

var _vuedraggable = require('vuedraggable');

var _vuedraggable2 = _interopRequireDefault(_vuedraggable);

var _backups = require('../components/backups');

var _backups2 = _interopRequireDefault(_backups);

var _export = require('./export');

var _export2 = _interopRequireDefault(_export);

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

exports.default = {
  components: {
    InzPanelAdminExport: _export2.default,
    InzPanelAdminBackups: _backups2.default,
    InzPanelAdminOnlineusers: _onlineUser2.default,
    InzPanelAdminSummary: _summary2.default,
    InzPanelAdminInsuranceTypes: _insuranceType2.default, draggable: _vuedraggable2.default
  },
  data: function data() {
    return {
      panels: ['summary', 'insuranceTypes', 'onlineusers']
    };
  }
};
})()
if (module.exports.__esModule) module.exports = module.exports.default
var __vue__options__ = (typeof module.exports === "function"? module.exports.options: module.exports)
if (__vue__options__.functional) {console.error("[vueify] functional components are not supported and should be defined in plain js files using render functions.")}
__vue__options__.render = function render () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('inz-module-header',{attrs:{"type":"error","title":"INZURO Admin"}}),_vm._v(" "),_c('b-row',[_c('b-col',{attrs:{"md":"6","sm":"12"}},[_c('inz-panel-admin-summary'),_vm._v(" "),_c('inz-panel-admin-export')],1),_vm._v(" "),_c('b-col',{attrs:{"md":"6","sm":"12"}},[_c('inz-panel-admin-onlineusers')],1)],1)],1)}
__vue__options__.staticRenderFns = []
if (module.hot) {(function () {  var hotAPI = require("vue-hot-reload-api")
  hotAPI.install(require("vue"), true)
  if (!hotAPI.compatible) return
  module.hot.accept()
  if (!module.hot.data) {
    hotAPI.createRecord("data-v-1dcb52c3", __vue__options__)
  } else {
    hotAPI.reload("data-v-1dcb52c3", __vue__options__)
  }
})()}