'use strict';
import Vue from 'vue'
import Router from 'vue-router'

import ExportEdit from '../modules/admin/export/editor';
import Public from '../modules/public';
import Login from '../modules/public/login';
import Admin from '../modules/admin';
import AdminDashboard from '../modules/admin/dashboard/index';
import Config from '../modules/admin/config/index';
import Performance from '../modules/admin/performance/index';
import ExportOverview from '../modules/admin/export/index';
import Backups from '../modules/admin/backups/index';
import Logs from '../modules/admin/logs/index';
import Dev from '../modules/admin/dev/index';
import Test from '../modules/admin/test/index';
import U from '../service/utils';

const publicRoutes = ['login', 'resetPwRequest', 'resetPw', 'police'];

Vue.use(Router);
const router = new Router({
	mode: 'history',
	base: '/',
	// After pagechange, scroll to top
	scrollBehavior() {
		return {x: 0, y: 0}
	},
	routes: [
		{
			path: '/admin', component: Admin, children: [
				{path: '', name: 'admin', component: AdminDashboard},
				{path: 'performance', name: 'performance', component: Performance},
				{path: 'config', name: 'config', component: Config},
				{path: 'export', name: 'export', component: ExportOverview},
				{path: 'export/:id/:type/:remoteId', name: 'exportEdit', component: ExportEdit},
				{path: 'backups', name: 'backups', component: Backups},
				{path: 'logs', name: 'logs', component: Logs},
				{path: 'dev', name: 'dev', component: Dev},
				{path: 'test', name: 'test', component: Test},
			],
		},
		{
			path: '/public', component: Public, children: [
				{path: 'login', name: 'login', component: Login},
			],
		},
	],
})

// ENABLE FOR LIVE
router.beforeEach((to, from, next) => {
	if (publicRoutes.indexOf(to.name) === -1 && !U.isAuthorized()) next({name: 'login'})
	else next()
});

export default router;
