;(function(){
'use strict';

Object.defineProperty(exports, "__esModule", {
  value: true
});

var _lodash = require('lodash');

var _ = _interopRequireWildcard(_lodash);

var _cf = require('../config/cf');

function _interopRequireWildcard(obj) { if (obj && obj.__esModule) { return obj; } else { var newObj = {}; if (obj != null) { for (var key in obj) { if (Object.prototype.hasOwnProperty.call(obj, key)) newObj[key] = obj[key]; } } newObj.default = obj; return newObj; } }

exports.default = {
  name: 'gmap',
  props: {
    config: { type: Object },
    markers: { type: Array, default: [] }
  },
  data: function data() {
    return {
      map: null,
      mapMarkers: [],
      mapConfig: {
        zoom: 8,
        center: { lat: -33, lng: 151 },
        zoomControl: true,
        mapTypeControl: false,
        fullscreenControl: false,
        scaleControl: true,
        streetViewControl: false,
        rotateControl: false
      }
    };
  },
  mounted: function mounted() {
    this.initializeMap();
  },

  watch: {
    markers: {
      handler: function handler() {
        this.resetPois();
      },

      deep: true
    }
  },
  computed: {
    google: function google() {
      return _cf.cf.getGmapsInstance();
    }
  },
  methods: {
    initializeMap: function initializeMap() {
      var mapContainer = this.$refs.googleMap;
      this.map = new this.google.maps.Map(mapContainer, this.mapConfig);

      this.resetPois();
    },
    resetPois: function resetPois() {
      var _this = this;

      if (_.size(this.mapMarkers)) {
        _.each(this.mapMarkers, function (it) {
          it.map = null;
          _this.mapMarkers.splice(_this.mapMarkers.indexOf(it), 1);
        });
      }
      if (_.size(this.markers)) {
        _.each(this.markers, function (it) {
          return _this.addPoi(it);
        });
      }
    },
    addPoi: function addPoi(location) {
      var marker = new this.google.maps.Marker({
        map: this.map,
        position: location,
        zoom: 12
      });
      this.mapMarkers.push(marker);
      this.map.setCenter(location);
      this.map.setZoom(12);
    }
  }
};
})()
if (module.exports.__esModule) module.exports = module.exports.default
var __vue__options__ = (typeof module.exports === "function"? module.exports.options: module.exports)
if (__vue__options__.functional) {console.error("[vueify] functional components are not supported and should be defined in plain js files using render functions.")}
__vue__options__.render = function render () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{ref:"googleMap",staticClass:"google-map"})}
__vue__options__.staticRenderFns = []
if (module.hot) {(function () {  var hotAPI = require("vue-hot-reload-api")
  hotAPI.install(require("vue"), true)
  if (!hotAPI.compatible) return
  module.hot.accept()
  if (!module.hot.data) {
    hotAPI.createRecord("data-v-2ef128e2", __vue__options__)
  } else {
    hotAPI.reload("data-v-2ef128e2", __vue__options__)
  }
})()}