;(function(){
'use strict';

Object.defineProperty(exports, "__esModule", {
  value: true
});

var _bool = require('./bool');

var _bool2 = _interopRequireDefault(_bool);

var _text = require('./text');

var _text2 = _interopRequireDefault(_text);

var _range = require('./range');

var _range2 = _interopRequireDefault(_range);

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

exports.default = {
  name: 'inz-question',
  components: { InzQuestionRange: _range2.default, InzQuestionText: _text2.default, InzQuestionBool: _bool2.default },
  props: {
    question: Object,
    value: {}
  },
  computed: {
    val: {
      get: function get() {
        return this.value;
      },
      set: function set(v) {
        return this.$emit('input', v);
      }
    }
  }
};
})()
if (module.exports.__esModule) module.exports = module.exports.default
var __vue__options__ = (typeof module.exports === "function"? module.exports.options: module.exports)
if (__vue__options__.functional) {console.error("[vueify] functional components are not supported and should be defined in plain js files using render functions.")}
__vue__options__.render = function render () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return (_vm.question)?_c('div',{staticClass:"inz-question",class:'inz-question--'+_vm.question.questionType},[(_vm.question.questionType==='bool')?_c('inz-question-bool',{attrs:{"question":_vm.question},model:{value:(_vm.val),callback:function ($$v) {_vm.val=$$v},expression:"val"}}):(_vm.question.questionType==='text')?_c('inz-question-text',{attrs:{"question":_vm.question},model:{value:(_vm.val),callback:function ($$v) {_vm.val=$$v},expression:"val"}}):(_vm.question.questionType==='range')?_c('inz-question-range',{attrs:{"question":_vm.question},model:{value:(_vm.val),callback:function ($$v) {_vm.val=$$v},expression:"val"}}):_vm._e()],1):_vm._e()}
__vue__options__.staticRenderFns = []
if (module.hot) {(function () {  var hotAPI = require("vue-hot-reload-api")
  hotAPI.install(require("vue"), true)
  if (!hotAPI.compatible) return
  module.hot.accept()
  if (!module.hot.data) {
    hotAPI.createRecord("data-v-881c5d16", __vue__options__)
  } else {
    hotAPI.reload("data-v-881c5d16", __vue__options__)
  }
})()}