;(function(){
'use strict';

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = {
  name: 'inz-load-circle',
  props: {
    val: { type: Number }
  },
  computed: {
    stateClass: function stateClass() {
      var val = this.val;
      var res = 'text-success';
      if (val > 3) {
        res = 'text-warning';
      }
      if (val > 6) {
        res = 'text-danger';
      }
      return res;
    }
  }
};
})()
if (module.exports.__esModule) module.exports = module.exports.default
var __vue__options__ = (typeof module.exports === "function"? module.exports.options: module.exports)
if (__vue__options__.functional) {console.error("[vueify] functional components are not supported and should be defined in plain js files using render functions.")}
__vue__options__.render = function render () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('fa',{directives:[{name:"b-tooltip",rawName:"v-b-tooltip",value:(_vm.val),expression:"val"}],staticClass:"inz-load-circle",class:_vm.stateClass,attrs:{"icon":"circle"}})}
__vue__options__.staticRenderFns = []
if (module.hot) {(function () {  var hotAPI = require("vue-hot-reload-api")
  hotAPI.install(require("vue"), true)
  if (!hotAPI.compatible) return
  module.hot.accept()
  if (!module.hot.data) {
    hotAPI.createRecord("data-v-0d641663", __vue__options__)
  } else {
    hotAPI.reload("data-v-0d641663", __vue__options__)
  }
})()}