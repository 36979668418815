;(function(){
'use strict';

Object.defineProperty(exports, "__esModule", {
  value: true
});

var _abstractPanel = require('./abstractPanel');

var _abstractPanel2 = _interopRequireDefault(_abstractPanel);

var _xhr = require('../../service/xhr');

var _header = require('./header');

var _header2 = _interopRequireDefault(_header);

var _history = require('../history');

var _history2 = _interopRequireDefault(_history);

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

exports.default = {
  name: 'inz-panel-history',
  components: { InzHistory: _history2.default, InzPanelHeader: _header2.default },
  extends: _abstractPanel2.default,
  computed: {
    apiUrl: function apiUrl() {
      return _xhr.panel.history;
    }
  }
};
})()
if (module.exports.__esModule) module.exports = module.exports.default
var __vue__options__ = (typeof module.exports === "function"? module.exports.options: module.exports)
if (__vue__options__.functional) {console.error("[vueify] functional components are not supported and should be defined in plain js files using render functions.")}
__vue__options__.render = function render () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"inz-panel inz-panel-history i-t-left"},[_c('b-card',[_c('inz-panel-header',{attrs:{"slot":"header","title-label":"history"},slot:"header"}),_vm._v(" "),_c('inz-history',{attrs:{"records":_vm.filteredData}})],1)],1)}
__vue__options__.staticRenderFns = []
if (module.hot) {(function () {  var hotAPI = require("vue-hot-reload-api")
  hotAPI.install(require("vue"), true)
  if (!hotAPI.compatible) return
  module.hot.accept()
  if (!module.hot.data) {
    hotAPI.createRecord("data-v-00f46501", __vue__options__)
  } else {
    hotAPI.reload("data-v-00f46501", __vue__options__)
  }
})()}