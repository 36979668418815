;(function(){
'use strict';

Object.defineProperty(exports, "__esModule", {
  value: true
});

var _vueMultiselect = require('vue-multiselect');

var _vueMultiselect2 = _interopRequireDefault(_vueMultiselect);

var _baseControl = require('../../base/baseControl');

var _baseControl2 = _interopRequireDefault(_baseControl);

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

exports.default = {
  name: 'inz-control-multiselect',
  components: { Multiselect: _vueMultiselect2.default },
  extends: _baseControl2.default,
  props: {
    type: { default: 'text' },
    options: Array
  }
};
})()
if (module.exports.__esModule) module.exports = module.exports.default
var __vue__options__ = (typeof module.exports === "function"? module.exports.options: module.exports)
if (__vue__options__.functional) {console.error("[vueify] functional components are not supported and should be defined in plain js files using render functions.")}
__vue__options__.render = function render () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('multiselect',{staticClass:"inz-control",attrs:{"options":_vm.options,"close-on-select":false,"clear-on-select":false,"multiple":true},model:{value:(_vm.val),callback:function ($$v) {_vm.val=$$v},expression:"val"}})}
__vue__options__.staticRenderFns = []
if (module.hot) {(function () {  var hotAPI = require("vue-hot-reload-api")
  hotAPI.install(require("vue"), true)
  if (!hotAPI.compatible) return
  module.hot.accept()
  if (!module.hot.data) {
    hotAPI.createRecord("data-v-230f3106", __vue__options__)
  } else {
    hotAPI.reload("data-v-230f3106", __vue__options__)
  }
})()}