;(function(){
'use strict';

Object.defineProperty(exports, "__esModule", {
  value: true
});

var _VueTypeaheadBootstrap = require('../../3rdparty/VueTypeaheadBootstrap');

var _VueTypeaheadBootstrap2 = _interopRequireDefault(_VueTypeaheadBootstrap);

var _baseControl = require('../../base/baseControl');

var _baseControl2 = _interopRequireDefault(_baseControl);

var _lodash = require('lodash');

var _ = _interopRequireWildcard(_lodash);

function _interopRequireWildcard(obj) { if (obj && obj.__esModule) { return obj; } else { var newObj = {}; if (obj != null) { for (var key in obj) { if (Object.prototype.hasOwnProperty.call(obj, key)) newObj[key] = obj[key]; } } newObj.default = obj; return newObj; } }

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

exports.default = {
  name: 'inz-control-typeahead',
  extends: _baseControl2.default,
  props: {
    opts: Array,
    onlyFromList: { default: true },
    showValidation: { default: true },
    showAllResults: { default: false },
    minMatchingChars: { default: 1 },
    showOnFocus: { default: true }
  },
  data: function data() {
    return {
      internalState: null,
      tmp: null
    };
  },

  watch: {
    state: function state() {
      this.internalState = this.state;
    }
  },
  components: { VueTypeaheadBootstrap: _VueTypeaheadBootstrap2.default },
  computed: {
    typeaheadVal: {
      get: function get() {
        var res = _.find(this.opts, { id: this.val });
        if (res) {
          return res.text;
        }
        return '';
      },
      set: function set(v) {
        this.tmp = v;
        this.setVal(v);
      }
    },
    cssClasses: function cssClasses() {
      var s = this.internalState;
      var showV = this.showValidation === true;

      var res = [s === true && showV ? 'is-valid' : null, s === false && showV ? 'is-invalid' : null, 'form-control'];
      return _.filter(res).join(' ');
    }
  },
  methods: {
    setVal: function setVal(label) {
      if (this.onlyFromList === false) {
        this.$set(this, 'internalState', true);
        return;
      }

      var res = this.opts.find(function (it) {
        return it.label === label;
      });
      if (res !== undefined) {
        this.$set(this, 'val', res.id);
        this.$set(this, 'internalState', true);
      } else {
        this.$set(this, 'internalState', false);
      }
    },
    blur: function blur() {
      this.$emit('blur');
    },
    check: function check() {
      this.setVal(this.tmp);
    },
    hit: function hit(it) {
      this.$set(this, 'val', it.id);
      this.$set(this, 'internalState', true);
    }
  }
};
})()
if (module.exports.__esModule) module.exports = module.exports.default
var __vue__options__ = (typeof module.exports === "function"? module.exports.options: module.exports)
if (__vue__options__.functional) {console.error("[vueify] functional components are not supported and should be defined in plain js files using render functions.")}
__vue__options__.render = function render () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return (_vm.opts.length>0)?_c('b-form-group',{staticClass:"inz-control",class:{'is-valid':_vm.internalState===true&&_vm.showValidation,'invalid':_vm.internalState===false&&_vm.showValidation},attrs:{"label":_vm.labelS,"valid-feedback":_vm.validFeedback,"invalid-feedback":_vm.invalidFeedback}},[_c('vue-typeahead-bootstrap',{attrs:{"name":_vm.name,"data":_vm.opts,"input-class":_vm.cssClasses,"required":_vm.required,"show-all-results":_vm.showAllResults,"disabled":_vm.disabled,"placeholder":_vm.placeh,"show-on-focus":_vm.showOnFocus,"min-matching-chars":_vm.minMatchingChars,"serializer":function (item) { return item.text; }},on:{"keyup":_vm.check,"hit":_vm.hit},model:{value:(_vm.typeaheadVal),callback:function ($$v) {_vm.typeaheadVal=$$v},expression:"typeaheadVal"}})],1):_vm._e()}
__vue__options__.staticRenderFns = []
if (module.hot) {(function () {  var hotAPI = require("vue-hot-reload-api")
  hotAPI.install(require("vue"), true)
  if (!hotAPI.compatible) return
  module.hot.accept()
  if (!module.hot.data) {
    hotAPI.createRecord("data-v-2a266198", __vue__options__)
  } else {
    hotAPI.reload("data-v-2a266198", __vue__options__)
  }
})()}