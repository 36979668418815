;(function(){
'use strict';

Object.defineProperty(exports, "__esModule", {
  value: true
});

var _baseForm = require('../../../../../../base/baseForm');

var _baseForm2 = _interopRequireDefault(_baseForm);

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

exports.default = {
  name: 'inz-contact-form-basics',
  extends: _baseForm2.default,
  computed: {
    contactType: function contactType() {
      return this.form.contactType;
    },
    showSwitch: function showSwitch() {
      return ['employee', 'shop', 'developer', 'partner', 'consultant', 'apb'].indexOf(this.type) === -1;
    },
    type: function type() {
      return this.form.contactType;
    },
    isCompany: function isCompany() {
      return this.form.isCompany === true;
    }
  }
};
})()
if (module.exports.__esModule) module.exports = module.exports.default
var __vue__options__ = (typeof module.exports === "function"? module.exports.options: module.exports)
if (__vue__options__.functional) {console.error("[vueify] functional components are not supported and should be defined in plain js files using render functions.")}
__vue__options__.render = function render () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('b-form-row',{staticClass:"inz-contact-form-basics"},[(_vm.isCompany)?_c('b-col',{attrs:{"md":"12"}},[_c('inz-control-input',{attrs:{"labelt":"company","required":"","name":"name"},model:{value:(_vm.form.name),callback:function ($$v) {_vm.$set(_vm.form, "name", $$v)},expression:"form.name"}})],1):_vm._e(),_vm._v(" "),(_vm.isCompany)?_c('b-col',{attrs:{"md":"12"}},[_c('inz-control-input',{attrs:{"labelt":"additional","name":"additional"},model:{value:(_vm.form.additional),callback:function ($$v) {_vm.$set(_vm.form, "additional", $$v)},expression:"form.additional"}})],1):_vm._e(),_vm._v(" "),(!_vm.isCompany)?_c('b-col',{attrs:{"md":"6","sm":"12"}},[_c('inz-control-input',{attrs:{"labelt":"firstname","name":"name"},model:{value:(_vm.form.name),callback:function ($$v) {_vm.$set(_vm.form, "name", $$v)},expression:"form.name"}})],1):_vm._e(),_vm._v(" "),(!_vm.isCompany)?_c('b-col',{attrs:{"md":"6","sm":"12"}},[_c('inz-control-input',{attrs:{"labelt":"lastname","required":"","name":"lastname","required":true},model:{value:(_vm.form.lastname),callback:function ($$v) {_vm.$set(_vm.form, "lastname", $$v)},expression:"form.lastname"}})],1):_vm._e()],1)}
__vue__options__.staticRenderFns = []
if (module.hot) {(function () {  var hotAPI = require("vue-hot-reload-api")
  hotAPI.install(require("vue"), true)
  if (!hotAPI.compatible) return
  module.hot.accept()
  if (!module.hot.data) {
    hotAPI.createRecord("data-v-19b4c8fc", __vue__options__)
  } else {
    hotAPI.reload("data-v-19b4c8fc", __vue__options__)
  }
})()}