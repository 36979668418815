;(function(){
'use strict';

Object.defineProperty(exports, "__esModule", {
  value: true
});

var _baseModal = require('../../../base/baseModal');

var _baseModal2 = _interopRequireDefault(_baseModal);

var _detail = require('./detail');

var _detail2 = _interopRequireDefault(_detail);

var _R = require('../../../service/R');

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

exports.default = {
  name: 'd-exportlog',
  components: { InzExportlogDetail: _detail2.default },
  extends: _baseModal2.default,
  data: function data() {
    return {
      rec: null
    };
  },

  methods: {
    edit: function edit() {
      _R.R.exportEdit(this.rec);
    },
    redo: function redo() {
      this.$emit('redo', this.rec.id);
      this.close();
    },
    remove: function remove() {
      this.$emit('remove', this.rec.id);
      this.close();
    },
    open: function open(rec) {
      this.$set(this, 'rec', rec);
      this.$set(this, 'isVisible', true);
    }
  }
};
})()
if (module.exports.__esModule) module.exports = module.exports.default
var __vue__options__ = (typeof module.exports === "function"? module.exports.options: module.exports)
if (__vue__options__.functional) {console.error("[vueify] functional components are not supported and should be defined in plain js files using render functions.")}
__vue__options__.render = function render () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('b-modal',{attrs:{"title":"Transfer Protokoll","button-size":"sm"},scopedSlots:_vm._u([{key:"modal-footer",fn:function(){return [_c('inz-btn',{attrs:{"size":"sm","icon":"edit","variant":"primary","labelt":"Bearbeiten"},on:{"click":_vm.edit}}),_vm._v(" "),_c('inz-btn',{attrs:{"size":"sm","icon":"rotate-right","variant":"primary","labelt":"Erneut exportieren"},on:{"click":_vm.redo}}),_vm._v(" "),_c('inz-btn',{attrs:{"size":"sm","icon":"remove","variant":"primary","labelt":"Löschen"},on:{"click":_vm.remove}}),_vm._v(" "),_c('inz-btn',{attrs:{"size":"sm","variant":"default","label":_vm.$t('btn.cancel')},on:{"click":_vm.close}})]},proxy:true}]),model:{value:(_vm.isVisible),callback:function ($$v) {_vm.isVisible=$$v},expression:"isVisible"}},[_c('inz-exportlog-detail',{attrs:{"rec":_vm.rec}})],1)}
__vue__options__.staticRenderFns = []
if (module.hot) {(function () {  var hotAPI = require("vue-hot-reload-api")
  hotAPI.install(require("vue"), true)
  if (!hotAPI.compatible) return
  module.hot.accept()
  if (!module.hot.data) {
    hotAPI.createRecord("data-v-25904912", __vue__options__)
  } else {
    hotAPI.reload("data-v-25904912", __vue__options__)
  }
})()}