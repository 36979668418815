;(function(){
'use strict';

Object.defineProperty(exports, "__esModule", {
  value: true
});

var _promise = require('babel-runtime/core-js/promise');

var _promise2 = _interopRequireDefault(_promise);

var _lodash = require('lodash');

var _ = _interopRequireWildcard(_lodash);

var _baseEdit = require('../../../base/baseEdit');

var _baseEdit2 = _interopRequireDefault(_baseEdit);

var _recordMeta = require('../../../components/recordMeta');

var _recordMeta2 = _interopRequireDefault(_recordMeta);

var _detail = require('./detail');

var _detail2 = _interopRequireDefault(_detail);

var _xhr = require('../../../service/xhr');

var _contract = require('./entity/contract');

var _contract2 = _interopRequireDefault(_contract);

var _claim = require('./entity/claim');

var _claim2 = _interopRequireDefault(_claim);

var _contact = require('./entity/contact');

var _contact2 = _interopRequireDefault(_contact);

var _utils = require('../../../service/utils');

var _utils2 = _interopRequireDefault(_utils);

var _loader = require('../../../skeleton/loader');

var _loader2 = _interopRequireDefault(_loader);

var _claimDamagedObject = require('./entity/claimDamagedObject');

var _claimDamagedObject2 = _interopRequireDefault(_claimDamagedObject);

function _interopRequireWildcard(obj) { if (obj && obj.__esModule) { return obj; } else { var newObj = {}; if (obj != null) { for (var key in obj) { if (Object.prototype.hasOwnProperty.call(obj, key)) newObj[key] = obj[key]; } } newObj.default = obj; return newObj; } }

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

exports.default = {
  extends: _baseEdit2.default,
  components: {
    InzExportFileEditor: _claimDamagedObject2.default,
    InzLoader: _loader2.default,
    InzExportContactEditor: _contact2.default,
    InzExportClaimEditor: _claim2.default,
    InzExportContractEditor: _contract2.default,
    InzExportlogDetail: _detail2.default,
    UiRecordMeta: _recordMeta2.default
  },
  data: function data() {
    return {
      remoteEntity: null
    };
  },
  mounted: function mounted() {
    var _this = this;

    this.load().then(function (r) {
      return _this.$set(_this, 'rec', r.data);
    });

    this.loadRemoteEntity(this.type).then(function (r) {
      return _this.$set(_this, 'remoteEntity', r.data);
    });
  },

  methods: {
    cancel: function cancel() {
      this.R.exportOverview();
    },
    save: function save() {
      var _this2 = this;

      this.update().then(function (r) {
        return _utils2.default.toast(_this2.$i18n.t('toastSuccess.save'), true);
      }).catch(function (e) {
        return _utils2.default.handleXhrError(e, 'save');
      });
    },
    up: function up(r) {
      this.$set(this, 'rec', r);
    },
    remoteObjectType: function remoteObjectType(type) {
      switch (type) {
        case 'contractExport':
        case 'contractCancel':
          return _xhr.contract;
        case 'claim':
          return _xhr.claim;
        case 'partner':
          return _xhr.contact;
        case 'claimDamagedObject':
          return _xhr.claimDamagedObject;
      }
    },
    update: function update() {
      switch (this.type) {
        case 'contractExport':
        case 'contractCancel':
          return _xhr.exportLog.updateContract(this.remoteEntity);
        case 'claim':
          return _xhr.exportLog.updateClaim(this.remoteEntity);
        case 'partner':
          return _xhr.exportLog.updateContact(this.remoteEntity);
        default:
          return _promise2.default.reject('No suitable XHR object');
      }
    },
    loadRemoteEntity: function loadRemoteEntity(type) {
      return this.remoteObjectType(type).edit(this.$route.params.remoteId);
    }
  },
  computed: {
    type: function type() {
      return this.$route.params.type;
    },
    canSave: function canSave() {
      return _.isObject(this.rec) && _utils2.default.xhr() === false;
    },
    remoteObjectForm: function remoteObjectForm() {
      switch (this.rec.type) {
        case 'contractExport':
        case 'contractCancel':
          return 'contract';
        case 'claim':
          return 'claim';
        case 'partner':
          return 'contact';
        case 'claimDamagedObject':
          return 'claimDamagedObject';
      }
    },
    api: function api() {
      return _xhr.exportLog;
    }
  }
};
})()
if (module.exports.__esModule) module.exports = module.exports.default
var __vue__options__ = (typeof module.exports === "function"? module.exports.options: module.exports)
if (__vue__options__.functional) {console.error("[vueify] functional components are not supported and should be defined in plain js files using render functions.")}
__vue__options__.render = function render () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return (_vm.rec)?_c('div',{staticClass:"contract-edit"},[_c('inz-module-header',{attrs:{"title":_vm.$t('exportType.'+_vm.remoteObjectForm),"subtitle":'#'+_vm.rec.remoteId,"type":_vm.type}},[_c('div',{staticClass:"d-flex",attrs:{"slot":"right"},slot:"right"},[_c('inz-actionbar',[_c('inz-btn',{staticClass:"mr-1",attrs:{"labelt":"btn.cancel"},on:{"click":_vm.cancel}}),_vm._v(" "),_c('inz-btn',{attrs:{"icon":"save","labelt":"btn.save","variant":"primary","disabled":!_vm.canSave},on:{"click":_vm.save}})],1)],1)]),_vm._v(" "),_c('b-form-row',[(_vm.remoteEntity)?_c('b-col',{attrs:{"lg":"7","md":"12"}},[(_vm.remoteObjectForm==='contract')?_c('inz-export-contract-editor',{model:{value:(_vm.remoteEntity),callback:function ($$v) {_vm.remoteEntity=$$v},expression:"remoteEntity"}}):(_vm.remoteObjectForm==='claim')?_c('inz-export-claim-editor',{model:{value:(_vm.remoteEntity),callback:function ($$v) {_vm.remoteEntity=$$v},expression:"remoteEntity"}}):(_vm.remoteObjectForm==='contact')?_c('inz-export-contact-editor',{model:{value:(_vm.remoteEntity),callback:function ($$v) {_vm.remoteEntity=$$v},expression:"remoteEntity"}}):(_vm.remoteObjectForm==='claimDamagedObject')?_c('inz-export-file-editor',{model:{value:(_vm.remoteEntity),callback:function ($$v) {_vm.remoteEntity=$$v},expression:"remoteEntity"}}):_vm._e()],1):_c('b-col',{attrs:{"lg":"7","md":"12"}},[_c('inz-loader')],1),_vm._v(" "),_c('b-col',{attrs:{"lg":"5","md":"12"}},[_c('inz-box',{attrs:{"variant":"white"}},[_c('inz-exportlog-detail',{attrs:{"rec":_vm.rec}})],1)],1)],1)],1):_vm._e()}
__vue__options__.staticRenderFns = []
if (module.hot) {(function () {  var hotAPI = require("vue-hot-reload-api")
  hotAPI.install(require("vue"), true)
  if (!hotAPI.compatible) return
  module.hot.accept()
  if (!module.hot.data) {
    hotAPI.createRecord("data-v-a816cc58", __vue__options__)
  } else {
    hotAPI.reload("data-v-a816cc58", __vue__options__)
  }
})()}