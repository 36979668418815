;(function(){
'use strict';

Object.defineProperty(exports, "__esModule", {
  value: true
});

var _security = require('../service/security');

var _lodash = require('lodash');

var _ = _interopRequireWildcard(_lodash);

function _interopRequireWildcard(obj) { if (obj && obj.__esModule) { return obj; } else { var newObj = {}; if (obj != null) { for (var key in obj) { if (Object.prototype.hasOwnProperty.call(obj, key)) newObj[key] = obj[key]; } } newObj.default = obj; return newObj; } }

exports.default = {
  name: 'inz-create',
  data: function data() {
    return {
      show: false
    };
  },

  computed: {
    items: function items() {
      return [{ slug: 'contact', icon: 'user', url: 'contactAdd', P: this.canAddContact }, { slug: 'claim', icon: 'exclamation-triangle', url: 'claimAdd', P: this.canAddClaim }, { slug: 'block', icon: 'cube', url: 'blockAdd', P: this.canAddBlock }, { slug: 'insurance', icon: 'shield', url: 'insuranceAdd', P: this.canAddInsurance }];
    },
    filtered: function filtered() {
      return _.filter(this.items, function (it) {
        return it.P === true;
      });
    },
    canAddContact: function canAddContact() {
      return _security.security.mayAdd();
    },
    canAddClaim: function canAddClaim() {
      return _security.security.mayAdd();
    },
    canAddBlock: function canAddBlock() {
      return _security.security.mayAdd();
    },
    canAddInsurance: function canAddInsurance() {
      return _security.security.mayAdd();
    }
  }
};
})()
if (module.exports.__esModule) module.exports = module.exports.default
var __vue__options__ = (typeof module.exports === "function"? module.exports.options: module.exports)
if (__vue__options__.functional) {console.error("[vueify] functional components are not supported and should be defined in plain js files using render functions.")}
__vue__options__.render = function render () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return (_vm.filtered.length>0)?_c('div',{staticClass:"inz-create"},[_c('b-dropdown',{directives:[{name:"b-tooltip",rawName:"v-b-tooltip.hover",modifiers:{"hover":true}}],attrs:{"variant":"primary","title":"Create new…"},scopedSlots:_vm._u([{key:"button-content",fn:function(){return [_c('fa',{attrs:{"icon":"plus"}}),_vm._v(" "),_c('span',{staticClass:"sr-only"},[_vm._v("Create new…")])]},proxy:true}],null,false,2126578747)},[_vm._v(" "),_vm._l((_vm.filtered),function(item,$i){return _c('b-dropdown-item',{key:$i,staticClass:"button-",attrs:{"title":"Create new…"},on:{"click":function($event){return _vm.$router.push({name:item.url})}}},[_c('fa',{attrs:{"icon":item.icon}}),_vm._v("\n      "+_vm._s(_vm.$t('btnAdd.' + item.slug))+"\n    ")],1)})],2)],1):_vm._e()}
__vue__options__.staticRenderFns = []
if (module.hot) {(function () {  var hotAPI = require("vue-hot-reload-api")
  hotAPI.install(require("vue"), true)
  if (!hotAPI.compatible) return
  module.hot.accept()
  if (!module.hot.data) {
    hotAPI.createRecord("data-v-47e5cd89", __vue__options__)
  } else {
    hotAPI.reload("data-v-47e5cd89", __vue__options__)
  }
})()}