;(function(){
'use strict';

Object.defineProperty(exports, "__esModule", {
  value: true
});

var _dView = require('./dView');

var _dView2 = _interopRequireDefault(_dView);

var _listsmall = require('./listsmall');

var _listsmall2 = _interopRequireDefault(_listsmall);

var _xhr = require('../../../../service/xhr');

var _header = require('../../../../components/panels/header');

var _header2 = _interopRequireDefault(_header);

var _utils = require('../../../../service/utils');

var _utils2 = _interopRequireDefault(_utils);

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

exports.default = {
  components: { InzPanelHeader: _header2.default, DUserOnline: _dView2.default, InzAdminOnlineuserList: _listsmall2.default, InzAdminInsuranceTypesList: _listsmall2.default },
  name: 'inz-panel-admin-onlineusers',
  methods: {
    load: function load() {
      var _this = this;

      _xhr.admin.onlineUsers().then(function (res) {
        return _this.$set(_this, 'records', res.data);
      }).catch(function (e) {
        return _utils2.default.handleXhrError(e, 'load');
      });
    },
    open: function open(it) {
      this.$refs.modal.open(it);
    }
  },
  mounted: function mounted() {
    this.load();
  },
  data: function data() {
    return {
      records: null
    };
  }
};
})()
if (module.exports.__esModule) module.exports = module.exports.default
var __vue__options__ = (typeof module.exports === "function"? module.exports.options: module.exports)
if (__vue__options__.functional) {console.error("[vueify] functional components are not supported and should be defined in plain js files using render functions.")}
__vue__options__.render = function render () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return (_vm.records)?_c('div',{staticClass:"inz-panel inz-admin-onlineuser"},[_c('b-card',{staticClass:"inz-panel"},[_c('d-user-online',{ref:"modal"}),_vm._v(" "),_c('inz-panel-header',{attrs:{"slot":"header","title-label":"onlineUsers"},slot:"header"},[_c('inz-records',{attrs:{"num":_vm.records.length}})],1),_vm._v(" "),_c('inz-admin-onlineuser-list',{attrs:{"recs":_vm.records},on:{"open":_vm.open}})],1)],1):_vm._e()}
__vue__options__.staticRenderFns = []
if (module.hot) {(function () {  var hotAPI = require("vue-hot-reload-api")
  hotAPI.install(require("vue"), true)
  if (!hotAPI.compatible) return
  module.hot.accept()
  if (!module.hot.data) {
    hotAPI.createRecord("data-v-4d36ebc6", __vue__options__)
  } else {
    hotAPI.reload("data-v-4d36ebc6", __vue__options__)
  }
})()}