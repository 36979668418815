;(function(){
'use strict';

Object.defineProperty(exports, "__esModule", {
  value: true
});

var _baseControl = require('../../base/baseControl');

var _baseControl2 = _interopRequireDefault(_baseControl);

var _opts = require('../../config/opts');

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

exports.default = {
  name: 'inz-control-country',
  extends: _baseControl2.default,
  computed: {
    optsCountries: function optsCountries() {
      var _this = this;

      return _opts.opts.countries.map(function (it) {
        return { id: it, text: _this.$i18n.t('country.' + it.toLowerCase()) };
      });
    }
  }
};
})()
if (module.exports.__esModule) module.exports = module.exports.default
var __vue__options__ = (typeof module.exports === "function"? module.exports.options: module.exports)
if (__vue__options__.functional) {console.error("[vueify] functional components are not supported and should be defined in plain js files using render functions.")}
__vue__options__.render = function render () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"inz-control inz-control-country"},[_c('inz-control-typeahead',{attrs:{"labelt":"country","name":"country","opts":_vm.optsCountries,"disabled":_vm.disabled},on:{"blur":_vm.blur},model:{value:(_vm.val),callback:function ($$v) {_vm.val=$$v},expression:"val"}})],1)}
__vue__options__.staticRenderFns = []
if (module.hot) {(function () {  var hotAPI = require("vue-hot-reload-api")
  hotAPI.install(require("vue"), true)
  if (!hotAPI.compatible) return
  module.hot.accept()
  if (!module.hot.data) {
    hotAPI.createRecord("data-v-d4e88db2", __vue__options__)
  } else {
    hotAPI.reload("data-v-d4e88db2", __vue__options__)
  }
})()}