/**
 * APP Routes
 */
import router from '../config/router';

export const R = {
	home() {
		router.push({name: 'admin'});
	},
	login() {
		router.push({name: 'login'});
	},
	exportEdit(rec) {
		router.push({name: 'exportEdit', params: rec});
	},
	exportOverview() {
		router.push({name: 'export'});
	},
}