;(function(){
'use strict';

Object.defineProperty(exports, "__esModule", {
  value: true
});

var _VueTypeaheadBootstrap = require('../../3rdparty/VueTypeaheadBootstrap');

var _VueTypeaheadBootstrap2 = _interopRequireDefault(_VueTypeaheadBootstrap);

var _baseControl = require('../../base/baseControl');

var _baseControl2 = _interopRequireDefault(_baseControl);

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

exports.default = {
  name: 'inz-control-switch',
  extends: _baseControl2.default,
  props: {
    defValue: { default: true },
    onLabel: {
      default: function _default() {
        return this.$i18n.t('yes');
      }
    },
    offLabel: {
      default: function _default() {
        return this.$i18n.t('no');
      }
    },
    vertical: { default: false }
  },
  components: { VueTypeaheadBootstrap: _VueTypeaheadBootstrap2.default }
};
})()
if (module.exports.__esModule) module.exports = module.exports.default
var __vue__options__ = (typeof module.exports === "function"? module.exports.options: module.exports)
if (__vue__options__.functional) {console.error("[vueify] functional components are not supported and should be defined in plain js files using render functions.")}
__vue__options__.render = function render () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('b-form-group',{staticClass:"inz-control",class:{'inz-control-switch':true},attrs:{"label":_vm.labelS,"valid-feedback":_vm.validFeedback,"invalid-feedback":_vm.invalidFeedback,"state":_vm.valid}},[_c('b-form-checkbox',{attrs:{"value":_vm.defValue,"name":_vm.name,"switch":"","disabled":_vm.disabled},model:{value:(_vm.val),callback:function ($$v) {_vm.val=$$v},expression:"val"}},[_vm._v("\n    "+_vm._s(_vm.val === true ? _vm.onLabel : _vm.offLabel)+"\n  ")])],1)}
__vue__options__.staticRenderFns = []
if (module.hot) {(function () {  var hotAPI = require("vue-hot-reload-api")
  hotAPI.install(require("vue"), true)
  if (!hotAPI.compatible) return
  module.hot.accept()
  if (!module.hot.data) {
    hotAPI.createRecord("data-v-457aa2b3", __vue__options__)
  } else {
    hotAPI.reload("data-v-457aa2b3", __vue__options__)
  }
})()}