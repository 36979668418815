;(function(){
'use strict';

Object.defineProperty(exports, "__esModule", {
  value: true
});

var _R = require('../../../../../service/R');

var _baseControl = require('../../../../../base/baseControl');

var _baseControl2 = _interopRequireDefault(_baseControl);

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

exports.default = {
  name: 'inz-claim-form',
  extends: _baseControl2.default,
  data: function data() {
    return {
      selectedContact: null,
      dateOfAccidentValid: null,
      locationValid: null,
      descriptionAccidentValid: null,
      costsValid: null,
      summaryValid: null,
      uploadValid: null
    };
  },

  computed: {
    selectedName: function selectedName() {
      return this.selectedContact;
    }
  },
  methods: {
    cancel: function cancel() {
      _R.R.claimOverview();
    },
    save: function save() {
      this.$emit('save');
    }
  }
};
})()
if (module.exports.__esModule) module.exports = module.exports.default
var __vue__options__ = (typeof module.exports === "function"? module.exports.options: module.exports)
if (__vue__options__.functional) {console.error("[vueify] functional components are not supported and should be defined in plain js files using render functions.")}
__vue__options__.render = function render () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return (_vm.val)?_c('b-form',[_c('b-form-row',[_c('b-col',{attrs:{"md":"6","sm":"12"}},[_c('inz-control-datepick',{attrs:{"labelt":"dateOfAccident","required":"","name":"dateOfAccident","state":_vm.dateOfAccidentValid},model:{value:(_vm.val.dateOfAccident),callback:function ($$v) {_vm.$set(_vm.val, "dateOfAccident", $$v)},expression:"val.dateOfAccident"}})],1),_vm._v(" "),_c('b-col',{attrs:{"md":"6","sm":"12"}},[_c('inz-control-input',{attrs:{"labelt":"locationAccident","required":"","name":"locationAccident","state":_vm.locationValid},model:{value:(_vm.val.locationAccident),callback:function ($$v) {_vm.$set(_vm.val, "locationAccident", $$v)},expression:"val.locationAccident"}})],1)],1),_vm._v(" "),_c('inz-control-input',{attrs:{"labelt":"summary","required":"","state":_vm.summaryValid},model:{value:(_vm.val.summary),callback:function ($$v) {_vm.$set(_vm.val, "summary", $$v)},expression:"val.summary"}}),_vm._v(" "),_c('inz-control-textarea',{attrs:{"labelt":"descriptionAccident","required":"","name":"descriptionAccident","state":_vm.descriptionAccidentValid},model:{value:(_vm.val.descriptionAccident),callback:function ($$v) {_vm.$set(_vm.val, "descriptionAccident", $$v)},expression:"val.descriptionAccident"}}),_vm._v(" "),_c('inz-control-input',{attrs:{"type":"number","labelt":"costs","required":"","name":"costs","state":_vm.costsValid},model:{value:(_vm.val.costs),callback:function ($$v) {_vm.$set(_vm.val, "costs", $$v)},expression:"val.costs"}},[_c('template',{slot:"prepend"},[_c('div',{staticClass:"input-group-prepend"},[_c('span',{staticClass:"input-group-text"},[_vm._v("CHF")])])])],2)],1):_vm._e()}
__vue__options__.staticRenderFns = []
if (module.hot) {(function () {  var hotAPI = require("vue-hot-reload-api")
  hotAPI.install(require("vue"), true)
  if (!hotAPI.compatible) return
  module.hot.accept()
  if (!module.hot.data) {
    hotAPI.createRecord("data-v-4bfe39cc", __vue__options__)
  } else {
    hotAPI.reload("data-v-4bfe39cc", __vue__options__)
  }
})()}