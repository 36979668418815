;(function(){
'use strict';

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = {
  name: 'inz-panel',
  props: {
    panel: Object
  },
  methods: {
    doAction: function doAction(a) {
      this.$router.push(a.to);
    }
  }
};
})()
if (module.exports.__esModule) module.exports = module.exports.default
var __vue__options__ = (typeof module.exports === "function"? module.exports.options: module.exports)
if (__vue__options__.functional) {console.error("[vueify] functional components are not supported and should be defined in plain js files using render functions.")}
__vue__options__.render = function render () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"inz-panel"},[_c('b-card',[_c('div',{attrs:{"slot":"header"},slot:"header"},[_c('div',{staticClass:"d-flex"},[_c('div',{staticClass:"inz-panel-title f-auto",domProps:{"innerHTML":_vm._s(_vm.$t('panel.' + _vm.panel.type))}}),_vm._v(" "),_c('div',{staticClass:"inz-panel-actions"},[(_vm.panel.actions && _vm.panel.actions.length>0)?_c('b-dropdown',{staticClass:"mv-0",attrs:{"variant":"dust","title":"Actions","size":"sm"}},_vm._l((_vm.panel.actions),function(a,$i){return _c('b-dropdown-item',{key:$i,on:{"click":function($event){return _vm.doAction(a)}}},[_vm._v(_vm._s(a.label)+"\n            ")])}),1):_vm._e()],1)])]),_vm._v(" "),_vm._t("default")],2)],1)}
__vue__options__.staticRenderFns = []
if (module.hot) {(function () {  var hotAPI = require("vue-hot-reload-api")
  hotAPI.install(require("vue"), true)
  if (!hotAPI.compatible) return
  module.hot.accept()
  if (!module.hot.data) {
    hotAPI.createRecord("data-v-299d01a0", __vue__options__)
  } else {
    hotAPI.reload("data-v-299d01a0", __vue__options__)
  }
})()}