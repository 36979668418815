import {account} from './xhr';
import $store from '../store';
import $persistent from '../store/persistent';
import $i18n from '../config/i18n';
import {R} from './R';
import {AdminNav} from "../config/nav";
import _ from 'lodash';

export const accountService = {
	setUserData(d) {
		$store.commit('setUser', d.user);
		$store.commit('setNav', AdminNav.nav());
		$store.commit('setHomeUrl', 'admin');
		$store.commit('setEditLang', d.user.language);
		$i18n.locale = d.user.language;
	},
	user() {
		return $store.state.user;
	},
	userRole() {
		return (this.user() || {}).role;
	},
	isDeveloper() {
		return $store.state.user.role === 'ROLE_DEVELOPER';
	},
	isSuperAdmin() {
		return ['ROLE_SUPER_ADMIN', 'ROLE_DEVELOPER'].indexOf(this.userRole()) > -1;
	},
	login(cr) {
		return account.login(cr)
			.then(res => this.setUserData(res.data.data));
	},
	logout() {
		$store.commit('loaded');
		$store.commit('logout');
		account.logout();
		setTimeout(() => R.login(), 120);
	},
	load(afterLoad) {
		if ($persistent.state.uid === null) {
			$store.commit('loaded');
		}
		account.loadCurrent()
			.then(res => {
				$store.commit('loaded');
				this.setUserData(res.data);
				setTimeout(() => {
					if (_.isFunction(afterLoad)) {
						afterLoad();
					} else {
						R.home();
					}
				}, 120);
			})
			.catch(() => $store.commit('loaded'))
	},
}