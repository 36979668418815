;(function(){
'use strict';

Object.defineProperty(exports, "__esModule", {
  value: true
});

var _baseForm = require('../../../../../../base/baseForm');

var _baseForm2 = _interopRequireDefault(_baseForm);

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

exports.default = {
  name: 'inz-contact-form-contact',
  extends: _baseForm2.default
};
})()
if (module.exports.__esModule) module.exports = module.exports.default
var __vue__options__ = (typeof module.exports === "function"? module.exports.options: module.exports)
if (__vue__options__.functional) {console.error("[vueify] functional components are not supported and should be defined in plain js files using render functions.")}
__vue__options__.render = function render () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return (_vm.form)?_c('b-form-row',{staticClass:"inz-contact-form-contact"},[_c('b-col',{attrs:{"md":"6","sm":"12"}},[_c('inz-control-input',{attrs:{"labelt":"phone","type":"tel","name":"phone","placeholder":"+41"},model:{value:(_vm.form.phone),callback:function ($$v) {_vm.$set(_vm.form, "phone", $$v)},expression:"form.phone"}})],1),_vm._v(" "),_c('b-col',{attrs:{"md":"6","sm":"12"}},[_c('inz-control-input',{attrs:{"labelt":"mobile","type":"tel","name":"mobile","placeholder":"+41"},model:{value:(_vm.form.mobile),callback:function ($$v) {_vm.$set(_vm.form, "mobile", $$v)},expression:"form.mobile"}})],1),_vm._v(" "),_c('b-col',{attrs:{"md":"6","sm":"12"}},[_c('inz-control-input',{attrs:{"labelt":"email","required":"","type":"email","name":"email"},model:{value:(_vm.form.email),callback:function ($$v) {_vm.$set(_vm.form, "email", $$v)},expression:"form.email"}})],1)],1):_vm._e()}
__vue__options__.staticRenderFns = []
if (module.hot) {(function () {  var hotAPI = require("vue-hot-reload-api")
  hotAPI.install(require("vue"), true)
  if (!hotAPI.compatible) return
  module.hot.accept()
  if (!module.hot.data) {
    hotAPI.createRecord("data-v-1dc3b3b9", __vue__options__)
  } else {
    hotAPI.reload("data-v-1dc3b3b9", __vue__options__)
  }
})()}