import Vue from 'vue'
import Vuex from 'vuex'
import * as _ from 'lodash';

Vue.use(Vuex)

const local = {
	fill(target, data) {
		target.splice(0, target.length);
		_.each(data, it => target.push(it))
	},
}

const $store = new Vuex.Store({
	state: {
		// General DEV Mode
		jam: false,
		// Application + User Bootstrap
		loaded: false,
		// XHR Requests
		isLoading: false,
		// Editable input field current lang
		editLang: 'de',
		insurance: null,
		// User data
		nav: [],
		user: null,
		homeUrl: null,
		// Cache
		agents: [],
		partners: [],
		employees: [],
		consultants: [],
		generalAgencies: [],
		blocks: [],
		insurers: [],
		insurances: [],
		adjusters: [],
		insuranceTypes: [],
		contact: null,
		currentContract: null,
		api: 'live',
		environment: null,
		lastUpdate: null,
		version: null,
	},
	mutations: {
		setSystemData(s, d) {
			s.api = d.api;
			s.environment = d.environment;
			s.lastUpdate = d.lastUpdate;
			s.version = d.version;
		},
		setCurrentContract(s, v) {
			s.currentContract = v;
		},
		setOptions(s, opts) {
			if (opts.agents) {
				local.fill(s.agents, opts.agents);
			}
			if (opts.insurers) {
				local.fill(s.insurers, opts.insurers);
			}
			if (opts.generalAgencies) {
				local.fill(s.generalAgencies, opts.generalAgencies);
			}
			if (opts.partners) {
				local.fill(s.partners, opts.partners);
			}
			if (opts.consultants) {
				local.fill(s.consultants, opts.consultants);
			}
			if (opts.adjusters) {
				local.fill(s.adjusters, opts.adjusters);
			}
			if (opts.insuranceTypes) {
				local.fill(s.insuranceTypes, opts.insuranceTypes);
			}
			if (opts.employees) {
				local.fill(s.employees, _.sortBy(opts.employees, ['label']));
			}
			if (opts.insurances) {
				local.fill(s.insurances, opts.insurances);
			}
		},
		setEditLang(s, code) {
			s.editLang = code;
		},
		setInsurance(s, rec) {
			s.insurance = rec
		},
		setInsurances(s, recs) {
			local.fill(s.insurances, recs);
		},
		setUser(s, rec) {
			s.user = rec
		},
		setNav(s, recs) {
			local.fill(s.nav, recs);
		},
		setHomeUrl(s, url) {
			s.homeUrl = url;
		},
		xhrOn(s) {
			s.isLoading = true
		},
		xhrOff(s) {
			setTimeout(() => s.isLoading = false, 120);
		},
		loaded(s) {
			s.loaded = true;
		},
		logout(s) {
			s.user = null;
			s.nav.splice(0, s.nav.length)
			s.homeUrl = null;
		},
	},
})
export default $store;