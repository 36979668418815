'use strict';

import Vue from 'vue'
import Vuex from 'vuex'
import createPersistedState from 'vuex-persistedstate';

Vue.use(Vuex);
const store = new Vuex.Store({
	plugins: [createPersistedState()],
	state: {
		panels: [],
		api: 'live',
		filter: {
			performance: null,
			topBranches: null,
			leads: null,
			contacts: null,
			blocks: null,
			insurances: null,
			contracts: null,
		},
	},
	mutations: {
		setApi(S, v) {
			S.api = v;
		},
		setFilter(S, fc) {
			S.filter[fc.name] = fc.filter;
		},
		setPanels(S, panels) {
			S.panels.splice(0, S.panels.length);
			panels.map(it => S.panels.push(it));
		},
	},
});
export default store;
