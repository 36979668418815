;(function(){
'use strict';

Object.defineProperty(exports, "__esModule", {
  value: true
});

var _baseForm = require('../../../../../../base/baseForm');

var _baseForm2 = _interopRequireDefault(_baseForm);

var _xhr = require('../../../../../../service/xhr');

var _price = require('../price/price');

var _price2 = _interopRequireDefault(_price);

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

exports.default = {
  name: 'inz-contract-form-price',
  components: { InzPriceElement: _price2.default },
  extends: _baseForm2.default,
  mounted: function mounted() {
    this.loadPrice();
  },

  methods: {
    loadPrice: function loadPrice() {
      var _this = this;

      _xhr.priceFinder.load(this.form.id).then(function (r) {
        return _this.$set(_this.form, 'priceRanges', r.data);
      });
    }
  }
};
})()
if (module.exports.__esModule) module.exports = module.exports.default
var __vue__options__ = (typeof module.exports === "function"? module.exports.options: module.exports)
if (__vue__options__.functional) {console.error("[vueify] functional components are not supported and should be defined in plain js files using render functions.")}
__vue__options__.render = function render () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return (_vm.form.priceRanges)?_c('div',{staticClass:"inz-contract-form-price"},[_c('b-row',{staticClass:"mb-1"},[_c('b-col',{staticClass:"head-label",attrs:{"md":"5"}},[_vm._v("Produkt")]),_vm._v(" "),_c('b-col',{staticClass:"head-label",attrs:{"md":"3"}},[_vm._v("Preisspanne")]),_vm._v(" "),_c('b-col',{staticClass:"head-label",attrs:{"md":"2"}},[_vm._v("Prämie")]),_vm._v(" "),_c('b-col',{staticClass:"head-label",attrs:{"md":"2"}},[_vm._v("WP-ID")])],1),_vm._v(" "),_vm._l((_vm.form.priceRanges),function(item,$i){return _c('inz-price-element',{key:$i,model:{value:(_vm.form.priceRanges[$i]),callback:function ($$v) {_vm.$set(_vm.form.priceRanges, $i, $$v)},expression:"form.priceRanges[$i]"}})})],2):_vm._e()}
__vue__options__.staticRenderFns = []
if (module.hot) {(function () {  var hotAPI = require("vue-hot-reload-api")
  hotAPI.install(require("vue"), true)
  if (!hotAPI.compatible) return
  module.hot.accept()
  if (!module.hot.data) {
    hotAPI.createRecord("data-v-72079b16", __vue__options__)
  } else {
    hotAPI.reload("data-v-72079b16", __vue__options__)
  }
})()}