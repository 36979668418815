;(function(){
'use strict';

Object.defineProperty(exports, "__esModule", {
  value: true
});

var _promise = require('babel-runtime/core-js/promise');

var _promise2 = _interopRequireDefault(_promise);

var _lodash = require('lodash');

var _ = _interopRequireWildcard(_lodash);

var _persistent = require('../../store/persistent');

var _persistent2 = _interopRequireDefault(_persistent);

function _interopRequireWildcard(obj) { if (obj && obj.__esModule) { return obj; } else { var newObj = {}; if (obj != null) { for (var key in obj) { if (Object.prototype.hasOwnProperty.call(obj, key)) newObj[key] = obj[key]; } } newObj.default = obj; return newObj; } }

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

exports.default = {
  data: function data() {
    return {
      isLoading: false,
      panelData: null,
      filter: { start: 0, type: 'week' }
    };
  },
  mounted: function mounted() {
    if (_.isObject(_persistent2.default.state.filter[this.filterName])) {
      this.$set(this, 'filter', _persistent2.default.state.filter[this.filterName]);
    }
    this.load();
  },

  watch: {
    filter: {
      deep: true,
      handler: function handler() {
        this.load();
        this.safeFilter();
      }
    }
  },
  computed: {
    hasData: function hasData() {
      return this.panelData !== null;
    },
    filteredData: function filteredData() {
      return _.isObject(this.panelData) && this.panelData.data ? this.panelData.data : [];
    }
  },
  methods: {
    safeFilter: function safeFilter() {
      _persistent2.default.commit('setFilter', { name: this.filterName, filter: this.filter });
    },
    loadData: function loadData() {
      return _promise2.default.resolve({ data: null });
    },
    load: function load() {
      var _this = this;

      var fn = this.apiUrl;
      this.$set(this, 'isLoading', true);
      if (_.isFunction(fn)) {
        fn(this.filter).then(function (r) {
          _this.$set(_this, 'panelData', r.data);
          _this.$set(_this, 'isLoading', false);
        }).catch(function () {
          return console.warn('Promise error');
        });
      }
    }
  }
};
})()
if (module.exports.__esModule) module.exports = module.exports.default
var __vue__options__ = (typeof module.exports === "function"? module.exports.options: module.exports)
if (module.hot) {(function () {  var hotAPI = require("vue-hot-reload-api")
  hotAPI.install(require("vue"), true)
  if (!hotAPI.compatible) return
  module.hot.accept()
  if (!module.hot.data) {
    hotAPI.createRecord("data-v-4704d6a2", __vue__options__)
  } else {
    hotAPI.reload("data-v-4704d6a2", __vue__options__)
  }
})()}