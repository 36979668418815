;(function(){
'use strict';

Object.defineProperty(exports, "__esModule", {
  value: true
});

var _opts = require('../config/opts');

exports.default = {
  name: 'inz-lang-bar',
  computed: {
    langs: function langs() {
      return _opts.opts.languages;
    }
  },
  methods: {
    setLang: function setLang(lang) {
      this.$store.commit('setEditLang', lang);
    }
  }
};
})()
if (module.exports.__esModule) module.exports = module.exports.default
var __vue__options__ = (typeof module.exports === "function"? module.exports.options: module.exports)
if (__vue__options__.functional) {console.error("[vueify] functional components are not supported and should be defined in plain js files using render functions.")}
__vue__options__.render = function render () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('b-btn-group',{directives:[{name:"b-tooltip",rawName:"v-b-tooltip.hover",modifiers:{"hover":true}}],staticClass:"inz-lang-bar",attrs:{"title":_vm.$t('tooltip.langSwitch')}},_vm._l((_vm.langs),function(l){return _c('inz-btn',{key:l.code,class:{'active':_vm.$store.state.editLang===l.code},attrs:{"size":"sm"},domProps:{"innerHTML":_vm._s(l.code.toUpperCase())},on:{"click":function($event){return _vm.setLang(l.code)}}})}),1)}
__vue__options__.staticRenderFns = []
if (module.hot) {(function () {  var hotAPI = require("vue-hot-reload-api")
  hotAPI.install(require("vue"), true)
  if (!hotAPI.compatible) return
  module.hot.accept()
  if (!module.hot.data) {
    hotAPI.createRecord("data-v-057f3208", __vue__options__)
  } else {
    hotAPI.reload("data-v-057f3208", __vue__options__)
  }
})()}