import {R} from '../service/R';
import * as _ from 'lodash';

export default {
    props: {
        title: String,
        recs: Array,
        // currentPage: Number,
        // perPage: Number
    },
    computed: {
        fields() {
            return [];
        },
        fieldsTr() {
            return this.fields.map(it => Object.assign(it, {label: this.$i18n.t(it.label)}))
        },
        num() {
            return (_.isArray(this.filtered) && this.filtered.length > 0)
        },
        R() {
            return R;
        },
        filtered() {
            return _.isArray(this.recs) ? this.recs : []
        }
    }
}