;(function(){
'use strict';

Object.defineProperty(exports, "__esModule", {
  value: true
});

var _VueTypeaheadBootstrapListItem = require('./VueTypeaheadBootstrapListItem.vue');

var _VueTypeaheadBootstrapListItem2 = _interopRequireDefault(_VueTypeaheadBootstrapListItem);

var _clone = require('lodash/clone');

var _clone2 = _interopRequireDefault(_clone);

var _includes = require('lodash/includes');

var _includes2 = _interopRequireDefault(_includes);

var _isEmpty = require('lodash/isEmpty');

var _isEmpty2 = _interopRequireDefault(_isEmpty);

var _reject = require('lodash/reject');

var _reject2 = _interopRequireDefault(_reject);

var _reverse = require('lodash/reverse');

var _reverse2 = _interopRequireDefault(_reverse);

var _findIndex = require('lodash/findIndex');

var _findIndex2 = _interopRequireDefault(_findIndex);

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

var BEFORE_LIST_INDEX = -1;

function sanitize(text) {
  return text.replace(/</g, '&lt;').replace(/>/g, '&gt;');
}

function escapeRegExp(str) {
  return str.replace(/[.*+?^${}()|[\]\\]/g, '\\$&');
}

exports.default = {
  name: 'VueTypeaheadBootstrapList',

  components: {
    VueTypeaheadBootstrapListItem: _VueTypeaheadBootstrapListItem2.default
  },

  props: {
    data: {
      type: Array,
      required: true,
      validator: function validator(d) {
        return d instanceof Array;
      }
    },
    query: {
      type: String,
      default: ''
    },
    vbtUniqueId: {
      type: Number,
      required: true
    },
    backgroundVariant: {
      type: String
    },
    backgroundVariantResolver: {
      type: Function,
      default: function _default(d) {
        return null;
      },
      validator: function validator(d) {
        return d instanceof Function;
      }
    },
    disableSort: {
      type: Boolean
    },
    textVariant: {
      type: String
    },
    maxMatches: {
      type: Number,
      default: 10
    },
    minMatchingChars: {
      type: Number,
      default: 2
    },
    disabledValues: {
      type: Array,
      default: function _default() {
        return [];
      }
    },
    showOnFocus: {
      type: Boolean,
      default: false
    },
    showAllResults: {
      type: Boolean,
      default: false
    },
    highlightClass: {
      type: String,
      default: 'vbt-matched-text'
    }
  },

  created: function created() {
    this.$parent.$on('input', this.resetActiveListItem);
    this.$parent.$on('keyup', this.handleParentInputKeyup);
  },
  data: function data() {
    return {
      activeListItem: -1
    };
  },


  computed: {
    highlight: function highlight() {
      var _this = this;

      return function (text) {
        text = sanitize(text);
        if (_this.query.length === 0) {
          return text;
        }

        var re = new RegExp(_this.escapedQuery, 'gi');
        return text.replace(re, '<span class=\'' + _this.highlightClass + '\'>$&</span>');
      };
    },
    escapedQuery: function escapedQuery() {
      return escapeRegExp(sanitize(this.query));
    },
    actionableItems: function actionableItems() {
      var _this2 = this;

      return (0, _reject2.default)(this.matchedItems, function (matchedItem) {
        return _this2.isDisabledItem(matchedItem);
      });
    },
    matchedItems: function matchedItems() {
      var _this3 = this;

      if (!this.showOnFocus && ((0, _isEmpty2.default)(this.query) || this.query.length < this.minMatchingChars)) {
        return [];
      }

      var re = new RegExp(this.showAllResults ? '' : this.escapedQuery, 'gi');

      return this.data.filter(function (i) {
        return i.text.match(re) !== null;
      }).sort(function (a, b) {
        if (_this3.disableSort) return 0;

        var aIndex = a.text.indexOf(a.text.match(re)[0]);
        var bIndex = b.text.indexOf(b.text.match(re)[0]);

        if (aIndex < bIndex) {
          return -1;
        }
        if (aIndex > bIndex) {
          return 1;
        }
        return 0;
      }).slice(0, this.maxMatches);
    }
  },

  methods: {
    handleParentInputKeyup: function handleParentInputKeyup(e) {
      switch (e.keyCode) {
        case 40:
          this.selectNextListItem();
          break;
        case 38:
          this.selectPreviousListItem();
          break;
        case 13:
          this.hitActiveListItem();
          break;
      }
    },
    handleHit: function handleHit(item, evt) {
      this.$emit('hit', item);
      evt.preventDefault();
    },
    hitActiveListItem: function hitActiveListItem() {
      if (this.activeListItem < 0) {
        this.selectNextListItem();
      }
      if (this.activeListItem >= 0) {
        this.$emit('hit', this.matchedItems[this.activeListItem]);
      }
    },
    isDisabledItem: function isDisabledItem(item) {
      return (0, _includes2.default)(this.disabledValues, item.text);
    },
    isListItemActive: function isListItemActive(id) {
      return this.activeListItem === id;
    },
    resetActiveListItem: function resetActiveListItem() {
      this.activeListItem = -1;
    },
    findIndexForNextActiveItem: function findIndexForNextActiveItem(itemsToSearch, currentSelectedItem) {
      if (!itemsToSearch) {
        itemsToSearch = this.matchedItems;
      }
      if (currentSelectedItem === undefined) {
        currentSelectedItem = this.activeListItem;
      }

      var nextActiveIndex = (0, _findIndex2.default)(itemsToSearch, function (o) {
        return !this.isDisabledItem(o);
      }.bind(this), currentSelectedItem + 1);

      if (nextActiveIndex === BEFORE_LIST_INDEX) {
        nextActiveIndex = (0, _findIndex2.default)(itemsToSearch, function (o) {
          return !this.isDisabledItem(o);
        }.bind(this));
      }

      return nextActiveIndex;
    },
    selectNextListItem: function selectNextListItem() {
      if (this.actionableItems.length <= 0) {
        this.activeListItem = BEFORE_LIST_INDEX;
        return true;
      }

      this.activeListItem = this.findIndexForNextActiveItem();
    },
    selectPreviousListItem: function selectPreviousListItem() {
      if (this.actionableItems.length <= 0) {
        this.activeListItem = BEFORE_LIST_INDEX;
        return true;
      } else if (this.activeListItem === 0) {
        this.activeListItem = BEFORE_LIST_INDEX;
      }

      var reversedList = (0, _reverse2.default)((0, _clone2.default)(this.matchedItems));
      var currerntReversedIndex = this.matchedItems.length - 1 - this.activeListItem;
      var nextReverseIndex = this.findIndexForNextActiveItem(reversedList, currerntReversedIndex);

      this.activeListItem = this.matchedItems.length - 1 - nextReverseIndex;
    }
  },
  watch: {
    activeListItem: function activeListItem(newValue, oldValue) {
      if (!this.$parent.autoClose && this.$parent.isFocused === false) {
        this.$parent.isFocused = true;
      }
      if (newValue >= 0) {
        var scrollContainer = this.$refs.suggestionList;
        var listItem = scrollContainer.children[this.activeListItem];
        var scrollContainerlHeight = scrollContainer.clientHeight;
        var listItemHeight = listItem.clientHeight;
        var visibleItems = Math.floor(scrollContainerlHeight / (listItemHeight + 20));
        if (newValue >= visibleItems) {
          scrollContainer.scrollTop = listItemHeight * this.activeListItem;
        } else {
          scrollContainer.scrollTop = 0;
        }
        listItem.focus();
      }
    }
  }
};
})()
if (module.exports.__esModule) module.exports = module.exports.default
var __vue__options__ = (typeof module.exports === "function"? module.exports.options: module.exports)
if (__vue__options__.functional) {console.error("[vueify] functional components are not supported and should be defined in plain js files using render functions.")}
__vue__options__.render = function render () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{ref:"suggestionList",staticClass:"list-group shadow"},_vm._l((_vm.matchedItems),function(item,id){return _c('vue-typeahead-bootstrap-list-item',_vm._g({key:id,attrs:{"active":_vm.isListItemActive(id),"id":(_vm.isListItemActive(id)) ? ("selected-option-" + _vm.vbtUniqueId) : false,"data":item.data,"html-text":_vm.highlight(item.text),"role":"option","aria-selected":(_vm.isListItemActive(id)) ? 'true' : 'false',"screen-reader-text":(item.screenReaderText) ? item.screenReaderText : item.text,"disabled":_vm.isDisabledItem(item),"background-variant":_vm.backgroundVariant,"background-variant-resolver":_vm.backgroundVariantResolver,"text-variant":_vm.textVariant},nativeOn:{"click":function($event){return _vm.handleHit(item, $event)}},scopedSlots:_vm._u([{key:"suggestion",fn:function(ref){
var data = ref.data;
var htmlText = ref.htmlText;
return (_vm.$scopedSlots.suggestion)?[_vm._t("suggestion",null,null,{ data: data, htmlText: htmlText })]:undefined}}],null,true)},_vm.$listeners))}),1)}
__vue__options__.staticRenderFns = []
if (module.hot) {(function () {  var hotAPI = require("vue-hot-reload-api")
  hotAPI.install(require("vue"), true)
  if (!hotAPI.compatible) return
  module.hot.accept()
  if (!module.hot.data) {
    hotAPI.createRecord("data-v-61da2db9", __vue__options__)
  } else {
    hotAPI.reload("data-v-61da2db9", __vue__options__)
  }
})()}