;(function(){
'use strict';

Object.defineProperty(exports, "__esModule", {
  value: true
});

var _utils = require('../service/utils');

var _utils2 = _interopRequireDefault(_utils);

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

exports.default = {
  name: 'inz-cube-info-files',
  props: {
    items: Array
  },
  methods: {
    download: function download(it) {
      if (it.check && it.check === true) {
        _utils2.default.checkFile(it.url).then(function () {
          return _utils2.default.downloadFile(it.url);
        }).catch(function (error) {
          return _utils2.default.handleXhrError(error, 'fileDownload');
        });
      } else {
        _utils2.default.downloadFile(it.url);
      }
    }
  }
};
})()
if (module.exports.__esModule) module.exports = module.exports.default
var __vue__options__ = (typeof module.exports === "function"? module.exports.options: module.exports)
if (__vue__options__.functional) {console.error("[vueify] functional components are not supported and should be defined in plain js files using render functions.")}
__vue__options__.render = function render () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"inz-cube-info-files"},_vm._l((_vm.items),function(it,$i){return _c('div',{directives:[{name:"b-tooltip",rawName:"v-b-tooltip:hover",arg:"hover"}],key:$i,staticClass:"inz-cube-info-files--item",attrs:{"title":it.title},on:{"click":function($event){return _vm.download(it)}}},[_c('div',{staticClass:"label"},[_c('img',{attrs:{"src":"/build/images/pdf.svg","width":"20","height":"20","alt":"pdf"}}),_vm._v(" "+_vm._s(_vm.$t(it.label)))])])}),0)}
__vue__options__.staticRenderFns = []
if (module.hot) {(function () {  var hotAPI = require("vue-hot-reload-api")
  hotAPI.install(require("vue"), true)
  if (!hotAPI.compatible) return
  module.hot.accept()
  if (!module.hot.data) {
    hotAPI.createRecord("data-v-6126311c", __vue__options__)
  } else {
    hotAPI.reload("data-v-6126311c", __vue__options__)
  }
})()}