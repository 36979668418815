;(function(){
'use strict';

Object.defineProperty(exports, "__esModule", {
  value: true
});

var _baseControl = require('../../base/baseControl');

var _baseControl2 = _interopRequireDefault(_baseControl);

var _lodash = require('lodash');

var _ = _interopRequireWildcard(_lodash);

var _moment = require('moment');

var _moment2 = _interopRequireDefault(_moment);

var _opts = require('../../config/opts');

function _interopRequireWildcard(obj) { if (obj && obj.__esModule) { return obj; } else { var newObj = {}; if (obj != null) { for (var key in obj) { if (Object.prototype.hasOwnProperty.call(obj, key)) newObj[key] = obj[key]; } } newObj.default = obj; return newObj; } }

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

exports.default = {
  name: 'inz-control-daterangebtns',
  extends: _baseControl2.default,
  props: {
    value: Object,
    future: { type: Boolean, default: false }
  },
  methods: {
    rangeDown: function rangeDown() {
      this.val.start -= 1;
    },
    rangeUp: function rangeUp() {
      if (this.future === false && this.val.start === 0) {
        return;
      }
      this.val.start += 1;
    },
    setRangeType: function setRangeType(type) {
      this.val.type = type;
    },
    rangeCurrent: function rangeCurrent() {
      this.val.start = 0;
    }
  },
  computed: {
    canRangeUp: function canRangeUp() {
      return this.future === true || this.val.start < 0;
    },
    curDate: function curDate() {
      var m = (0, _moment2.default)();
      switch (this.val.type) {
        case 'week':
          m.add(this.val.start, 'weeks');
          return 'KW ' + m.isoWeek() + ' ' + m.format('YY');
        case 'month':
          m.add(this.val.start, 'months');
          return m.format('MMM YY');
        case 'year':
          m.add(this.val.start, 'years');
          return m.format('YYYY');
      }
    },
    calOpts: function calOpts() {
      return _opts.opts.dateRange;
    },

    val: {
      get: function get() {
        var V = this.value;
        return _.isObject(V) ? V : { start: 0, type: 'quarter' };
      },
      set: function set(v) {
        this.$emit('input', v);
      }
    }
  }
};
})()
if (module.exports.__esModule) module.exports = module.exports.default
var __vue__options__ = (typeof module.exports === "function"? module.exports.options: module.exports)
if (__vue__options__.functional) {console.error("[vueify] functional components are not supported and should be defined in plain js files using render functions.")}
__vue__options__.render = function render () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('b-btn-group',{staticClass:"inz-control-daterangebtns"},[_c('inz-btn',{attrs:{"size":"sm","icon":"chevron-left"},on:{"click":_vm.rangeDown}}),_vm._v(" "),_c('b-dropdown',{attrs:{"size":"sm","icon":"calendar","variant":"dust","no-caret":""},scopedSlots:_vm._u([{key:"button-content",fn:function(){return [_c('fa',{attrs:{"icon":"calendar"}})]},proxy:true}])},[_vm._v(" "),_vm._l((_vm.calOpts),function(o){return _c('b-dropdown-item',{class:{'active':o.id===_vm.value.type},on:{"click":function($event){return _vm.setRangeType(o.id)}}},[_vm._v("\n      "+_vm._s(_vm.$t(o.text))+"\n    ")])})],2),_vm._v(" "),_c('inz-btn',{attrs:{"size":"sm","label":_vm.curDate},on:{"click":_vm.rangeCurrent}}),_vm._v(" "),_c('inz-btn',{attrs:{"size":"sm","icon":"chevron-right","disabled":!_vm.canRangeUp},on:{"click":_vm.rangeUp}})],1)}
__vue__options__.staticRenderFns = []
if (module.hot) {(function () {  var hotAPI = require("vue-hot-reload-api")
  hotAPI.install(require("vue"), true)
  if (!hotAPI.compatible) return
  module.hot.accept()
  if (!module.hot.data) {
    hotAPI.createRecord("data-v-0d48cf37", __vue__options__)
  } else {
    hotAPI.reload("data-v-0d48cf37", __vue__options__)
  }
})()}