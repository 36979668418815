;(function(){
'use strict';

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = {
  name: 'inz-btn',
  props: {
    variant: { default: 'dust' },
    type: { default: 'button' },
    size: { default: 'md' },
    icon: { default: null },
    label: { default: null },
    labelt: { default: null },
    tooltip: { default: null }
  },
  methods: {
    click: function click() {
      this.$emit('click');
    }
  }
};
})()
if (module.exports.__esModule) module.exports = module.exports.default
var __vue__options__ = (typeof module.exports === "function"? module.exports.options: module.exports)
if (__vue__options__.functional) {console.error("[vueify] functional components are not supported and should be defined in plain js files using render functions.")}
__vue__options__.render = function render () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('b-button',{directives:[{name:"b-tooltip",rawName:"v-b-tooltip.hover",modifiers:{"hover":true}}],attrs:{"variant":_vm.variant,"title":_vm.tooltip,"size":_vm.size},on:{"click":_vm.click}},[(_vm.icon)?_c('span',[(_vm.icon)?_c('fa',{attrs:{"icon":_vm.icon}}):_vm._e()],1):_vm._e(),_vm._v(" "),(_vm.label)?_c('span',{domProps:{"innerHTML":_vm._s(_vm.label)}}):(_vm.labelt)?_c('span',{domProps:{"innerHTML":_vm._s(_vm.$t(_vm.labelt))}}):_vm._e(),_vm._v(" "),_vm._t("default")],2)}
__vue__options__.staticRenderFns = []
if (module.hot) {(function () {  var hotAPI = require("vue-hot-reload-api")
  hotAPI.install(require("vue"), true)
  if (!hotAPI.compatible) return
  module.hot.accept()
  if (!module.hot.data) {
    hotAPI.createRecord("data-v-d2f21d26", __vue__options__)
  } else {
    hotAPI.reload("data-v-d2f21d26", __vue__options__)
  }
})()}