;(function(){
'use strict';

Object.defineProperty(exports, "__esModule", {
  value: true
});

var _baseControl = require('../../base/baseControl');

var _baseControl2 = _interopRequireDefault(_baseControl);

var _vue2Dropzone = require('vue2-dropzone');

var _vue2Dropzone2 = _interopRequireDefault(_vue2Dropzone);

var _cf = require('../../config/cf');

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

exports.default = {
  name: 'inz-control-upload',
  extends: _baseControl2.default,
  props: {
    elementId: { type: String },
    imagesOnly: { type: Boolean, default: false },
    pdfOnly: { type: Boolean, default: false },
    maxFileSize: { type: Number, default: 10 },
    defaultMessage: { type: String, default: 'dropzone.defaultPdf' },
    fileType: {
      type: String,
      validator: function validator(it) {
        return _cf.cf.uploadableFileTypes.indexOf(it) > -1;
      }
    },
    remoteId: { type: Number, required: true }
  },
  components: {
    vueDropzone: _vue2Dropzone2.default
  },
  methods: {
    complete: function complete(a) {
      var item = JSON.parse(a.xhr.responseText);
      this.$emit('complete', item);
      this.$refs.dropzone.removeAllFiles();
    },
    sendingEvent: function sendingEvent(file, xhr, formData) {
      formData.append('fileTypeSlug', this.remoteFileType);
      formData.append('remoteId', this.remoteId);
    }
  },
  computed: {
    remoteFileType: function remoteFileType() {
      return this.fileType;
    },
    api: function api() {
      var s = this.$store.state.api;
      if (s.indexOf('localhost') > -1) {
        return s;
      }
      return s.replaceAll('http:', 'https:');
    },
    dropzoneOptions: function dropzoneOptions() {

      var acceptedFiles = [];
      if (this.imagesOnly) {
        acceptedFiles = 'image/*';
      }
      if (this.pdfOnly) {
        acceptedFiles = 'application/pdf';
      }

      return {
        dictDefaultMessage: this.$i18n.t(this.defaultMessage),
        acceptedFiles: acceptedFiles,
        url: this.api + '/file/upload',
        thumbnailWidth: 60,
        thumbnailHeight: 60,
        maxFilesize: this.maxFilesize
      };
    }
  }
};
})()
if (module.exports.__esModule) module.exports = module.exports.default
var __vue__options__ = (typeof module.exports === "function"? module.exports.options: module.exports)
if (__vue__options__.functional) {console.error("[vueify] functional components are not supported and should be defined in plain js files using render functions.")}
__vue__options__.render = function render () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return (_vm.remoteId)?_c('b-form-group',{staticClass:"inz-control inz-control-upload",attrs:{"label":_vm.labelS,"description":_vm.desc,"state":_vm.valid}},[_c('vue-dropzone',{ref:"dropzone",attrs:{"id":_vm.elementId,"options":_vm.dropzoneOptions},on:{"vdropzone-sending":_vm.sendingEvent,"vdropzone-complete":_vm.complete}})],1):_vm._e()}
__vue__options__.staticRenderFns = []
if (module.hot) {(function () {  var hotAPI = require("vue-hot-reload-api")
  hotAPI.install(require("vue"), true)
  if (!hotAPI.compatible) return
  module.hot.accept()
  if (!module.hot.data) {
    hotAPI.createRecord("data-v-4bb0cb00", __vue__options__)
  } else {
    hotAPI.reload("data-v-4bb0cb00", __vue__options__)
  }
})()}