;(function(){
'use strict';

Object.defineProperty(exports, "__esModule", {
  value: true
});

var _R = require('../../service/R');

var _account = require('../../service/account');

var _logohead = require('./logohead');

var _logohead2 = _interopRequireDefault(_logohead);

var _utils = require('../../service/utils');

var _utils2 = _interopRequireDefault(_utils);

var _val = require('../../service/val');

var _val2 = _interopRequireDefault(_val);

var _persistent = require('../../store/persistent');

var _persistent2 = _interopRequireDefault(_persistent);

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

exports.default = {
  components: { InzPublicLogohead: _logohead2.default },
  data: function data() {
    return {
      emailValid: null,
      passwordValid: null,
      api: 'live',
      cr: {
        email: '',
        password: ''
      }
    };
  },

  computed: {
    isValid: function isValid() {
      return this.emailValid && this.passwordValid;
    },
    optsApi: function optsApi() {
      return [{ id: 'live', text: 'Production' }, { id: 'test', text: 'Staging' }, { id: 'dev', text: 'Development' }];
    }
  },
  methods: {
    validateEmail: function validateEmail() {
      this.emailValid = _val2.default.validateEmail(this.cr.email);
    },
    validatePassword: function validatePassword() {
      this.passwordValid = this.cr.password.length > 3;
    },
    login: function login() {
      var _this = this;

      this.validateEmail();
      this.validatePassword();
      if (!this.isValid) {
        return;
      }

      _persistent2.default.commit('setApi', this.api);

      setTimeout(function () {
        return _account.accountService.login(_this.cr).then(function () {
          return setTimeout(function () {
            return _R.R.home();
          }, 120);
        }).catch(function () {
          return _utils2.default.toast('toastError.login', false);
        });
      }, 200);
    }
  }
};
})()
if (module.exports.__esModule) module.exports = module.exports.default
var __vue__options__ = (typeof module.exports === "function"? module.exports.options: module.exports)
if (__vue__options__.functional) {console.error("[vueify] functional components are not supported and should be defined in plain js files using render functions.")}
__vue__options__.render = function render () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('inz-public-logohead'),_vm._v(" "),(_vm.$store.state.loaded===true)?_c('inz-box',{staticClass:"m-auto w-30 mv-6 inz-login",attrs:{"variant":"white"}},[_c('b-form',{on:{"submit":_vm.login,"keydown":function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"enter",13,$event.key,"Enter")){ return null; }return _vm.login.apply(null, arguments)}}},[_c('inz-control-select',{attrs:{"required":"","labelt":"api","name":"api","opts":_vm.optsApi},model:{value:(_vm.api),callback:function ($$v) {_vm.api=$$v},expression:"api"}}),_vm._v(" "),_c('inz-control-input',{attrs:{"required":"","labelt":"email","name":"email","state":_vm.emailValid},on:{"blur":_vm.validateEmail,"keyup":_vm.validatePassword},model:{value:(_vm.cr.email),callback:function ($$v) {_vm.$set(_vm.cr, "email", $$v)},expression:"cr.email"}}),_vm._v(" "),_c('inz-control-input',{attrs:{"required":"","labelt":"password","type":"password","name":"password","state":_vm.passwordValid},on:{"blur":_vm.validatePassword,"keyup":_vm.validatePassword},model:{value:(_vm.cr.password),callback:function ($$v) {_vm.$set(_vm.cr, "password", $$v)},expression:"cr.password"}}),_vm._v(" "),_c('inz-actionbar',{staticClass:"mv-2"},[_c('inz-btn',{staticClass:"inz-btn-login",attrs:{"variant":"primary","disabled":!_vm.isValid,"labelt":"login","icon":"arrow-right","type":"submit"},on:{"click":_vm.login}})],1)],1)],1):_vm._e()],1)}
__vue__options__.staticRenderFns = []
if (module.hot) {(function () {  var hotAPI = require("vue-hot-reload-api")
  hotAPI.install(require("vue"), true)
  if (!hotAPI.compatible) return
  module.hot.accept()
  if (!module.hot.data) {
    hotAPI.createRecord("data-v-b1aff3f2", __vue__options__)
  } else {
    hotAPI.reload("data-v-b1aff3f2", __vue__options__)
  }
})()}