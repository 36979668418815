;(function(){
'use strict';

Object.defineProperty(exports, "__esModule", {
  value: true
});

var _langswitch = require('../../skeleton/langswitch');

var _langswitch2 = _interopRequireDefault(_langswitch);

var _logo = require('../../components/logo');

var _logo2 = _interopRequireDefault(_logo);

var _R = require('../../service/R');

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

exports.default = {
  name: 'inz-public-logohead',
  components: { Logo: _logo2.default, inzLangswitch: _langswitch2.default },
  methods: {
    home: function home() {
      _R.R.home();
    }
  }
};
})()
if (module.exports.__esModule) module.exports = module.exports.default
var __vue__options__ = (typeof module.exports === "function"? module.exports.options: module.exports)
if (__vue__options__.functional) {console.error("[vueify] functional components are not supported and should be defined in plain js files using render functions.")}
__vue__options__.render = function render () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"inz-public-logohead"},[_c('div',{staticClass:"m-auto i-t-center mt-4 mb-2"},[_c('img',{staticClass:"inz-logo",attrs:{"src":"/build/images/logo.svg","width":200,"alt":"INZURO"},on:{"click":_vm.home}}),_vm._v(" "),_c('div',{staticClass:"logo-admin"},[_vm._v("ADMIN")])])])}
__vue__options__.staticRenderFns = []
if (module.hot) {(function () {  var hotAPI = require("vue-hot-reload-api")
  hotAPI.install(require("vue"), true)
  if (!hotAPI.compatible) return
  module.hot.accept()
  if (!module.hot.data) {
    hotAPI.createRecord("data-v-6d8324dd", __vue__options__)
  } else {
    hotAPI.reload("data-v-6d8324dd", __vue__options__)
  }
})()}