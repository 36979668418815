;(function(){
'use strict';

Object.defineProperty(exports, "__esModule", {
  value: true
});

var _nav = require('../../components/nav');

var _nav2 = _interopRequireDefault(_nav);

var _nav3 = require('../../config/nav');

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

exports.default = {
  components: { InzNav: _nav2.default },
  data: function data() {
    return {
      showNav: false
    };
  },

  methods: {
    toggleNav: function toggleNav() {
      this.showNav = !this.showNav;
    },
    open: function open(el) {
      this.$emit('close');
      this.$router.push({ name: el.url, params: el.params });
    }
  },
  computed: {
    items: function items() {
      return _nav3.AdminNav.nav();
    }
  }
};
})()
if (module.exports.__esModule) module.exports = module.exports.default
var __vue__options__ = (typeof module.exports === "function"? module.exports.options: module.exports)
if (__vue__options__.functional) {console.error("[vueify] functional components are not supported and should be defined in plain js files using render functions.")}
__vue__options__.render = function render () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"inz-podium d-flex"},[_c('div',{staticClass:"inz-struct-nav"},[_c('inz-nav',{attrs:{"items":_vm.items}})],1),_vm._v(" "),_c('div',{staticClass:"inz-struct-main container"},[_c('router-view',{key:_vm.$route.fullPath})],1)])}
__vue__options__.staticRenderFns = []
if (module.hot) {(function () {  var hotAPI = require("vue-hot-reload-api")
  hotAPI.install(require("vue"), true)
  if (!hotAPI.compatible) return
  module.hot.accept()
  if (!module.hot.data) {
    hotAPI.createRecord("data-v-0000d504", __vue__options__)
  } else {
    hotAPI.reload("data-v-0000d504", __vue__options__)
  }
})()}