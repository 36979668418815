import Vue from 'vue'

import VueI18n from 'vue-i18n'
import messagesDe from './../translations/de'
import messagesFr from './../translations/fr'

Vue.use(VueI18n);

const $i18n = new VueI18n({
    locale: 'de',
    fallbackLocale: 'de',
    messages: {
        fr: messagesFr,
        de: messagesDe,
    }
});

export default $i18n;