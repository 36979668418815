;(function(){
'use strict';

Object.defineProperty(exports, "__esModule", {
  value: true
});

var _R2 = require('../../../service/R');

var _baseList = require('../../../base/baseList');

var _baseList2 = _interopRequireDefault(_baseList);

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

exports.default = {
  name: 'inz-insurance-listsmall',
  extends: _baseList2.default,
  props: {
    recs: { type: Array }
  },
  computed: {
    fields: function fields() {
      return [{ key: 'name', sortable: true, label: 'th.insurance' }, { key: 'dealer', sortable: true, label: 'th.partner' }, { key: 'contractType', sortable: true, label: 'th.contractType' }];
    },
    R: function R() {
      return _R2.R;
    },
    filtered: function filtered() {
      return this.recs;
    }
  }
};
})()
if (module.exports.__esModule) module.exports = module.exports.default
var __vue__options__ = (typeof module.exports === "function"? module.exports.options: module.exports)
if (__vue__options__.functional) {console.error("[vueify] functional components are not supported and should be defined in plain js files using render functions.")}
__vue__options__.render = function render () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return (_vm.recs)?_c('inz-box',{staticClass:"i-listsmall inz-insurance-listsmall",attrs:{"variant":"white"}},[_c('inz-title',{attrs:{"title":_vm.$t('mod.insurances')}},[_c('template',{slot:"right"},[_c('inz-records',{staticClass:"mr-1",attrs:{"num":_vm.recs.length}})],1)],2),_vm._v(" "),_c('b-table',{staticClass:"inz-table-list",attrs:{"hover":"","items":_vm.filtered,"fields":_vm.fieldsTr},scopedSlots:_vm._u([{key:"cell()",fn:function(data){return [_c('div',{staticClass:"clickable",on:{"click":function($event){return _vm.R.insuranceDetail(data.item.id)}}},[_vm._v(_vm._s(data.value))])]}},{key:"cell(dealer)",fn:function(data){return [_c('div',{staticClass:"clickable",on:{"click":function($event){return _vm.R.contactDetail(data.value.id)}}},[_vm._v(_vm._s(data.value.name))])]}}],null,false,101690975)})],1):_vm._e()}
__vue__options__.staticRenderFns = []
if (module.hot) {(function () {  var hotAPI = require("vue-hot-reload-api")
  hotAPI.install(require("vue"), true)
  if (!hotAPI.compatible) return
  module.hot.accept()
  if (!module.hot.data) {
    hotAPI.createRecord("data-v-2337b2e0", __vue__options__)
  } else {
    hotAPI.reload("data-v-2337b2e0", __vue__options__)
  }
})()}