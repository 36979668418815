;(function(){
'use strict';

Object.defineProperty(exports, "__esModule", {
  value: true
});

var _toConsumableArray2 = require('babel-runtime/helpers/toConsumableArray');

var _toConsumableArray3 = _interopRequireDefault(_toConsumableArray2);

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

exports.default = {
  name: 'VueTypeaheadBootstrapListItem',

  props: {
    active: {
      type: Boolean
    },
    data: {},
    screenReaderText: {
      type: String
    },
    htmlText: {
      type: String
    },
    disabled: {
      type: Boolean
    },
    backgroundVariant: {
      type: String
    },
    backgroundVariantResolver: {
      type: Function,
      default: function _default(d) {
        return null;
      },
      validator: function validator(d) {
        return d instanceof Function;
      }
    },
    textVariant: {
      type: String
    }
  },
  data: function data() {
    return {
      baseTextClasses: ['vbst-item', 'list-group-item', 'list-group-item-action']
    };
  },

  computed: {
    textClasses: function textClasses() {
      var classes = [].concat((0, _toConsumableArray3.default)(this.baseTextClasses));
      var backgroundVariantResolverResult = this.backgroundVariantResolver(this.data);
      var backgroundVariant = typeof backgroundVariantResolverResult === 'string' && backgroundVariantResolverResult.trim() || this.backgroundVariant;
      if (backgroundVariant) classes.push('list-group-item-' + backgroundVariant);
      if (this.textVariant) classes.push('text-' + this.textVariant);
      if (this.disabled) classes.push('disabled');
      return classes.join(' ');
    }
  },

  methods: {
    processFocusOut: function processFocusOut(evt) {
      var tgt = evt.relatedTarget;
      if (tgt && tgt.classList.contains('vbst-item')) {
        return;
      }

      this.$emit('listItemBlur');
    }
  }
};
})()
if (module.exports.__esModule) module.exports = module.exports.default
var __vue__options__ = (typeof module.exports === "function"? module.exports.options: module.exports)
if (__vue__options__.functional) {console.error("[vueify] functional components are not supported and should be defined in plain js files using render functions.")}
__vue__options__.render = function render () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('a',{class:_vm.textClasses,attrs:{"tabindex":"0","href":"#"},on:{"keydown":[function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"tab",9,$event.key,"Tab")){ return null; }return _vm.$emit('listItemBlur')},function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"esc",27,$event.key,["Esc","Escape"])){ return null; }$event.stopPropagation();$event.preventDefault();return _vm.$emit('listItemBlur')},function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"down",40,$event.key,["Down","ArrowDown"])){ return null; }$event.preventDefault();},function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"up",38,$event.key,["Up","ArrowUp"])){ return null; }$event.preventDefault();}],"keyup":[function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"down",40,$event.key,["Down","ArrowDown"])){ return null; }return _vm.$parent.selectNextListItem($event)},function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"up",38,$event.key,["Up","ArrowUp"])){ return null; }return _vm.$parent.selectPreviousListItem($event)}],"blur":_vm.processFocusOut}},[_c('div',{staticClass:"sr-only"},[_vm._v(_vm._s(_vm.screenReaderText))]),_vm._v(" "),_c('div',{attrs:{"aria-hidden":"true"}},[_vm._t("suggestion",function(){return [_c('span',{domProps:{"innerHTML":_vm._s(_vm.htmlText)}})]},null,{ data: _vm.data, htmlText: _vm.htmlText })],2)])}
__vue__options__.staticRenderFns = []
__vue__options__._scopeId = "data-v-4d505a28"
if (module.hot) {(function () {  var hotAPI = require("vue-hot-reload-api")
  hotAPI.install(require("vue"), true)
  if (!hotAPI.compatible) return
  module.hot.accept()
  if (!module.hot.data) {
    hotAPI.createRecord("data-v-4d505a28", __vue__options__)
  } else {
    hotAPI.reload("data-v-4d505a28", __vue__options__)
  }
})()}