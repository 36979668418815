import {R} from '../service/R';
import {security} from '../service/security';
import {opts} from '../config/opts';
import utils from '../service/utils';

export default {
    data() {
        return {
            currentPage: 1,
            perPage: 20,
            values: [],
            filter: {
                inpSearch: ''
            }
        }
    },
    computed: {
        fields() {
            return []
        },
        mayAdd() {
            return security.mayAdd();
        },
        fieldsTr() {
            return this.fields.map(it => Object.assign(it, {label: this.$i18n.t(it.label)}))
        },
        maxRows() {
            return this.values.length;
        },
        maxRowsFiltered() {
            return this.filtered.length;
        },
        R() {
            return R;
        },
        filtered() {
            return this.values;
        },
        api() {
            return null;
        },
        optsPages() {
            return opts.pageCluster();
        },
    },
    mounted() {
        if (this.api !== null) {
            this.api.list(this.filter)
                .then(r => this.$set(this, 'values', r.data.values))
                .catch(e => utils.handleXhrError(e, 'loadList'));
        }
    }
}

