;(function(){
"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});

var _insuranceType = require("../components/insuranceType");

var _insuranceType2 = _interopRequireDefault(_insuranceType);

var _export = require("../components/export");

var _export2 = _interopRequireDefault(_export);

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

exports.default = {
  components: { InzPanelSettingsExport: _export2.default, InzPanelAdminInsuranceTypes: _insuranceType2.default }
};
})()
if (module.exports.__esModule) module.exports = module.exports.default
var __vue__options__ = (typeof module.exports === "function"? module.exports.options: module.exports)
if (__vue__options__.functional) {console.error("[vueify] functional components are not supported and should be defined in plain js files using render functions.")}
__vue__options__.render = function render () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('inz-module-header',{attrs:{"type":"config","title":"Config"}}),_vm._v(" "),_c('b-row',[_c('b-col',{attrs:{"md":"6","sm":"12"}},[_c('inz-panel-admin-insurance-types')],1),_vm._v(" "),_c('b-col',{attrs:{"md":"6","sm":"12"}},[_c('inz-panel-settings-export')],1)],1)],1)}
__vue__options__.staticRenderFns = []
if (module.hot) {(function () {  var hotAPI = require("vue-hot-reload-api")
  hotAPI.install(require("vue"), true)
  if (!hotAPI.compatible) return
  module.hot.accept()
  if (!module.hot.data) {
    hotAPI.createRecord("data-v-4ef7236e", __vue__options__)
  } else {
    hotAPI.reload("data-v-4ef7236e", __vue__options__)
  }
})()}