import utils from '../service/utils';

export default {
    props: {
        value: {}, // Array, Object
        mode: {default: 'create'}
    },
    methods: {
        toast(label, isSuccess) {
            utils.toast(label, isSuccess);
        },
        onSubmit() {
            this.$emit('submit')
        },
        onReset() {
            this.$emit('reset')
        },
        fieldValid(name) {

        }
    },
    computed: {
        modeEdit() {
            return this.mode === 'edit'
        },
        modeCreate() {
            return this.mode === 'create'
        },
        form: {
            get() {
                return this.value;
            },
            set(v) {
                this.$emit('input', v);
            }
        }
    }
}