;(function(){
'use strict';

Object.defineProperty(exports, "__esModule", {
  value: true
});

var _baseControl = require('../../base/baseControl');

var _baseControl2 = _interopRequireDefault(_baseControl);

var _moment = require('moment');

var _moment2 = _interopRequireDefault(_moment);

var _lodash = require('lodash');

var _ = _interopRequireWildcard(_lodash);

function _interopRequireWildcard(obj) { if (obj && obj.__esModule) { return obj; } else { var newObj = {}; if (obj != null) { for (var key in obj) { if (Object.prototype.hasOwnProperty.call(obj, key)) newObj[key] = obj[key]; } } newObj.default = obj; return newObj; } }

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

exports.default = {
  name: 'inz-control-birthday',
  extends: _baseControl2.default,
  data: function data() {
    return {
      tmp: null,
      month: null,
      day: null,
      year: null
    };
  },
  mounted: function mounted() {
    this.loadVal();
  },

  watch: {
    'val': function val() {
      this.loadVal();
    }
  },
  methods: {
    loadVal: function loadVal() {
      if (this.val && (0, _moment2.default)(this.val).isValid()) {
        this.month = (0, _moment2.default)(this.val).format('MM');
        this.year = (0, _moment2.default)(this.val).format('YYYY');
        this.day = (0, _moment2.default)(this.val).format('DD');
      }
    },
    blur: function blur() {
      if (this.date) {
        this.$emit('input', this.date.format('YYYY-MM-DD'));
      }
    }
  },
  computed: {
    monthOpts: function monthOpts() {
      var T = this.$i18n;
      return [{ text: T.t('select.month'), value: null }, { text: T.t('months.jan'), value: '01' }, { text: T.t('months.feb'), value: '02' }, { text: T.t('months.mar'), value: '03' }, { text: T.t('months.apr'), value: '04' }, { text: T.t('months.mai'), value: '05' }, { text: T.t('months.jun'), value: '06' }, { text: T.t('months.jul'), value: '07' }, { text: T.t('months.aug'), value: '08' }, { text: T.t('months.sep'), value: '09' }, { text: T.t('months.oct'), value: '10' }, { text: T.t('months.nov'), value: '11' }, { text: T.t('months.dec'), value: '12' }];
    },
    years: function years() {
      var end = (0, _moment2.default)().format('YYYYY') - 18;
      var start = end - 85;
      return [{
        text: this.$i18n.t('select.year'), value: null
      }].concat(_.range(start, end).map(function (i) {
        return { value: i, text: i };
      }));
    },
    date: function date() {
      if (this.year !== null && this.month !== null && this.day !== null) {
        return (0, _moment2.default)(this.year + '-' + this.month + '-' + this.day);
      }
      return null;
    }
  }
};
})()
if (module.exports.__esModule) module.exports = module.exports.default
var __vue__options__ = (typeof module.exports === "function"? module.exports.options: module.exports)
if (__vue__options__.functional) {console.error("[vueify] functional components are not supported and should be defined in plain js files using render functions.")}
__vue__options__.render = function render () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('b-form-group',{staticClass:"inz-control",attrs:{"label":_vm.labelS,"valid-feedback":_vm.validFeedback,"invalid-feedback":_vm.invalidFeedback,"state":_vm.valid}},[_c('b-form-row',[_c('b-col',[_c('b-form-input',{attrs:{"max":31,"min":1,"state":_vm.valid,"maxlength":"2","type":"number","placeholder":_vm.$t('placeholder.day'),"disabled":_vm.disabled},on:{"keyup":_vm.blur},model:{value:(_vm.day),callback:function ($$v) {_vm.day=$$v},expression:"day"}})],1),_vm._v(" "),_c('b-col',[_c('b-form-select',{attrs:{"options":_vm.monthOpts,"state":_vm.valid,"disabled":_vm.disabled},on:{"blur":_vm.blur,"change":_vm.blur},model:{value:(_vm.month),callback:function ($$v) {_vm.month=$$v},expression:"month"}})],1),_vm._v(" "),_c('b-col',[_c('b-form-select',{attrs:{"options":_vm.years,"state":_vm.valid,"disabled":_vm.disabled},on:{"blur":_vm.blur,"change":_vm.blur},model:{value:(_vm.year),callback:function ($$v) {_vm.year=$$v},expression:"year"}})],1)],1),_vm._v(" "),_c('div',{staticClass:"error-msg"},[_vm._t("error")],2)],1)}
__vue__options__.staticRenderFns = []
if (module.hot) {(function () {  var hotAPI = require("vue-hot-reload-api")
  hotAPI.install(require("vue"), true)
  if (!hotAPI.compatible) return
  module.hot.accept()
  if (!module.hot.data) {
    hotAPI.createRecord("data-v-4b8263bc", __vue__options__)
  } else {
    hotAPI.reload("data-v-4b8263bc", __vue__options__)
  }
})()}