import {R} from '../service/R';
import utils from '../service/utils';

export default {
    data() {
        return {
            // TODO xhr must be computed and from store!
            rec: null
        }
    },
    methods: {
        load() {
            return this.api.edit(this.$route.params.id);
        },
        create() {
            return this.api.create(this.rqData());
        },
        update() {
            return this.api.update(this.$route.params.id, this.rqData());
        },
        save() {
            (this.isNew ? this.create() : this.update())
                .then(r => {
                    utils.toast(this.$i18n.t(`toastSuccess.save`), true);
                    this.view(r.data.id);
                })
                .catch(e => utils.handleXhrError(e, 'save'));
        },
        cancel() {
            this.view();
        },
        view() {
            this.R.home();
        },
        up(d) {
            this.$set(this, 'rec', d.data);
        },
        rqData() {
            return this.rec;
        }
    },
    mounted() {
        this.$set(this, 'rec', null);
        this.load()
            .then(r => this.$set(this, 'rec', r.data))
            .catch(e => utils.handleXhrError(e, 'load'))
    },
    computed: {
        xhr() {
            return utils.xhr();
        },
        isNew() {
            return !this.rec.id;
        },
        // Overwrite this method with your API, like contact, claim,...
        api() {
            return null;
        },
        R() {
            return R;
        },
        canSave() {
            return this.xhr === false;
        },
        canCancel() {
            return this.xhr === false;
        }
    }
}