import $i18n from './i18n';
import * as _ from 'lodash';

export default {
    confirm(_cf) {
        return _.defaults((_cf || {}), {
            title: $i18n.t('confirmRm.title'),
            // autoHideDelay: 3000,
            // size: 'sm',
            okVariant: 'danger',
            okTitle: $i18n.t('btn.yes'),
            cancelTitle: $i18n.t('btn.no'),
            hideHeaderClose: false,
            centered: true
        });
    },
    toast(type = true) {
        return {
            title: $i18n.t('toast' + (type === true ? 'Success' : 'Error') + '.title'),
            noAutoHide: !type,
            autoHideDelay: type === true ? 1900 : null,
            variant: type === true ? 'success' : 'danger',
            static: true
        }
    }
}