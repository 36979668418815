import * as _ from 'lodash';
import $store from '../store';
import {axios, general} from './xhr';
import {bus} from './bus';
import i18n from "../config/i18n";

function genLetter() {
	let a = '_-:=()/.$!?123456789aQWERTZUIOPASDFGHJKLYXCVBNMqwertzuiopasdfghjklyxcvbnmüäö_-:=()/.$!?';
	return a[_.random(0, a.length - 1)];
}

export default {
	init() {
		return general.init()
			.then(r => $store.commit('setSystemData', r.data))
			.catch(e => console.error(e));
	},
	screenWidth() {
		return (window.innerWidth > 0) ? window.innerWidth : screen.width;
	},
	toast(label, success) {
		bus.$emit('toast', {label: label, success: success === true});
	},
	xhr() {
		return $store.state.isLoading === true
	},
	xhrOn() {
		$store.commit('xhrOn')
	},
	xhrOff() {
		$store.commit('xhrOff')
	},
	isAuthorized() {
		return _.isObject($store.state.user);
	},
	opts() {
		return general.opts()
			.then(r => {
				_.each(r.data.data, (pack, key) => $store.commit('set' + _.startCase(key), pack));
				return r.data.data;
			})
			.catch(() => console.error('Failed to load opts'));
	},
	// Do we need this here?
	u() {
		return $store.state.user;
	},
	findInRecord(object, str) {
		let has = false;
		if (_.isString(object) || _.isNumber(object)) {
			if (_.isNumber(str)) {
				has = Number(object) === Number(str);
			} else {
				has = String(object + '').toLowerCase().indexOf(str.toLowerCase()) !== -1;
			}
		} else {
			_.each(object, value => {
				if (_.isString(value)) {
					has = has || String(value + '').toLowerCase().indexOf(str.toLowerCase()) !== -1;
				} else if (_.isObject(value) || _.isArray(value)) {
					has = has || this.findInRecord(value, str)
				}
			});
		}
		return has;
	},
	parseBool(val) {
		return !!JSON.parse(String(val).toLowerCase());
	},
	filterRecords(items, str) {
		if (!str || (str || '').length <= 0) {
			return items;
		}
		// Search for ID
		if (_.isNumber(str) || String(Number(str)) === str) {
			return _.filter(items, {id: Number(str)});
		}

		// Search any content
		return _.filter(items, item => this.findInRecord(item, str));
	},
	parseGoogleAddress(ac) {
		if (!ac || !_.isArray(ac)) {
			return false
		}
		let res = {};
		_.each(ac, it => {
			if (it.types[0] === 'establishment') {
				res.name = it.long_name;
			}
			if (it.types[0] === 'street_number') {
				res.streetNumber = it.long_name;
			}
			if (it.types[0] === 'country') {
				res.country = it.short_name;
			}
			// if (it.types[0] === 'administrative_area_level_1') {
			//     res.state = it.long_name;
			// }
			//if (it.types[0] === 'administrative_area_level_2') {
			if (it.types[0] === 'locality' && it.types[1] === 'political') {
				res.city = it.long_name;
			}
			if (it.types[0] === 'route') {
				res.address = it.long_name;
			}
			if (it.types[0] === 'postal_code') {
				res.plz = it.long_name;
			}
			// if (it.types[0] === 'administrative_area_level_2') {
			//     res.state = it.long_name;
			// }
		});
		if (res.address && res.streetNumber) {
			res.address += ' ' + res.streetNumber
			delete res.streetNumber;
		}
		if (res.name && !res.address) {
			res.address = res.name;
		}
		return res;
	},
	genPassword(len = 12) {
		let res = [];
		while (res.length < len) {
			res.push(genLetter());
		}
		return res.join('');
	},
	checkFile(url) {
		return axios.get(url + '/check');
	},
	downloadFile(url) {
		this.xhrOn();
		const path = `${location.protocol}//${location.host}${url}`;
		let a = document.createElement('a');
		a.href = path;
		a.click();
		setTimeout(() => {
			this.xhrOff();
		}, 1200);

	},
	isEmail(string) {
		const exp = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
		return !!exp.exec(string);
	},
	handleXhrError(error, name) {
		if (!_.isObject(error)) {
			return this.toast((error || name), false);
		}

		if (error.response.status === 401) {
			this.toast('toastError.autoLogout', false)
			setTimeout(() => account.logout(), 1200);
		} else {
			name = i18n.t(`toastError.${name || 'general'}`);
			this.toast((error.response.data.message || name), false);
		}
	},
}