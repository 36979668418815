;(function(){
'use strict';

Object.defineProperty(exports, "__esModule", {
  value: true
});

var _base = require('./base');

var _base2 = _interopRequireDefault(_base);

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

exports.default = {
  extends: _base2.default,
  name: 'inz-question-text'
};
})()
if (module.exports.__esModule) module.exports = module.exports.default
var __vue__options__ = (typeof module.exports === "function"? module.exports.options: module.exports)
if (__vue__options__.functional) {console.error("[vueify] functional components are not supported and should be defined in plain js files using render functions.")}
__vue__options__.render = function render () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('div',{staticClass:"inz-question--grid"},[_c('div',{staticClass:"inz-question--question"},[_c('label',{domProps:{"innerHTML":_vm._s(_vm.question.question)}})]),_vm._v(" "),_c('div',{staticClass:"inz-question--input"},[_c('b-form-input',{model:{value:(_vm.val),callback:function ($$v) {_vm.val=$$v},expression:"val"}})],1)])])}
__vue__options__.staticRenderFns = []
if (module.hot) {(function () {  var hotAPI = require("vue-hot-reload-api")
  hotAPI.install(require("vue"), true)
  if (!hotAPI.compatible) return
  module.hot.accept()
  if (!module.hot.data) {
    hotAPI.createRecord("data-v-57e972bc", __vue__options__)
  } else {
    hotAPI.reload("data-v-57e972bc", __vue__options__)
  }
})()}