;(function(){
"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});

var _backups = require("../components/backups");

var _backups2 = _interopRequireDefault(_backups);

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

exports.default = {
  components: { InzPanelAdminBackups: _backups2.default }
};
})()
if (module.exports.__esModule) module.exports = module.exports.default
var __vue__options__ = (typeof module.exports === "function"? module.exports.options: module.exports)
if (__vue__options__.functional) {console.error("[vueify] functional components are not supported and should be defined in plain js files using render functions.")}
__vue__options__.render = function render () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('inz-module-header',{attrs:{"type":"backups","title":"Backups"}}),_vm._v(" "),_c('inz-panel-admin-backups')],1)}
__vue__options__.staticRenderFns = []
if (module.hot) {(function () {  var hotAPI = require("vue-hot-reload-api")
  hotAPI.install(require("vue"), true)
  if (!hotAPI.compatible) return
  module.hot.accept()
  if (!module.hot.data) {
    hotAPI.createRecord("data-v-c43de040", __vue__options__)
  } else {
    hotAPI.reload("data-v-c43de040", __vue__options__)
  }
})()}