;(function(){
"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});

var _logo = require("./logo");

var _logo2 = _interopRequireDefault(_logo);

var _icons = require("../config/icons");

var _langswitch = require("../skeleton/langswitch");

var _langswitch2 = _interopRequireDefault(_langswitch);

var _account = require("../skeleton/account");

var _account2 = _interopRequireDefault(_account);

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

exports.default = {
  name: 'inz-nav',
  components: { InzAccount: _account2.default, InzLangswitch: _langswitch2.default, InzLogo: _logo2.default },
  props: {
    items: Array
  },
  methods: {
    open: function open(el) {
      this.$router.push({ name: el.url, params: el.params });
    },
    icon: function icon(it) {
      return _icons.icons[it.slug];
    },
    isActive: function isActive(url) {
      return this.mod(url) === this.mod(this.$route.name);
    },
    mod: function mod(url) {
      return url.replace('Overview', '').replace('Detail', '').replace('Edit', '');
    }
  }
};
})()
if (module.exports.__esModule) module.exports = module.exports.default
var __vue__options__ = (typeof module.exports === "function"? module.exports.options: module.exports)
if (__vue__options__.functional) {console.error("[vueify] functional components are not supported and should be defined in plain js files using render functions.")}
__vue__options__.render = function render () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"inz-nav"},[_c('b-navbar',{attrs:{"toggleable":"lg","type":"dark"}},[_c('b-navbar-nav',{staticClass:"flex-column"},_vm._l((_vm.items),function(n,$i){return _c('li',{key:$i,staticClass:"inz-nav-item",class:'inz-nav-item--'+n.slug},[_c('a',{staticClass:"nav-link clickable no-select",class:{'active':_vm.isActive(n.url)},on:{"click":function($event){$event.preventDefault();return _vm.open(n)}}},[_c('span',{staticClass:"inz-nav-icon"},[_c('fa',{attrs:{"icon":_vm.icon(n)}})],1),_vm._v(" "),_c('span',{staticClass:"inz-nav-label",domProps:{"innerHTML":_vm._s(n.label)}})])])}),0)],1),_vm._v(" "),_c('inz-langswitch'),_vm._v(" "),_c('b-navbar',{attrs:{"toggleable":"lg","type":"dark"}},[_c('inz-account')],1)],1)}
__vue__options__.staticRenderFns = []
if (module.hot) {(function () {  var hotAPI = require("vue-hot-reload-api")
  hotAPI.install(require("vue"), true)
  if (!hotAPI.compatible) return
  module.hot.accept()
  if (!module.hot.data) {
    hotAPI.createRecord("data-v-3715c666", __vue__options__)
  } else {
    hotAPI.reload("data-v-3715c666", __vue__options__)
  }
})()}