export default {
    validateSlug(slug, slugs) {
        const reg = /^[a-z][a-z\-]*[a-z0-9-_]$/i;
        let errors = [];
        if (slug.length < 2) {
            errors.push('slug ist zu kurz. Mind. 2 Zeichen');
        } else if (slugs.indexOf(slug) > -1) {
            errors.push('Dieser Slug existiert bereits und kann nicht erneut verwendet werden.');
        } else if (!slug.match(reg)) {
            errors.push('Ungültige Zeichen. Ein Slug kann nur Zeichen a-z und Nummern 0-9 enthalten');
        }

        return errors;
    },
}