;(function(){
'use strict';

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = {
  name: 'inz-expandable',
  props: {
    title: {},
    subtitle: {},
    isCollapsed: { type: Boolean, default: false }
  },
  mounted: function mounted() {
    this.$set(this, 'collapsed', this.isCollapsed);
  },
  data: function data() {
    return {
      collapsed: false
    };
  },

  methods: {
    toggle: function toggle() {
      this.$set(this, 'collapsed', !this.collapsed);
    }
  },
  computed: {
    hasSubtitleSlot: function hasSubtitleSlot() {
      return !!this.$slots.subtitle;
    },
    hasTitleSlot: function hasTitleSlot() {
      return !!this.$slots.title;
    }
  }
};
})()
if (module.exports.__esModule) module.exports = module.exports.default
var __vue__options__ = (typeof module.exports === "function"? module.exports.options: module.exports)
if (__vue__options__.functional) {console.error("[vueify] functional components are not supported and should be defined in plain js files using render functions.")}
__vue__options__.render = function render () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"inz-expandable"},[_c('div',{staticClass:"d-flex justify-content-center align-items-center i-t-b"},[_c('div',{staticClass:"f-auto clickable",on:{"click":_vm.toggle}},[_vm._v("\n      "+_vm._s(_vm.title)+"\n      "),(_vm.hasTitleSlot)?_vm._t("title"):_vm._e(),_vm._v(" "),_c('small',{staticClass:"i-hl"},[_vm._v("\n        "+_vm._s(_vm.subtitle)+"\n        "),(_vm.hasSubtitleSlot)?_vm._t("subtitle"):_vm._e()],2)],2),_vm._v(" "),_c('div',{staticClass:"right"},[_c('inz-btn',{attrs:{"icon":_vm.collapsed?'chevron-down':'chevron-up',"size":"sm"},on:{"click":_vm.toggle}})],1)]),_vm._v(" "),(_vm.collapsed===false)?_c('div',[_vm._t("content")],2):_vm._e()])}
__vue__options__.staticRenderFns = []
if (module.hot) {(function () {  var hotAPI = require("vue-hot-reload-api")
  hotAPI.install(require("vue"), true)
  if (!hotAPI.compatible) return
  module.hot.accept()
  if (!module.hot.data) {
    hotAPI.createRecord("data-v-0061f5fe", __vue__options__)
  } else {
    hotAPI.reload("data-v-0061f5fe", __vue__options__)
  }
})()}