;(function(){
'use strict';

Object.defineProperty(exports, "__esModule", {
  value: true
});

var _xhr = require('../../../service/xhr');

var _header = require('../../../components/panels/header');

var _header2 = _interopRequireDefault(_header);

var _utils = require('../../../service/utils');

var _utils2 = _interopRequireDefault(_utils);

var _lodash = require('lodash');

var _ = _interopRequireWildcard(_lodash);

function _interopRequireWildcard(obj) { if (obj && obj.__esModule) { return obj; } else { var newObj = {}; if (obj != null) { for (var key in obj) { if (Object.prototype.hasOwnProperty.call(obj, key)) newObj[key] = obj[key]; } } newObj.default = obj; return newObj; } }

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

exports.default = {
  name: 'inz-panel-admin-export',
  components: { InzPanelHeader: _header2.default },
  mounted: function mounted() {
    this.load();
  },
  data: function data() {
    return {
      raw: null
    };
  },

  methods: {
    load: function load() {
      var _this = this;

      _xhr.exportLog.stats().then(function (r) {
        return _this.$set(_this, 'raw', r.data);
      }).catch(function (e) {
        return _utils2.default.handleXhrError(e);
      });
    }
  },
  computed: {
    infos: function infos() {
      var _this2 = this;

      if (!this.raw) {
        return null;
      }

      return _.keys(this.raw.details).map(function (key) {
        return {
          label: 'exportType.' + key,
          value: _this2.raw.details[key],
          type: 'number'
        };
      });
    },
    summary: function summary() {
      var _this3 = this;

      if (!this.raw) {
        return null;
      }

      return _.keys(this.raw.summary).map(function (key) {
        return {
          label: 'label.' + key,
          value: _this3.raw.summary[key],
          type: 'number'
        };
      });
    }
  }
};
})()
if (module.exports.__esModule) module.exports = module.exports.default
var __vue__options__ = (typeof module.exports === "function"? module.exports.options: module.exports)
if (__vue__options__.functional) {console.error("[vueify] functional components are not supported and should be defined in plain js files using render functions.")}
__vue__options__.render = function render () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return (_vm.raw)?_c('div',{staticClass:"inz-panel inz-admin-export inz-infolist-2col"},[_c('b-card',{staticClass:"mb-1"},[_c('inz-panel-header',{attrs:{"slot":"header","title-label":"s2rExportStats"},slot:"header"}),_vm._v(" "),_c('b-row',[_c('b-col',{attrs:{"md":"6","sm":"12"}},[_c('inz-infolist',{staticClass:"ph-1 mb-1",attrs:{"infos":_vm.infos}})],1),_vm._v(" "),_c('b-col',{attrs:{"md":"6","sm":"12"}},[_c('inz-infolist',{staticClass:"ph-1 mb-1",attrs:{"infos":_vm.summary}})],1)],1)],1)],1):_vm._e()}
__vue__options__.staticRenderFns = []
if (module.hot) {(function () {  var hotAPI = require("vue-hot-reload-api")
  hotAPI.install(require("vue"), true)
  if (!hotAPI.compatible) return
  module.hot.accept()
  if (!module.hot.data) {
    hotAPI.createRecord("data-v-7e2ea713", __vue__options__)
  } else {
    hotAPI.reload("data-v-7e2ea713", __vue__options__)
  }
})()}