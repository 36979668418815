;(function(){
"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});

var _xhr = require("../../../service/xhr");

var _bar = require("../../../components/charts/bar");

var _bar2 = _interopRequireDefault(_bar);

var _daterangebtns = require("../../../components/control/daterangebtns");

var _daterangebtns2 = _interopRequireDefault(_daterangebtns);

var _line = require("../../../components/charts/line");

var _line2 = _interopRequireDefault(_line);

var _doughnut = require("../../../components/charts/doughnut");

var _doughnut2 = _interopRequireDefault(_doughnut);

var _polarArea = require("../../../components/charts/polarArea");

var _polarArea2 = _interopRequireDefault(_polarArea);

var _performance = require("./charts/performance");

var _performance2 = _interopRequireDefault(_performance);

var _turnover = require("./charts/turnover");

var _turnover2 = _interopRequireDefault(_turnover);

var _volume = require("./charts/volume");

var _volume2 = _interopRequireDefault(_volume);

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

var ix = 0;

exports.default = {
  components: {
    ChartVolume: _volume2.default,
    ChartTurnover: _turnover2.default,
    ChartPerformance: _performance2.default, InzChartPolarArea: _polarArea2.default, InzChartDoughnut: _doughnut2.default, InzChartLine: _line2.default, InzControlDaterangebtns: _daterangebtns2.default, InzChartBar: _bar2.default
  },
  mounted: function mounted() {
    this.load();
  },

  watch: {
    'filter': {
      handler: function handler() {
        var _this = this;

        this.show = false;
        this.$nextTick(function () {
          return _this.show = true;
        });
      },

      deep: true
    }
  },
  data: function data() {
    return {
      show: true,
      filter: {
        start: 0,
        type: 'week'
      },
      recs: null
    };
  },

  methods: {
    load: function load() {
      var _this2 = this;

      _xhr.performance.contracts().then(function (r) {
        return _this2.$set(_this2, 'recs', r.data);
      });
    }
  }
};
})()
if (module.exports.__esModule) module.exports = module.exports.default
var __vue__options__ = (typeof module.exports === "function"? module.exports.options: module.exports)
if (__vue__options__.functional) {console.error("[vueify] functional components are not supported and should be defined in plain js files using render functions.")}
__vue__options__.render = function render () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return (_vm.recs&&_vm.show)?_c('div',[_c('inz-module-header',{attrs:{"type":"performance","title":"Performance"}},[_c('template',{slot:"right"},[_c('inz-control-daterangebtns',{model:{value:(_vm.filter),callback:function ($$v) {_vm.filter=$$v},expression:"filter"}})],1)],2),_vm._v(" "),_c('chart-performance',{attrs:{"recs":_vm.recs,"filter":_vm.filter}}),_vm._v(" "),_c('b-row',[_c('b-col',{attrs:{"md":"6","sm":"12"}},[_c('chart-turnover',{attrs:{"recs":_vm.recs,"filter":_vm.filter}})],1),_vm._v(" "),_c('b-col',{attrs:{"md":"6","sm":"12"}},[_c('chart-volume',{attrs:{"recs":_vm.recs,"filter":_vm.filter}})],1)],1)],1):_vm._e()}
__vue__options__.staticRenderFns = []
if (module.hot) {(function () {  var hotAPI = require("vue-hot-reload-api")
  hotAPI.install(require("vue"), true)
  if (!hotAPI.compatible) return
  module.hot.accept()
  if (!module.hot.data) {
    hotAPI.createRecord("data-v-3a981f42", __vue__options__)
  } else {
    hotAPI.reload("data-v-3a981f42", __vue__options__)
  }
})()}