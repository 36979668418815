;(function(){
'use strict';

Object.defineProperty(exports, "__esModule", {
  value: true
});

var _vueChartjs = require('vue-chartjs');

exports.default = {
  extends: _vueChartjs.Doughnut,
  name: 'inz-chart-doughnut',
  props: ['chartdata', 'options', 'width', 'height'],
  mounted: function mounted() {
    this.renderChart(this.chartdata, this.options);
    this.addPlugin({
      id: 'custom_canvas_background_image'
    });
  }
};
})()
if (module.exports.__esModule) module.exports = module.exports.default
var __vue__options__ = (typeof module.exports === "function"? module.exports.options: module.exports)
if (module.hot) {(function () {  var hotAPI = require("vue-hot-reload-api")
  hotAPI.install(require("vue"), true)
  if (!hotAPI.compatible) return
  module.hot.accept()
  if (!module.hot.data) {
    hotAPI.createRecord("data-v-4865ccbf", __vue__options__)
  } else {
    hotAPI.reload("data-v-4865ccbf", __vue__options__)
  }
})()}