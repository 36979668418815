;(function(){
"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});

var _lodash = require("lodash");

var _ = _interopRequireWildcard(_lodash);

var _daterangebtns = require("../../../../components/control/daterangebtns");

var _daterangebtns2 = _interopRequireDefault(_daterangebtns);

var _doughnut = require("../../../../components/charts/doughnut");

var _doughnut2 = _interopRequireDefault(_doughnut);

var _helper = require("./helper");

var _helper2 = _interopRequireDefault(_helper);

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

function _interopRequireWildcard(obj) { if (obj && obj.__esModule) { return obj; } else { var newObj = {}; if (obj != null) { for (var key in obj) { if (Object.prototype.hasOwnProperty.call(obj, key)) newObj[key] = obj[key]; } } newObj.default = obj; return newObj; } }

var ix = 0;

exports.default = {
  name: 'chart-volume',
  components: { InzChartDoughnut: _doughnut2.default, InzControlDaterangebtns: _daterangebtns2.default },
  props: {
    recs: Array,
    filter: Object
  },
  data: function data() {
    return {
      type: 'list'
    };
  },

  methods: {
    setType: function setType(t) {
      this.$set(this, 'type', t);
    },
    color: function color() {
      return _helper2.default.color();
    },
    sumValuesForPartner: function sumValuesForPartner(partnerId) {
      return _.sum(this.recs.filter(function (it) {
        return it.partner_id === partnerId;
      }).map(function (it) {
        return it.num;
      }));
    },
    valuesForPartner: function valuesForPartner(partnerId) {
      var _this = this;

      return this.dateRange.map(function (d) {
        return _.sum(_this.recs.filter(function (it) {
          return d.year() === it.year && d.month() === it.month && d.day() === it.day && it.partner_id === partnerId;
        }).map(function (it) {
          return it.num;
        }));
      });
    }
  },
  computed: {
    chartData: function chartData() {
      return {
        labels: this.labels,
        datasets: this.datasets
      };
    },
    datasets: function datasets() {
      var _this2 = this;

      var m = this.allPartners.map(function (partnerId) {
        var data = _this2.valuesForPartner(partnerId);
        return {
          partnerId: partnerId,
          data: data
        };
      });

      return _.filter(m, function (it) {
        return _.sum(it.data) > 0;
      }).map(function (it) {
        var col = _this2.color();
        return {
          label: it.partnerId,
          data: it.data,
          borderColor: col,
          backgroundColor: col
        };
      });
    },
    allPartners: function allPartners() {
      var _this3 = this;

      return _.filter(_.uniq(this.recs.map(function (it) {
        return it.partner_id;
      })).map(function (partnerId) {
        return _this3.sumValuesForPartner(partnerId) > 0 ? partnerId : null;
      }));
    },
    labels: function labels() {
      return this.dateRange.map(function (it) {
        return it.format('DD.MM');
      });
    },
    dateRange: function dateRange() {
      return _helper2.default.dateRange(this.filter);
    },
    opts: function opts() {
      return {
        responsive: true,
        maintainAspectRatio: false,
        plugins: {
          legend: {
            display: false
          }
        },
        scales: {
          yAxes: [{
            ticks: {
              beginAtZero: true
            }
          }]
        }
      };
    }
  }
};
})()
if (module.exports.__esModule) module.exports = module.exports.default
var __vue__options__ = (typeof module.exports === "function"? module.exports.options: module.exports)
if (__vue__options__.functional) {console.error("[vueify] functional components are not supported and should be defined in plain js files using render functions.")}
__vue__options__.render = function render () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"chart-volume"},[_c('inz-title',{attrs:{"title":"Volume"}},[_c('template',{slot:"actions"},[_c('b-btn-group',{staticClass:"inz-control-daterangebtns"},[_c('inz-btn',{class:{'active':this.type==='list'},attrs:{"icon":"list","size":"sm","tooltip":"Als Liste"},on:{"click":function($event){return _vm.setType('list')}}}),_vm._v(" "),_c('inz-btn',{class:{'active':this.type==='cube'},attrs:{"icon":"dollar","size":"sm","tooltip":"Als Liste"},on:{"click":function($event){return _vm.setType('cube')}}})],1)],1)],2),_vm._v(" "),_c('inz-chart-doughnut',{attrs:{"chartdata":_vm.chartData,"height":250,"width":300,"options":_vm.opts}})],1)}
__vue__options__.staticRenderFns = []
if (module.hot) {(function () {  var hotAPI = require("vue-hot-reload-api")
  hotAPI.install(require("vue"), true)
  if (!hotAPI.compatible) return
  module.hot.accept()
  if (!module.hot.data) {
    hotAPI.createRecord("data-v-76af9ba1", __vue__options__)
  } else {
    hotAPI.reload("data-v-76af9ba1", __vue__options__)
  }
})()}