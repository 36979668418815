;(function(){
'use strict';

Object.defineProperty(exports, "__esModule", {
  value: true
});

var _input = require('./input');

var _input2 = _interopRequireDefault(_input);

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

exports.default = {
  name: 'inz-control-number',
  extends: _input2.default,
  props: {
    type: { default: 'number' }
  }
};
})()
if (module.exports.__esModule) module.exports = module.exports.default
var __vue__options__ = (typeof module.exports === "function"? module.exports.options: module.exports)
if (module.hot) {(function () {  var hotAPI = require("vue-hot-reload-api")
  hotAPI.install(require("vue"), true)
  if (!hotAPI.compatible) return
  module.hot.accept()
  if (!module.hot.data) {
    hotAPI.createRecord("data-v-42cdb448", __vue__options__)
  } else {
    hotAPI.reload("data-v-42cdb448", __vue__options__)
  }
})()}