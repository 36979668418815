;(function(){
'use strict';

Object.defineProperty(exports, "__esModule", {
  value: true
});

var _listsmall = require('./listsmall');

var _listsmall2 = _interopRequireDefault(_listsmall);

var _xhr = require('../../../../service/xhr');

var _dEdit = require('./dEdit');

var _dEdit2 = _interopRequireDefault(_dEdit);

var _dRemove = require('./dRemove');

var _dRemove2 = _interopRequireDefault(_dRemove);

var _utils = require('../../../../service/utils');

var _utils2 = _interopRequireDefault(_utils);

var _header = require('../../../../components/panels/header');

var _header2 = _interopRequireDefault(_header);

var _boxRmMargin = require('../../../../components/box/boxRmMargin');

var _boxRmMargin2 = _interopRequireDefault(_boxRmMargin);

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

exports.default = {
  components: {
    InzBoxRmMargin: _boxRmMargin2.default, InzPanelHeader: _header2.default, DInsuranceTypeRemove: _dRemove2.default, DInsuranceTypeEdit: _dEdit2.default, InzAdminInsuranceTypesList: _listsmall2.default
  },
  name: 'inz-panel-admin-insurance-types',
  methods: {
    edit: function edit(it) {
      this.$refs.edit.open(it);
    },
    add: function add() {
      this.$refs.edit.open();
    },
    rm: function rm(id) {
      this.$refs.rm.open(id);
    },
    load: function load() {
      var _this = this;

      _xhr.admin.insuranceTypes().then(function (r) {
        return _this.$set(_this, 'recs', r.data);
      }).catch(function (e) {
        return _utils2.default.handleXhrError(e, 'load');
      });
    }
  },
  mounted: function mounted() {
    this.load();
  },
  data: function data() {
    return {
      recs: null
    };
  }
};
})()
if (module.exports.__esModule) module.exports = module.exports.default
var __vue__options__ = (typeof module.exports === "function"? module.exports.options: module.exports)
if (__vue__options__.functional) {console.error("[vueify] functional components are not supported and should be defined in plain js files using render functions.")}
__vue__options__.render = function render () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return (_vm.recs)?_c('inz-box',{staticClass:"inz-admin-insurance-types",attrs:{"variant":"white","title":"Versicherungs Arten"}},[_c('d-insurance-type-edit',{ref:"edit",attrs:{"insurance-types":_vm.recs},on:{"reload":_vm.load}}),_vm._v(" "),_c('d-insurance-type-remove',{ref:"rm",on:{"reload":_vm.load}}),_vm._v(" "),_c('inz-panel-header',{staticClass:"d-flex align-content-end",attrs:{"slot":"header","title-label":"insuranceTypes"},slot:"header"},[(_vm.recs)?_c('inz-records',{staticClass:"mr-1",attrs:{"num":_vm.recs.length}}):_vm._e(),_vm._v(" "),_c('inz-btn',{attrs:{"icon":"plus","variant":"primary","size":"sm"},on:{"click":_vm.add}})],1),_vm._v(" "),_c('inz-box-rm-margin',[_c('inz-admin-insurance-types-list',{attrs:{"recs":_vm.recs},on:{"edit":_vm.edit,"rm":_vm.rm,"add":_vm.add}})],1)],1):_vm._e()}
__vue__options__.staticRenderFns = []
if (module.hot) {(function () {  var hotAPI = require("vue-hot-reload-api")
  hotAPI.install(require("vue"), true)
  if (!hotAPI.compatible) return
  module.hot.accept()
  if (!module.hot.data) {
    hotAPI.createRecord("data-v-1b136ac2", __vue__options__)
  } else {
    hotAPI.reload("data-v-1b136ac2", __vue__options__)
  }
})()}