;(function(){
'use strict';

Object.defineProperty(exports, "__esModule", {
  value: true
});

var _baseControl = require('../../base/baseControl');

var _baseControl2 = _interopRequireDefault(_baseControl);

var _lodash = require('lodash');

var _ = _interopRequireWildcard(_lodash);

function _interopRequireWildcard(obj) { if (obj && obj.__esModule) { return obj; } else { var newObj = {}; if (obj != null) { for (var key in obj) { if (Object.prototype.hasOwnProperty.call(obj, key)) newObj[key] = obj[key]; } } newObj.default = obj; return newObj; } }

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

exports.default = {
  name: 'inz-control-select',
  extends: _baseControl2.default,
  props: {
    opts: Array
  },
  computed: {
    optsFlat: function optsFlat() {
      var _this = this;

      return _.map(this.opts, function (it) {
        return { value: it.id, text: it.label ? _this.$i18n.t(it.label) : it.text };
      });
    }
  }
};
})()
if (module.exports.__esModule) module.exports = module.exports.default
var __vue__options__ = (typeof module.exports === "function"? module.exports.options: module.exports)
if (__vue__options__.functional) {console.error("[vueify] functional components are not supported and should be defined in plain js files using render functions.")}
__vue__options__.render = function render () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('b-form-group',{staticClass:"inz-control",attrs:{"label":_vm.labelS,"valid-feedback":_vm.validFeedback,"invalid-feedback":_vm.invalidFeedback,"state":_vm.state}},[_c('div',{staticClass:"input-group"},[_vm._t("prepend"),_vm._v(" "),_c('b-form-select',{attrs:{"options":_vm.optsFlat,"name":_vm.name,"required":_vm.required,"state":_vm.state,"size":_vm.size,"placeholder":_vm.placeh,"disabled":_vm.disabled},on:{"blur":_vm.blur},model:{value:(_vm.val),callback:function ($$v) {_vm.val=$$v},expression:"val"}}),_vm._v(" "),_vm._t("append")],2),_vm._v(" "),_c('div',{staticClass:"error-msg"},[_vm._t("error")],2)])}
__vue__options__.staticRenderFns = []
if (module.hot) {(function () {  var hotAPI = require("vue-hot-reload-api")
  hotAPI.install(require("vue"), true)
  if (!hotAPI.compatible) return
  module.hot.accept()
  if (!module.hot.data) {
    hotAPI.createRecord("data-v-110c070a", __vue__options__)
  } else {
    hotAPI.reload("data-v-110c070a", __vue__options__)
  }
})()}