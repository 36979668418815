/**
 * MASTER LANGUAGE FILE
 * only change this file, and adapt the translations to this.
 */
export default {
	login: 'Login',
	id: 'ID',
	records: 'Einträge',
	questions: 'Fragen',
	yes: 'Ja',
	no: 'Nein',
	all: 'Alle',
	monthsPeriod: 'Monate Laufzeit',
	none: 'Keine',
	pleaseSelect: 'Bitte wählen',
	directEmployeesOfPartner: 'Keine Filiale zugewiesen',
	isAdmin: 'Admin',
	isDeleted: 'Gelöscht',
	untitled: 'unbenannt',
	inDays: 'Tage',
	inMonths: 'Monate',
	years: 'Jahre',
	exportType: {
		contractExport: 'Vertrag',
		contractCancel: 'Vertrag Storno',
		contract: 'Vertrag',
		claimExport: 'Schadenfall',
		claim: 'Schadenfall',
		contact: 'Partner',
		partner: 'Partner',
		claimDamagedObject: 'Bild',
		shop: 'Zweigstellen',
		// TEMP! temove this: damagedObjectFiles from BE
		damagedObjectFiles: 'Bild',
	},
	lang: {
		de: 'Deutsch',
		fr: 'Französisch',
		it: 'Italienisch',
		en: 'English',
	},
	format: {
		json: 'JSON',
		excel: 'Microsoft Excel',
		csv: 'CSV',
	},
	country: {
		ch: 'Schweiz',
		li: 'Liechtenstein',
	},
	dCustomerEdit: {
		title: 'Kontakt bearbeiten',
	},
	formAddress: {
		title: 'Adresse',
	},
	nav: {
		contactOverview: 'Kontakte',
		contractOverview: 'Verträge',
		blockOverview: 'Produkte',
		insuranceOverview: 'Versicherungen',
		claimOverview: 'Schadenfälle',
		imprint: 'Impressum',
		privacy: 'Datenschutz',
	},
	contactDetail: {
		title: 'Kontakt',
		titleLeads: `Kontakt`,
		titleCustomers: `Kunden`,
	},
	contactTree: {
		titleBranchesEmployees: 'Unternehmensstruktur',
	},
	claimState: {
		incomplete: 'Unvollständig',
		pending: 'Pendent',
		open: 'Pendent',
		denied: 'Abgelehnt',
		approved: 'Akzeptiert',
		cancelled: 'Storniert',
	},
	confirmRm: {
		title: 'Löschen',
		text: 'Diesen Datensatz wirklich löschen?',
		textImage: 'Dieses Bild wirklich löschen?',
	},
	confirmCancel: {
		title: 'Stornieren',
		text: 'Diesen Datensatz wirklich stornieren?',
	},
	contactType: {
		super_admin: 'Super Admin',
		tester: 'Tester',
		generalAgency: 'General Agentur',
		apb: 'APB',
		agent: 'Vermittler',
		partner: 'Partner',
		customer: 'Kunde',
		developer: 'Entwickler',
		shop: 'Zweigstelle',
		shop_admin: 'Mitarbeiter',
		shop_employee: 'Mitarbeiter',
		employee: 'Mitarbeiter',
		underwriter: 'Underwriter',
		insurance: 'Versicherer', // Legacy, try to remove
		insurer: 'Versicherer',
		consultant: 'Berater',
		consultant_admin: 'Berater',
		generalagentur: 'Generalagentur',
		schadenregulierer: 'Schadenregulierer',
		adjuster: 'Schadenregulierer',
		dealer_admin: 'Partner',
		dealer: 'Partner',
	},
	blockOverview: {
		title: 'Produkte',
		tooltipAdd: 'Neues Produkt anlegen',
	},
	blockEdit: {
		titleCreate: 'Neues Produkt anlegen',
		titleEdit: 'Produkt bearbeiten',
	},
	blockFinder: {
		title: 'Produkt finden…',
		placeholderSearch: 'Name, Beschreibung',
		infoSelected: 'Selektiert',
	},
	formBlock: {
		titleDurations: 'Laufzeiten',
		tabBasics: 'Einstellungen',
		tabContent: 'Inhalte',
	},
	blockDetail: {
		infoNoUsage: 'Das Produkt ist nicht in Verwendung',
		titlePriceFieldLabel: 'Preis Label',
		titleProvisions: 'Provisionen',
		titleDescription: 'Beschreibung',
		titleQuestions: 'Fragen',
		titleAttachments: 'Dateien',
		titleInsures: 'Versichert',
		titleConditions: 'Konditionen',
		titleUsage: 'Verwendung',
		titleYear: 'Jahr',
		tooltipAddCondition: 'Kondition hinzufügen',
		tooltipAddRange: 'Preisspanne hinzufügen',
		tooltipAddQuestion: 'Frage hinzufügen',
	},
	contactOverview: {
		title: 'Kontakte',
		tooltipAdd: 'Neuen Kontakt anlegen',
	},
	blockType: {
		// base: 'Fixe Anzahl Jahre',
		split_year: 'Variable Anzahl Jahre',
		multi_year: 'Fixe Anzahl Jahre',
		inventar: 'Inventar Produkt',
	},
	contractType: {
		b2b: 'B2B',
		b2b2c: 'B2B2C',
		inventar: 'Inventar',
	},
	insuranceType: {
		garantie: 'Garantie',
		garantie3: 'Garantie 3',
		kasko: 'Kasko',
		haftpflichtversicherung: 'Haftpflichtversicherung',
		allrisk: 'All Risk',
	},
	contractState: {
		cancelled: 'Storniert',
	},
	badge: {
		incomplete: 'unvollständig',
		cancelled: 'Storniert',
		notActivated: 'aktivierung…',
	},
	formInsurance: {
		titleRequiredInformation: 'Benötigte Details',
		titleBasics: 'Basisdaten',
		titleBlocks: 'Produkte',
		titleSettings: 'Einstellungen',
	},
	label: {
		api: 'API Server',
		dateOfContract: 'Vertragsabschluss',
		priceOfContractConclusion: 'Total Prämie',
		exportedItems: 'Total exportiert',
		failedExportItems: 'Fehlerhaft',
		eventType: 'Event',
		eventTitle: 'Event',
		lead: 'Lead',
		contact: 'Kontakt',
		date: 'Datum',
		time: 'Zeit',
		funnelAction: 'Status',
		importantNotes: 'Wichtige Notizen',
		notes: 'Gesprächsnotizen',
		dateContact: 'Kontaktiert am',
		contactedBy: 'Kontaktiert durch',
		turnover: 'Umsatz',
		numContracts: 'Anzahl Verträge',
		dateRecall: 'Rückruf Datum',
		dateMeeting: 'Datum Meeting',
		timeMeeting: 'Zeit Meeting',
		timeRecall: 'Zeit Anruf',
		requestMeeting: 'Meeting erwünscht?',
		funnelState: 'Status',
		requestRecall: 'Erneut anrufen?',
		zipFrom: 'PLZ von',
		zipTo: 'PLZ bis',
		meetingAccepted: 'Meeting vereinbart',
		contactReached: 'Kontakt erreicht?',
		contractState: 'Status',
		numContract: 'Verträge',
		numPartner: 'Partner',
		numInsurances: 'Versicherungen',
		recordExported: 'Exportiert am',
		iban: 'IBAN',
		mwst: 'MwSt.',
		machineLoad: 'Serverauslastung',
		claim: 'Schadensmeldung',
		costs: 'Schadensumme',
		dateLatestLogin: 'Letztes Login',
		dataFormat: 'Ausgabeformat',
		username: 'Benutzername',
		apiKey: `API Key`,
		// TMP INZURO-176
		partnerLink: 'Partner verlinkt',
		discountInPercent: 'Rabatt in %',
		discount: 'Rabatt',
		warrantyProductId: 'Warranty Product ID',
		isClaimed: 'Schaden gemeldet',
		damagedObjectFiles: 'Bilder zum Vorfall',
		isCompany: 'Juristische Form',
		isAPerson: 'Person',
		isACompany: 'Firma',
		lastUpdated: 'Letztes Update',
		contractId: 'Vertragsnummer',
		version: 'Version',
		destinationShop: `Zweigstelle`,
		sendPassword: 'Initial Passwort Anfrage senden',
		downloadCertificate: 'Zertifikat PDF',
		birthday: 'Geburtstag',
		tariff: 'Tarife',
		deduction: 'Selbstbehalt',
		seller: 'Verkäufer',
		answerType: 'Antwort Typ',
		correctAnswer: 'Richtige Antwort',
		question: 'Frage',
		factsSheet: 'Facts Sheet',
		imei: 'IMEI',
		ean: 'EAN',
		avb: 'AVB',
		min: 'Min.',
		max: 'Max.',
		yes: 'Ja',
		no: 'Nein',
		license: 'Lizenziert für',
		unit: 'Einheit',
		priceFieldLabel: 'Preis Label',
		caseNo: 'Fall Nr.',
		locationAccident: 'Ort des Vorfalls',
		descriptionAccident: 'Beschreibung des Vorfalls',
		summary: 'Was ist geschehen?',
		platform: 'Platform',
		employee: 'Verkäufer',
		usePartnerLogo: `Logo des Partners für PDF's verwenden?`,
		insuranceType: 'Versicherungsart',
		description: 'Beschreibung',
		insures: 'Versichert',
		productName: 'Produktbezeichnung',
		blockType: 'Produktart',
		sms: 'SMS',
		whatsapp: 'Whatsapp',
		mainColor: 'Basis Farbe',
		updatedBy: 'Von',
		canCreateInsurance: 'Kann Versicherungen erstellen',
		generalAgentur: 'Generalagentur',
		dealer: 'Partner',
		vermittler: 'Vermittler',
		schadenregulierer: 'Schadenregulierer',
		communicationWay: 'Kommunikation per',
		contactNumber: 'Kontakt Nr',
		company: 'Firmenname',
		firstname: 'Vorname',
		lastname: 'Nachname',
		branchName: 'Name der Zweigstelle',
		name: 'Name',
		phone: 'Telefon',
		website: 'Website',
		additional: 'Zusatz',
		country: 'Land',
		dateOfPurchase: 'Kaufdatum',
		manufacturer: 'Hersteller',
		isAdmin: 'Admin Rechte',
		model: 'Model',
		year: 'Jahr',
		years: 'Jahre',
		month: 'Monat',
		months: 'Monate',
		day: 'Tag',
		days: 'Tage',
		week: 'Woche',
		weeks: 'Wochen',
		price: 'Kaufpreis',
		deviceNumber: 'Gerätenummer',
		objectPrice: 'Gerät Kaufpreis',
		birthdate: 'Geburtstag',
		plz: 'PLZ',
		address: 'Adresse',
		city: 'Stadt',
		mobile: 'Mobil',
		contract: 'Police',
		maxYears: 'Max. Jahre',
		noticePeriod: 'Stornofrist',
		isRenewable: 'Ist erneuerbar',
		supplierWarranty: 'Herstellergarantie',
		contractType: 'Vertragsart',
		contractNr: 'Vertragsnummer',
		salesPerson: 'Verkäufer',
		dateOfAccident: 'Datum',
		status: 'Status',
		email: 'E-Mail Adresse',
		password: 'Password',
		password1: 'Passwort',
		password2: 'Passwort wiederholen',
		contactType: 'Kontaktart',
		commercRegisterNumber: 'Handelsregister Nr.',
		language: 'Sprache',
		generalAgency: 'General Agentur',
		period: 'Laufzeit',
		adjuster: 'Schadenregulierer',
		evaluationType: 'Art der Auswertung',
		agent: 'Vermittler',
		partner: 'Partner',
		shop: 'Zweigstelle',
		consultant: 'Berater',
		block: 'Produkt',
		blocks: 'Produkte',
		mwstNumber: 'MwSt.Nr.',
		insurance: 'Versicherung',
		insurer: 'Versicherer',
		dateCreated: 'Angelegt am',
		dateUpdated: 'Zuletzt geändert',
		numberOfEmployees: 'Anzahl Mitarbeiter',
		insuredObject: 'Versichertes Objekt',
		objectValue: 'Sachwert',
		customer: 'Kunde',
		contractPdf: 'Vertrag PDF',
		CH: 'Schweiz',
		LI: 'Liechtenstein',
		herstellerGarantie: 'Herstellergarantie',
		duration: 'Laufzeit',
		latestPurchaseDate: 'Spätestes Kaufdatum',
		earliestPurchaseDate: 'Frühstes Kaufdatum',
		maxStornoDays: 'Stornierbar innert',
		policyNumber: 'Policen Nr.',
		renewalMonths: 'Laufzeit ab Verlängerung',
		priceMin: 'Von',
		priceMax: 'Bis',
		nettoPraemie: 'Netto Prämie',
		bruttoPraemie: 'Brutto Prämie',
		renewBruttoPraemie: 'Brutto Prämie Erneuerung',
		renewNettoPraemie: 'Netto Prämie Erneuerung',
		customerPriceIsPercentage: 'Selbstbehalt in',
		praemieInPercent: 'Prämie in',
		customerPriceMinimum: 'Selbstbehalt min.',
		customerPrice: 'Selbstbehalt',
		fee: 'Prämie',
		allowOnlineClaim: 'Schadenmeldung auf INZURO zulassen?',
		salesPersonSelectable: 'Verkäufer auswählbar?',
		isUmsatzSolution: 'Preise in Zertifikat anzeigen?',
	},
	rangeEditor: {
		title: 'Preisspanne bearbeiten',
	},
	priceEditor: {
		title: 'Preis bearbeiten',
	},
	toastSuccess: {
		eventSaved: 'Updates gesichert',
		resolved: 'Erledigt',
		activate: 'Vertrag aktiviert',
		sendPartnerOnboarding: 'Onboarding E-Mail verschickt',
		reload: 'Daten neu geladen',
		generateCertificate: 'Vertrag neu generiert.',
		resetPw: 'Passwort neu gesetzt',
		resetPwRequest: 'Passwortanfrage per E-Mail geschickt',
		title: 'Erfolgreich',
		rm: 'Der Datensatz wurde gelöscht',
		setPw: 'Account Passwort gesetzt',
		imp: 'Erfolgreich impersoniert',
		cancel: 'Erfolgreich storniert',
		save: 'Erfolgreich gesichert',
		approve: 'Schadenfall akzeptiert',
		reopen: 'Schadenfall wiedereröffnet',
		deny: 'Schadenfall ablehnen',
		posSave: 'Vertrag angelegt!',
		duplicate: 'Datensatz dupliziert',
		confirm: 'Bestätigung geschickt',
	},
	toastError: {
		posNoInsurance: `Es sind keine Versicherungen konfiguriert.`,
		posNoProducts: `Es sind keine Versicherungsprodukte konfiguriert.`,
		autoLogout: `Session ist abgelaufen, bitte erneut anmelden.`,
		claimFile: `Schadenfall konnte nicht angelegt werden`,
		sendPartnerOnboarding: `E-Mail konnte nicht verschickt werden`,
		loadPartner: 'Kontakt konnte nicht geladen werden',
		general: 'Ein unbekannter Fehler ist aufgetreten',
		resetPw: 'Passwort konnte nicht gesetzt werden',
		resetPwRequest: 'Passwortanfrage fehlgeschlagen',
		resetPwLoad: 'Passwortanfrage konnte nicht geladen werden',
		login: 'Login fehlgeschlagen',
		title: 'Oops',
		posSave: 'Vertrag konnte nicht gesichert werden.',
		rm: 'Der Datensatz konnte nicht gelöscht werden',
		setPw: 'Account Passwort kann nicht gesetzt werden',
		imp: 'Konnte nicht Impersonieren',
		reload: 'Datensatz konnte nicht geladen werden',
		load: 'Datensatz konnte nicht geladen werden',
		cancel: 'Konnte nicht storniert werden',
		save: 'Konnte nicht gesichert werden',
		approve: 'Konnte Schadenfall nicht akzeptieren',
		reject: 'Konnte Schadenfall nicht ablehnen',
		genReport: 'Die Auswertung konnte nicht generiert werden',
		selectType: 'Bitte einen Auswertungstyp wählen',
		posPrice: 'Keine Tarife für diesen Sachwert',
		posLoad: 'POS konnte nicht geladen werden',
		duplicate: 'Datensatz konnte nicht dupliziert werden',
		loadList: 'Daten konnten nicht geladen werden',
		fileDownload: 'Die Datei konnte nicht gefunden werden.',
	},
	btn: {
		download: 'Download',
		resolved: 'Erledigt',
		funnelSave: 'Sichern',
		close: 'schliessen',
		confirmRm: 'Löschen fortsetzen',
		clearForm: 'zurücksetzen',
		admin: 'Admin',
		login: 'Login',
		resetPw: 'Passwort setzen',
		resetPwRequest: 'Passwort neu anfordern…',
		passwordReset: 'Passwort vergessen…',
		setPassword: 'Passwort ändern…',
		posFromExisting: 'Neuer Vertrag',
		reportClaim: 'Schaden melden',
		search: 'Suchen…',
		accept: 'Bestätigen',
		about: 'Über INZURO',
		back: 'Zurück',
		next: 'Weiter',
		confirm: 'Bestätigen',
		logout: 'Logout',
		system: 'System',
		settings: 'Einstellungen',
		save: 'Sichern',
		ok: 'Ok',
		cancel: 'Abbrechen',
		no: 'Nein',
		yes: 'Ja',
		rm: 'Löschen',
		contractNr: 'Vertragsnummer',
		downloadEvaluation: 'Auswertung herunterladen',
		cancelClaim: 'Schadenfall stornieren',
		reopen: 'Wiedereröffnen',
		deny: 'Ablehnen',
		approve: 'Akzeptieren',
	},
	profile: {
		title: 'Profil',
		titleEdit: 'Persönliche Daten bearbeiten',
	},
	claimDetail: {
		titleLocationAccident: 'Ort',
		titleDetails: 'Beschreibung',
		titleAccident: 'Details zum Vorfall',
		titleType: 'Was geschehen ist',
		tooltipApprove: 'Schadenfall akzeptieren',
		tooltipReopen: 'Schadenfall wiedereröffnen',
		tooltipDeny: 'Schadenfall ablehnen',
	},
	action: {
		reload: 'Erneut laden',
		generateCertificate: 'Zertifikat neu generieren',
		impersonate: 'Imitieren',
		rm: 'Datensatz löschen',
		setPassword: 'Benutzerpasswort setzen',
		cancel: 'Stornieren',
		sendConfirmation: 'Bestätigung senden',
		editRelations: 'Abhängigkeiten…',
	},
	communicationWay: {
		all: 'Alle',
		sms: 'SMS',
		mail: 'E-Mail',
	},
	tooltip: {
		leadsDashboard: 'Dashboard',
		leadsCalendar: 'Termine',
		leadsList: 'Liste',
		downloadEvent: 'Event downloaden',
		downloadCal: 'Kalender downloaden',
		genPw: 'Passwort generieren',
		reportClaim: 'Neuen Schaden anmelden',
		posFromExisting: 'Vertrag auf Basis von diesem anlegen',
		addEmployee: 'Mitarbeiter hinzufügen',
		addDirectEmployee: 'Mitarbeiter hinzufügen',
		addBranch: 'Zweigstelle hinzufügen',
		addQuestion: 'Frage hinzufügen',
		bookmark: 'Lesezeichen setzen',
		edit: 'bearbeiten',
		preview: 'Vorschau',
		actions: 'Aktionen',
		langSwitch: 'Übersetzungen',
		blockRelations: 'Abhängigkeiten',
		showAll: 'Zeige alle Einträge',
	},
	mod: {
		insurances: 'Versicherungen',
		contracts: 'Policen',
	},
	status: {
		open: 'Offen',
		pending: 'Pendent',
		denied: 'Abgelehnt',
		approved: 'Akzeptiert',
		cancelled: 'Abgebrochen',
	},
	insuranceDetail: {
		titleBlocks: 'Produkte',
		titleSettings: 'Einstellungen',
	},
	stakeholder: {
		platform: 'Platform',
		agent: 'Agent',
		partner: 'Partner',
		shop: 'Shop',
		employee: 'Verkäufer',
		schadenregulierer: 'Schadenregulierer',
	},
	th: {
		id: 'ID',
		turnover: 'Umsatz',
		funnelState: 'Status',
		dateLastAction: 'Letzte Aktion',
		city: 'Ort',
		zip: 'PLZ',
		exportInfo: 'Export',
		partner: 'Partner',
		numContracts: 'Verträge',
		errorMessage: 'Fehler',
		dateLatestLogin: 'Eingelogt seit',
		dateExported: 'Exportiert am',
		year: 'Jahr',
		warrantyProductId: 'Warranty Product ID',
		action: 'Aktion',
		remoteRecord: 'Betrifft',
		user: 'Benutzer',
		period: 'Laufzeit',
		numProducts: 'Produkte',
		priceOfContractConclusion: 'Preis',
		policeNr: 'Policen Nr.',
		type: 'Typ',
		fileTitle: 'Dateiname',
		fileType: 'Typ',
		insuranceAmount: 'Versicherungswert',
		tariffRange: 'Tarif bis',
		netPrice: 'Nettoprämie',
		grossPrice: 'Bruttoprämie',
		customerPrice: 'Selbstbehalt',
		question: 'Frage',
		questionType: 'Antwort',
		product: 'Produktbezeichnung',
		isRenewable: 'Erneuerbar',
		contactType: 'Kontaktart',
		name: 'Name',
		email: 'E-Mail Adresse',
		insurance: 'Versicherung',
		insurer: 'Versicherer',
		duration: 'Laufzeit',
		productLabel: 'Produktbezeichnung',
		productType: 'Produktart',
		insuranceName: 'Name der Versicherung',
		description: 'Beschreibung',
		customer: 'Kunde',
		claimStatus: 'Status',
		dateOfIncident: 'Vorfall',
		dateCreated: 'Erstellt',
		insuredObject: 'Versichertes Objekt',
		contractType: 'Versicherungsart',
	},
	locationFinder: {
		title: 'Adresse finden',
		placeholderSearch: 'Suche nach Firmennamen, Strasse, Ort,…',
	},
	placeholder: {
		searchExport: 'ID, Case ID, Name,…',
		timeOrString: 'z.b. 13:00 oder am morgen',
		day: 'Tag',
		search: 'Suche…',
		searchContacts: 'ID, Name, Adresse, E-Mail, Tel…',
		searchContracts: 'ID, Policen Nr., Kundenname,…',
		searchClaims: 'ID, Policen Nr., Kundenname,…',
		searchInsurances: 'ID, Name, Beschreibung…',
		searchBlocks: 'ID, Name, Beschreibung…',
		funnelContactNotes: 'Gesprächsnotizen',
		searchLeads: 'ID, Kundenname, PLZ, Ort…',
	},
	opts: {
		selectContactType: 'Alle Kontaktarten',
		selectClaimState: 'Status',
		stateProspect: 'Potentielle Kunden',
		stateOut: 'Kein Interesse',
		stateCustomer: 'Kunden',
		all: 'Alle',
	},
	answerType: {
		text: 'Text',
		bool: 'Ja oder Nein',
		range: 'Spanne',
	},
	description: {
		mobile: 'Nur CH und LI Nummern (+4179xxxxxxx)',
	},
	select: {
		month: 'Monat',
		year: 'Jahr',
	},
	period: {
		month: 'Monat',
		year: 'Jahr',
		quarter: 'Quartal',
	},
	months: {
		jan: 'Jan',
		feb: 'Feb',
		mar: 'Mar',
		apr: 'Apr',
		mai: 'Mai',
		jun: 'Jun',
		jul: 'Jul',
		aug: 'Aug',
		sep: 'Sep',
		oct: 'Okt',
		nov: 'Nov',
		dec: 'Dez',
	},
	about: `Ein Produkt der <b>NxtLvl Development GmbH</b`,
	daterange: {
		day: 'Tag',
		week: 'Woche',
		month: 'Monat',
		quarter: 'Quartal',
		year: 'Jahr',
	},
	panel: {
		exportMailTest: 'Export Notification E-Mail Test',
		s2rExportStats: 'S2R Exportierte Daten',
		exportlog: 'Daten Export',
		summary: 'Übersicht',
		insuranceTypes: 'Versicherungsarten',
		onlineUsers: 'Angemeldete Benutzer',
	},
}