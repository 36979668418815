;(function(){
'use strict';

Object.defineProperty(exports, "__esModule", {
  value: true
});

var _baseListsmall = require('../../../../base/baseListsmall');

var _baseListsmall2 = _interopRequireDefault(_baseListsmall);

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

exports.default = {
  name: 'inz-admin-insurance-types-list',
  extends: _baseListsmall2.default,
  methods: {
    reload: function reload() {
      this.$emit('reload');
    },
    edit: function edit(it) {
      this.$emit('edit', it);
    },
    add: function add(it) {
      this.$emit('add');
    },
    rm: function rm(id) {
      this.$emit('rm', id);
    }
  },
  computed: {
    fields: function fields() {
      return [{ key: 'name', sortable: true, label: 'th.name' }, { key: 'rm', sortable: true, class: 'i-cell-icon' }];
    }
  }
};
})()
if (module.exports.__esModule) module.exports = module.exports.default
var __vue__options__ = (typeof module.exports === "function"? module.exports.options: module.exports)
if (__vue__options__.functional) {console.error("[vueify] functional components are not supported and should be defined in plain js files using render functions.")}
__vue__options__.render = function render () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return (_vm.filtered.length)?_c('div',{staticClass:"i-listsmall"},[_c('b-table',{staticClass:"inz-table-list",attrs:{"responsive":"","hover":"","items":_vm.filtered,"fields":_vm.fieldsTr},scopedSlots:_vm._u([{key:"cell(name)",fn:function(data){return [_c('div',{staticClass:"clickable",domProps:{"innerHTML":_vm._s(data.value)},on:{"click":function($event){return _vm.edit(data.item)}}})]}},{key:"cell(rm)",fn:function(data){return [_c('inz-btn',{attrs:{"size":"sm","icon":"remove"},on:{"click":function($event){return _vm.rm(data.item.id)}}})]}}],null,false,364046773)})],1):_vm._e()}
__vue__options__.staticRenderFns = []
if (module.hot) {(function () {  var hotAPI = require("vue-hot-reload-api")
  hotAPI.install(require("vue"), true)
  if (!hotAPI.compatible) return
  module.hot.accept()
  if (!module.hot.data) {
    hotAPI.createRecord("data-v-2e0fe999", __vue__options__)
  } else {
    hotAPI.reload("data-v-2e0fe999", __vue__options__)
  }
})()}