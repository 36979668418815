;(function(){
'use strict';

Object.defineProperty(exports, "__esModule", {
  value: true
});

var _xhr = require('../../../service/xhr');

var _header = require('../../../components/panels/header');

var _header2 = _interopRequireDefault(_header);

var _utils = require('../../../service/utils');

var _utils2 = _interopRequireDefault(_utils);

var _bus = require('../../../service/bus');

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

exports.default = {
  name: 'inz-panel-admin-summary',
  components: { InzPanelHeader: _header2.default },
  mounted: function mounted() {
    this.load();
  },
  created: function created() {
    var _this = this;

    _bus.bus.$on('adminSummaryReload', function (data) {
      return _this.load();
    });
  },
  data: function data() {
    return {
      summary: null
    };
  },

  methods: {
    load: function load() {
      var _this2 = this;

      _xhr.admin.summary().then(function (r) {
        return _this2.$set(_this2, 'summary', r.data);
      }).catch(function (e) {
        return _utils2.default.handleXhrError(e);
      });
    }
  },
  computed: {
    infos: function infos() {
      if (!this.summary) {
        return null;
      }
      return [{ value: this.summary.env, label: 'Backend' }, { value: this.summary.server, label: 'Server' }, { value: this.summary.contracts, label: 'Aktive Policen', type: 'number' }, { value: this.summary.exportedItems, label: 'Exportierte Objekte', type: 'number' }, { value: this.summary.failedExportItems, label: 'S2R Export Fehler', type: 'number' }, { value: this.summary.spooler, label: 'Spooler Einträge', type: 'number' }];
    }
  }
};
})()
if (module.exports.__esModule) module.exports = module.exports.default
var __vue__options__ = (typeof module.exports === "function"? module.exports.options: module.exports)
if (__vue__options__.functional) {console.error("[vueify] functional components are not supported and should be defined in plain js files using render functions.")}
__vue__options__.render = function render () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return (_vm.summary)?_c('div',{staticClass:"inz-panel"},[_c('b-card',{staticClass:"inz-panel inz-admin-summary mb-1"},[_c('inz-panel-header',{attrs:{"slot":"header","title-label":"summary"},slot:"header"}),_vm._v(" "),_c('inz-infolist',{staticClass:"ph-1 mb-1",attrs:{"infos":_vm.infos}})],1)],1):_vm._e()}
__vue__options__.staticRenderFns = []
if (module.hot) {(function () {  var hotAPI = require("vue-hot-reload-api")
  hotAPI.install(require("vue"), true)
  if (!hotAPI.compatible) return
  module.hot.accept()
  if (!module.hot.data) {
    hotAPI.createRecord("data-v-ffeed552", __vue__options__)
  } else {
    hotAPI.reload("data-v-ffeed552", __vue__options__)
  }
})()}