;(function(){
'use strict';

Object.defineProperty(exports, "__esModule", {
  value: true
});

var _utils = require('../service/utils');

var _utils2 = _interopRequireDefault(_utils);

var _account = require('../service/account');

var _security = require('../service/security');

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

exports.default = {
  name: 'inz-account',
  computed: {
    displayName: function displayName() {
      var u = _utils2.default.u();
      if (u) {
        return u.displayName;
      }
      return '';
    },
    user: function user() {
      return _utils2.default.u();
    },
    canSystem: function canSystem() {
      return _security.security.isDev();
    }
  },
  methods: {
    logout: function logout() {
      _account.accountService.logout();
    },
    profile: function profile() {
      this.$router.push({ name: 'profile' });
    },
    admin: function admin() {
      this.$router.push({ name: 'admin' });
    }
  }
};
})()
if (module.exports.__esModule) module.exports = module.exports.default
var __vue__options__ = (typeof module.exports === "function"? module.exports.options: module.exports)
if (__vue__options__.functional) {console.error("[vueify] functional components are not supported and should be defined in plain js files using render functions.")}
__vue__options__.render = function render () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('b-navbar-nav',{staticClass:"inz-account"},[_c('b-nav-item-dropdown',{staticClass:"inz-nav-item",attrs:{"no-caret":"","dropup":""},scopedSlots:_vm._u([{key:"button-content",fn:function(){return [_c('span',{staticClass:"inz-nav-icon"},[_c('i',{staticClass:"fa fa-fw fa-user"})]),_vm._v(" "),_c('span',{staticClass:"inz-nav-label",domProps:{"innerHTML":_vm._s(_vm.displayName)}})]},proxy:true}])},[_vm._v(" "),_c('b-dropdown-item',{attrs:{"href":"#"},on:{"click":_vm.profile}},[_c('fa',{attrs:{"icon":"cog"}}),_vm._v("\n      "+_vm._s(_vm.$t('btn.settings'))+"\n    ")],1),_vm._v(" "),_c('b-dropdown-item',{directives:[{name:"b-modal",rawName:"v-b-modal.modalAbout",modifiers:{"modalAbout":true}}],attrs:{"href":"#"}},[_c('fa',{attrs:{"icon":"circle"}}),_vm._v("\n      "+_vm._s(_vm.$t('btn.about'))+"\n    ")],1),_vm._v(" "),_c('b-dropdown-item',{attrs:{"href":"#"},on:{"click":_vm.logout}},[_c('fa',{attrs:{"icon":"sign-out"}}),_vm._v("\n      "+_vm._s(_vm.$t('btn.logout'))+"\n    ")],1)],1)],1)}
__vue__options__.staticRenderFns = []
if (module.hot) {(function () {  var hotAPI = require("vue-hot-reload-api")
  hotAPI.install(require("vue"), true)
  if (!hotAPI.compatible) return
  module.hot.accept()
  if (!module.hot.data) {
    hotAPI.createRecord("data-v-5e7f18a7", __vue__options__)
  } else {
    hotAPI.reload("data-v-5e7f18a7", __vue__options__)
  }
})()}