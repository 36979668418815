;(function(){
'use strict';

Object.defineProperty(exports, "__esModule", {
  value: true
});

var _baseModal = require('../../../../base/baseModal');

var _baseModal2 = _interopRequireDefault(_baseModal);

var _xhr = require('../../../../service/xhr');

var _utils = require('../../../../service/utils');

var _utils2 = _interopRequireDefault(_utils);

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

exports.default = {
  name: 'd-insurance-type-remove',
  extends: _baseModal2.default,
  computed: {
    infos: function infos() {
      return [{ label: 'Betroffene Produkte', value: this.num }];
    },
    canContinue: function canContinue() {
      return this.num === 0 || this.target !== null;
    }
  },
  methods: {
    open: function open(id) {
      this.$set(this, 'target', null);
      this.$set(this, 'opts', null);
      this.$set(this, 'id', id);
      this.$set(this, 'isVisible', true);
      this.load();
      this.affectedProducts();
    },
    load: function load() {
      var _this = this;

      _xhr.admin.insuranceTypes().then(function (res) {
        var opts = res.data.map(function (it) {
          return { id: it.id, label: it.name };
        });
        opts = opts.filter(function (it) {
          return it.id !== _this.id;
        });
        _this.$set(_this, 'opts', opts);
      }).catch(function (e) {
        return _utils2.default.handleXhrError(e, 'load');
      });
    },
    accept: function accept() {
      var _this2 = this;

      this.close();
      _xhr.admin.insuranceTypeDelete(this.id, this.target).then(function () {
        _this2.$emit('reload');
        _utils2.default.toast('Versicherungsart gelöscht', true);
      }).catch(function (e) {
        return _utils2.default.handleXhrError(e, 'delete');
      });
    },
    affectedProducts: function affectedProducts() {
      var _this3 = this;

      _xhr.admin.insuranceTypeUpdateNumLinkedProducts(this.id).then(function (r) {
        return _this3.$set(_this3, 'num', r.data.num);
      }).catch(function (e) {
        return _utils2.default.handleXhrError(e);
      });
    }
  },
  data: function data() {
    return {
      id: null,
      num: null,
      target: null,
      opts: null
    };
  }
};
})()
if (module.exports.__esModule) module.exports = module.exports.default
var __vue__options__ = (typeof module.exports === "function"? module.exports.options: module.exports)
if (__vue__options__.functional) {console.error("[vueify] functional components are not supported and should be defined in plain js files using render functions.")}
__vue__options__.render = function render () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('b-modal',{attrs:{"title":"Versicherungsart wirklich löschen?","button-size":"sm"},scopedSlots:_vm._u([{key:"modal-footer",fn:function(){return [_c('inz-btn',{attrs:{"size":"sm","variant":"default","label":_vm.$t('btn.cancel')},on:{"click":_vm.close}}),_vm._v(" "),_c('inz-btn',{attrs:{"size":"sm","variant":"primary","label":_vm.$t('btn.confirmRm'),"icon":"check","disabled":!_vm.canContinue},on:{"click":_vm.accept}})]},proxy:true}]),model:{value:(_vm.isVisible),callback:function ($$v) {_vm.isVisible=$$v},expression:"isVisible"}},[(_vm.opts)?_c('div',[(_vm.num)?[_c('p',[_vm._v("Mit dieser Versicherungsart markierte Produkte müssen einer anderen Versicherungsart zugewiesen werden.")]),_vm._v(" "),_c('inz-infolist',{staticClass:"mb-2 mt-1",attrs:{"infos":_vm.infos}}),_vm._v(" "),_c('inz-control-select',{attrs:{"label":"Wähle eine alternative Versicherungsart","opts":_vm.opts},model:{value:(_vm.target),callback:function ($$v) {_vm.target=$$v},expression:"target"}})]:[_c('p',[_vm._v("Es sind keine Produkte mit dieser Versicherungsart markiert, sie kann gelöscht werden.")])]],2):_vm._e()])}
__vue__options__.staticRenderFns = []
if (module.hot) {(function () {  var hotAPI = require("vue-hot-reload-api")
  hotAPI.install(require("vue"), true)
  if (!hotAPI.compatible) return
  module.hot.accept()
  if (!module.hot.data) {
    hotAPI.createRecord("data-v-74670af8", __vue__options__)
  } else {
    hotAPI.reload("data-v-74670af8", __vue__options__)
  }
})()}