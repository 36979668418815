;(function(){
'use strict';

Object.defineProperty(exports, "__esModule", {
  value: true
});

var _abstractPanel = require('./abstractPanel');

var _abstractPanel2 = _interopRequireDefault(_abstractPanel);

var _xhr = require('../../service/xhr');

var _header = require('./header');

var _header2 = _interopRequireDefault(_header);

var _daterangebtns = require('../control/daterangebtns');

var _daterangebtns2 = _interopRequireDefault(_daterangebtns);

var _performanceYear = require('./charts/performanceYear');

var _performanceYear2 = _interopRequireDefault(_performanceYear);

var _performanceMonth = require('./charts/performanceMonth');

var _performanceMonth2 = _interopRequireDefault(_performanceMonth);

var _performanceWeek = require('./charts/performanceWeek');

var _performanceWeek2 = _interopRequireDefault(_performanceWeek);

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

exports.default = {
  name: 'inz-panel-performance',
  components: {
    InzPanelPerformanceChartWeek: _performanceWeek2.default,
    InzPanelPerformanceChartMonth: _performanceMonth2.default, InzPanelPerformanceChartYear: _performanceYear2.default, InzControlDaterangebtns: _daterangebtns2.default, InzPanelHeader: _header2.default
  },
  extends: _abstractPanel2.default,
  computed: {
    filterName: function filterName() {
      return 'performance';
    },
    chartType: function chartType() {
      return this.filter.type;
    },
    apiUrl: function apiUrl() {
      return _xhr.panel.performance;
    }
  }
};
})()
if (module.exports.__esModule) module.exports = module.exports.default
var __vue__options__ = (typeof module.exports === "function"? module.exports.options: module.exports)
if (__vue__options__.functional) {console.error("[vueify] functional components are not supported and should be defined in plain js files using render functions.")}
__vue__options__.render = function render () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"inz-panel inz-panel-performance"},[_c('b-card',[_c('inz-panel-header',{attrs:{"slot":"header","title-label":"performance"},slot:"header"},[_c('inz-control-daterangebtns',{model:{value:(_vm.filter),callback:function ($$v) {_vm.filter=$$v},expression:"filter"}})],1),_vm._v(" "),(_vm.panelData)?_c('div',{staticClass:"inz-panel-performance--legend"},_vm._l((_vm.panelData.performance),function(it,$i){return _c('div',{key:$i,staticClass:"inz-panel-performance-item"},[_c('h3',{staticClass:"inz-panel-performance-item--title",domProps:{"innerHTML":_vm._s(_vm.$t(it.slug))}}),_vm._v(" "),_c('div',{staticClass:"d-flex w-100"},[(it.type==='currency')?_c('div',{staticClass:"inz-panel-performance-item--amount"},[_vm._v(_vm._s(_vm._f("iPrice")(it.value)))]):(it.type==='number')?_c('div',{staticClass:"inz-panel-performance-item--amount"},[_vm._v(_vm._s(_vm._f("iNumber")(it.value))+"\n          ")]):_c('div',{staticClass:"inz-panel-performance-item--amount",domProps:{"innerHTML":_vm._s(it.value)}}),_vm._v(" "),(it.change)?_c('div',{staticClass:"inz-panel-performance-item--change",class:("i-t-" + (it.variant)),domProps:{"innerHTML":_vm._s(it.change)}}):_vm._e()])])}),0):_vm._e(),_vm._v(" "),(_vm.panelData && _vm.panelData.chart)?_c('div',{staticClass:"inz-panel-performance--chart mv-1"},[(_vm.chartType==='month')?_c('inz-panel-performance-chart-month',{attrs:{"panel-data":_vm.panelData,"filter":_vm.filter}}):_vm._e(),_vm._v(" "),(_vm.chartType==='year')?_c('inz-panel-performance-chart-year',{attrs:{"panel-data":_vm.panelData,"filter":_vm.filter}}):_vm._e(),_vm._v(" "),(_vm.chartType==='week')?_c('inz-panel-performance-chart-week',{attrs:{"panel-data":_vm.panelData,"filter":_vm.filter}}):_vm._e()],1):_vm._e()],1)],1)}
__vue__options__.staticRenderFns = []
if (module.hot) {(function () {  var hotAPI = require("vue-hot-reload-api")
  hotAPI.install(require("vue"), true)
  if (!hotAPI.compatible) return
  module.hot.accept()
  if (!module.hot.data) {
    hotAPI.createRecord("data-v-47731fc6", __vue__options__)
  } else {
    hotAPI.reload("data-v-47731fc6", __vue__options__)
  }
})()}