;(function(){
'use strict';

Object.defineProperty(exports, "__esModule", {
  value: true
});

var _baseForm = require('../../../../../../base/baseForm');

var _baseForm2 = _interopRequireDefault(_baseForm);

var _helper = require('../../contract/helper');

var _helper2 = _interopRequireDefault(_helper);

var _opts = require('../../../../../../config/opts');

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

exports.default = {
  name: 'inz-contact-form-settings',
  extends: _baseForm2.default,
  computed: {
    isPartner: function isPartner() {
      return _helper2.default.isPartner(this.form.contactType);
    },
    roleWithAdminOption: function roleWithAdminOption() {
      return _helper2.default.roleWithAdminOption(this.form.contactType);
    },
    optsLanguages: function optsLanguages() {
      return _opts.opts.languages.map(function (it) {
        return { id: it.code, label: it.label };
      });
    },
    optsCommunicationWay: function optsCommunicationWay() {
      return _opts.opts.communicationWay.map(function (it) {
        return { id: it.slug, label: 'communicationWay.' + it.slug };
      });
    }
  }
};
})()
if (module.exports.__esModule) module.exports = module.exports.default
var __vue__options__ = (typeof module.exports === "function"? module.exports.options: module.exports)
if (__vue__options__.functional) {console.error("[vueify] functional components are not supported and should be defined in plain js files using render functions.")}
__vue__options__.render = function render () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return (_vm.form)?_c('b-form-row',{staticClass:"inz-contact-form-settings"},[_c('b-col',{attrs:{"md":"6","sm":"12"}},[_c('inz-control-select',{attrs:{"labelt":"language","opts":_vm.optsLanguages,"name":"language"},model:{value:(_vm.form.language),callback:function ($$v) {_vm.$set(_vm.form, "language", $$v)},expression:"form.language"}})],1),_vm._v(" "),_c('b-col',{attrs:{"md":"6","sm":"12"}},[_c('inz-control-select',{attrs:{"labelt":"communicationWay","opts":_vm.optsCommunicationWay,"name":"communicationWay"},model:{value:(_vm.form.communicationWay),callback:function ($$v) {_vm.$set(_vm.form, "communicationWay", $$v)},expression:"form.communicationWay"}})],1),_vm._v(" "),_c('b-col',{attrs:{"md":"6","sm":"12"}},[_c('inz-control-input',{attrs:{"labelt":"contactNumber","name":"contactNumber"},model:{value:(_vm.form.contactNumber),callback:function ($$v) {_vm.$set(_vm.form, "contactNumber", $$v)},expression:"form.contactNumber"}})],1)],1):_vm._e()}
__vue__options__.staticRenderFns = []
if (module.hot) {(function () {  var hotAPI = require("vue-hot-reload-api")
  hotAPI.install(require("vue"), true)
  if (!hotAPI.compatible) return
  module.hot.accept()
  if (!module.hot.data) {
    hotAPI.createRecord("data-v-7101585a", __vue__options__)
  } else {
    hotAPI.reload("data-v-7101585a", __vue__options__)
  }
})()}