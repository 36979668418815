;(function(){
'use strict';

Object.defineProperty(exports, "__esModule", {
  value: true
});

var _line = require('../modules/admin/export/entity/contact/line');

var _line2 = _interopRequireDefault(_line);

var _line3 = require('../modules/admin/export/entity/contract/line');

var _line4 = _interopRequireDefault(_line3);

var _circle = require('./circle');

var _circle2 = _interopRequireDefault(_circle);

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

exports.default = {
  name: 'inz-cube-info',
  components: { SvgCircle: _circle2.default, InzContractLine: _line4.default, InzContactLine: _line2.default },
  props: {
    infos: Array,
    size: { default: 'md' }
  }
};
})()
if (module.exports.__esModule) module.exports = module.exports.default
var __vue__options__ = (typeof module.exports === "function"? module.exports.options: module.exports)
if (__vue__options__.functional) {console.error("[vueify] functional components are not supported and should be defined in plain js files using render functions.")}
__vue__options__.render = function render () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"inz-cube-info",class:'inz-cube-info--'+_vm.size},_vm._l((_vm.infos),function(it,$i){return _c('div',{key:$i,staticClass:"inz-cube-info-item"},[(it.label)?_c('div',{staticClass:"label",domProps:{"innerHTML":_vm._s(_vm.$t(it.label))}}):_c('div',{staticClass:"label",domProps:{"innerHTML":_vm._s(it.labelText)}}),_vm._v(" "),(it.type==='price')?_c('div',{staticClass:"value"},[_vm._v(_vm._s(_vm._f("iPrice")(it.value)))]):(it.type==='date')?_c('div',{staticClass:"value"},[_vm._v(_vm._s(_vm._f("iDate")(it.value)))]):(it.type==='datetime')?_c('div',{staticClass:"value"},[_vm._v(_vm._s(_vm._f("iDateTime")(it.value)))]):(it.type==='status')?_c('div',{staticClass:"value"},[_c('svg-circle',{attrs:{"status":it.value}}),_vm._v("\n      "+_vm._s(it.info)+"\n    ")],1):(it.type==='contact')?_c('div',{staticClass:"value"},[_c('inz-contact-line',{attrs:{"rec":it.value}})],1):(it.type==='contract')?_c('div',{staticClass:"value"},[_c('inz-contract-line',{attrs:{"rec":it.value}})],1):_c('div',{staticClass:"value",domProps:{"innerHTML":_vm._s(it.value)}})])}),0)}
__vue__options__.staticRenderFns = []
if (module.hot) {(function () {  var hotAPI = require("vue-hot-reload-api")
  hotAPI.install(require("vue"), true)
  if (!hotAPI.compatible) return
  module.hot.accept()
  if (!module.hot.data) {
    hotAPI.createRecord("data-v-3e433b60", __vue__options__)
  } else {
    hotAPI.reload("data-v-3e433b60", __vue__options__)
  }
})()}