;(function(){
'use strict';

Object.defineProperty(exports, "__esModule", {
  value: true
});

var _baseControl = require('../../base/baseControl');

var _baseControl2 = _interopRequireDefault(_baseControl);

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

exports.default = {
  name: 'inz-control-setting',
  extends: _baseControl2.default,
  props: {
    size: { default: null },
    defValue: { default: true }
  },
  methods: {
    toggle: function toggle() {
      this.$emit('input', !this.val);
    }
  }
};
})()
if (module.exports.__esModule) module.exports = module.exports.default
var __vue__options__ = (typeof module.exports === "function"? module.exports.options: module.exports)
if (__vue__options__.functional) {console.error("[vueify] functional components are not supported and should be defined in plain js files using render functions.")}
__vue__options__.render = function render () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"inz-control inz-control-setting"},[_c('div',{staticClass:"label",domProps:{"innerHTML":_vm._s(_vm._label)},on:{"click":_vm.toggle}}),_vm._v(" "),_c('div',{staticClass:"value"},[_vm._v(_vm._s(_vm.$t(_vm.val === true ? 'yes' : 'no')))]),_vm._v(" "),_c('div',{staticClass:"toggle"},[_c('b-form-checkbox',{attrs:{"value":_vm.defValue,"name":_vm.name,"switch":"","disabled":_vm.disabled,"size":_vm.size},model:{value:(_vm.val),callback:function ($$v) {_vm.val=$$v},expression:"val"}})],1)])}
__vue__options__.staticRenderFns = []
if (module.hot) {(function () {  var hotAPI = require("vue-hot-reload-api")
  hotAPI.install(require("vue"), true)
  if (!hotAPI.compatible) return
  module.hot.accept()
  if (!module.hot.data) {
    hotAPI.createRecord("data-v-3374fa3e", __vue__options__)
  } else {
    hotAPI.reload("data-v-3374fa3e", __vue__options__)
  }
})()}