;(function(){
'use strict';

Object.defineProperty(exports, "__esModule", {
  value: true
});

var _baseForm = require('../../../../../../base/baseForm');

var _baseForm2 = _interopRequireDefault(_baseForm);

var _country = require('../../../../../../components/control/country');

var _country2 = _interopRequireDefault(_country);

var _finder = require('../../../../../../components/location/finder');

var _finder2 = _interopRequireDefault(_finder);

var _inputAppend = require('../../../../../../components/inputAppend');

var _inputAppend2 = _interopRequireDefault(_inputAppend);

var _inputPrepend = require('../../../../../../components/inputPrepend');

var _inputPrepend2 = _interopRequireDefault(_inputPrepend);

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

exports.default = {
  name: 'inz-contract-form-object',
  components: { InzInputPrepend: _inputPrepend2.default, InzInputAppend: _inputAppend2.default, InzLocationSelector: _finder2.default, InzControlCountry: _country2.default },
  extends: _baseForm2.default
};
})()
if (module.exports.__esModule) module.exports = module.exports.default
var __vue__options__ = (typeof module.exports === "function"? module.exports.options: module.exports)
if (__vue__options__.functional) {console.error("[vueify] functional components are not supported and should be defined in plain js files using render functions.")}
__vue__options__.render = function render () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return (_vm.form)?_c('b-form-row',{staticClass:"inz-contract-form-object"},[_c('b-col',{attrs:{"md":"12"}},[_c('inz-control-datepick',{attrs:{"labelt":"dateOfPurchase","name":"dateOfPurchase"},model:{value:(_vm.form.insuranceObject.dateOfPurchase),callback:function ($$v) {_vm.$set(_vm.form.insuranceObject, "dateOfPurchase", $$v)},expression:"form.insuranceObject.dateOfPurchase"}})],1),_vm._v(" "),_c('b-col',{attrs:{"md":"6","sm":"12"}},[_c('inz-control-input',{attrs:{"labelt":"price","name":"price"},model:{value:(_vm.form.insuranceObject.price),callback:function ($$v) {_vm.$set(_vm.form.insuranceObject, "price", $$v)},expression:"form.insuranceObject.price"}},[_c('template',{slot:"prepend"},[_c('inz-input-prepend',{attrs:{"label":"CHF"}})],1)],2)],1),_vm._v(" "),_c('b-col',{attrs:{"md":"6","sm":"12"}},[_c('inz-control-input',{attrs:{"labelt":"manufacturer","name":"lastname"},model:{value:(_vm.form.insuranceObject.manufacturer),callback:function ($$v) {_vm.$set(_vm.form.insuranceObject, "manufacturer", $$v)},expression:"form.insuranceObject.manufacturer"}})],1),_vm._v(" "),_c('b-col',{attrs:{"md":"6","sm":"12"}},[_c('inz-control-input',{attrs:{"labelt":"model","name":"model"},model:{value:(_vm.form.insuranceObject.model),callback:function ($$v) {_vm.$set(_vm.form.insuranceObject, "model", $$v)},expression:"form.insuranceObject.model"}})],1),_vm._v(" "),_vm._l((_vm.form.responses),function(it,$i){return _c('b-col',{key:$i,attrs:{"md":"6","sm":"12"}},[_c('inz-control-input',{attrs:{"labelt":$i,"name":$i},model:{value:(_vm.form.responses[$i]),callback:function ($$v) {_vm.$set(_vm.form.responses, $i, $$v)},expression:"form.responses[$i]"}})],1)})],2):_vm._e()}
__vue__options__.staticRenderFns = []
if (module.hot) {(function () {  var hotAPI = require("vue-hot-reload-api")
  hotAPI.install(require("vue"), true)
  if (!hotAPI.compatible) return
  module.hot.accept()
  if (!module.hot.data) {
    hotAPI.createRecord("data-v-68591142", __vue__options__)
  } else {
    hotAPI.reload("data-v-68591142", __vue__options__)
  }
})()}