;(function(){
'use strict';

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = {
  name: 'inz-exportlog-detail',
  props: {
    rec: Object
  },
  computed: {
    remoteObject: function remoteObject() {
      var rec = this.rec;
      if (!rec) return '';
      return { remoteId: rec.remoteId, type: this.remoteObjectType, name: '#' + rec.remoteId, id: rec.id };
    },
    remoteObjectType: function remoteObjectType() {
      switch (this.rec.type) {
        case 'contractExport':
        case 'contractCancel':
          return 'contract';
        case 'claim':
          return 'claim';
        case 'partner':
          return 'contact';
      }
    },
    baseInfo: function baseInfo() {
      var item = this.rec;
      if (!item) {
        return null;
      }
      var res = [];
      res.push({ label: 'Report ID', value: '#' + item.id });
      res.push({ label: 'Objekt ID', value: '#' + item.remoteId });
      res.push({ label: 'Objekt Typ', value: this.$i18n.t('exportType.' + item.type) });
      res.push({ label: 'Export Datum', value: item.dateCreated, type: 'dateTime' });
      res.push({ label: 'Fehler', value: item.errorMessage, bold: true });
      res.push({ label: 'Exportiertes Objekt', value: item.originalEntity, type: 'json' });
      res.push({ label: 'Antwort', value: item.responseData, type: 'json' });
      return res;
    }
  }
};
})()
if (module.exports.__esModule) module.exports = module.exports.default
var __vue__options__ = (typeof module.exports === "function"? module.exports.options: module.exports)
if (__vue__options__.functional) {console.error("[vueify] functional components are not supported and should be defined in plain js files using render functions.")}
__vue__options__.render = function render () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return (_vm.rec)?_c('inz-infolist',{attrs:{"infos":_vm.baseInfo}}):_vm._e()}
__vue__options__.staticRenderFns = []
if (module.hot) {(function () {  var hotAPI = require("vue-hot-reload-api")
  hotAPI.install(require("vue"), true)
  if (!hotAPI.compatible) return
  module.hot.accept()
  if (!module.hot.data) {
    hotAPI.createRecord("data-v-6a170290", __vue__options__)
  } else {
    hotAPI.reload("data-v-6a170290", __vue__options__)
  }
})()}