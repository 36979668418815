;(function(){
'use strict';

Object.defineProperty(exports, "__esModule", {
  value: true
});

var _baseControl = require('../../base/baseControl');

var _baseControl2 = _interopRequireDefault(_baseControl);

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

exports.default = {
  name: 'inz-control-input',
  extends: _baseControl2.default,
  props: {
    type: { default: 'text' },
    min: Number,
    max: Number
  },
  methods: {
    click: function click() {
      this.$emit('click');
    }
  }
};
})()
if (module.exports.__esModule) module.exports = module.exports.default
var __vue__options__ = (typeof module.exports === "function"? module.exports.options: module.exports)
if (__vue__options__.functional) {console.error("[vueify] functional components are not supported and should be defined in plain js files using render functions.")}
__vue__options__.render = function render () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('b-form-group',{staticClass:"inz-control",class:{'multilang':_vm.multilang},attrs:{"label":_vm.labelS,"valid-feedback":_vm.validFeedback,"invalid-feedback":_vm.invalidFeedback,"description":_vm.desc,"state":_vm.valid}},[_c('div',{staticClass:"input-group"},[_vm._t("prepend"),_vm._v(" "),(!_vm.multilang)?_c('b-form-input',{ref:"inp",attrs:{"name":_vm.name,"required":_vm.required,"max":_vm.max,"min":_vm.min,"placeholder":_vm.placeh,"disabled":_vm.disabled,"state":_vm.valid,"type":_vm.type,"tabindex":_vm.tabindex},on:{"blur":_vm.blur,"click":_vm.click},model:{value:(_vm.val),callback:function ($$v) {_vm.val=$$v},expression:"val"}}):_c('b-form-input',{ref:"inp",attrs:{"name":_vm.name,"required":_vm.required,"max":_vm.max,"min":_vm.min,"placeholder":_vm.placeh,"tabindex":_vm.tabindex,"disabled":_vm.disabled,"state":_vm.valid,"type":_vm.type},on:{"blur":_vm.blur,"click":_vm.click},model:{value:(_vm.val[_vm.currentLang]),callback:function ($$v) {_vm.$set(_vm.val, _vm.currentLang, $$v)},expression:"val[currentLang]"}}),_vm._v(" "),_vm._t("append")],2),_vm._v(" "),_c('div',{staticClass:"error-msg"},[_vm._v("\n    "+_vm._s(_vm.validatorError)+"\n    "),_vm._t("error")],2)])}
__vue__options__.staticRenderFns = []
if (module.hot) {(function () {  var hotAPI = require("vue-hot-reload-api")
  hotAPI.install(require("vue"), true)
  if (!hotAPI.compatible) return
  module.hot.accept()
  if (!module.hot.data) {
    hotAPI.createRecord("data-v-dc3eacca", __vue__options__)
  } else {
    hotAPI.reload("data-v-dc3eacca", __vue__options__)
  }
})()}