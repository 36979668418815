;(function(){
'use strict';

Object.defineProperty(exports, "__esModule", {
  value: true
});

var _baseControl = require('../../base/baseControl');

var _baseControl2 = _interopRequireDefault(_baseControl);

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

exports.default = {
  name: 'inz-control-md',
  extends: _baseControl2.default,

  data: function data() {
    return {
      id: null,
      inst: null
    };
  }
};
})()
if (module.exports.__esModule) module.exports = module.exports.default
var __vue__options__ = (typeof module.exports === "function"? module.exports.options: module.exports)
if (__vue__options__.functional) {console.error("[vueify] functional components are not supported and should be defined in plain js files using render functions.")}
__vue__options__.render = function render () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('b-form-group',{staticClass:"inz-control",class:{'multilang':_vm.multilang},attrs:{"label":_vm.labelS,"valid-feedback":_vm.validFeedback,"invalid-feedback":_vm.invalidFeedback,"state":_vm.state}},[(!_vm.multilang)?_c('textarea',{directives:[{name:"model",rawName:"v-model",value:(_vm.val),expression:"val"}],staticClass:"form-control",attrs:{"name":_vm.name,"rows":"10"},domProps:{"value":(_vm.val)},on:{"input":function($event){if($event.target.composing){ return; }_vm.val=$event.target.value}}}):_c('textarea',{directives:[{name:"model",rawName:"v-model",value:(_vm.val[_vm.currentLang]),expression:"val[currentLang]"}],staticClass:"form-control",attrs:{"name":_vm.name,"rows":"10"},domProps:{"value":(_vm.val[_vm.currentLang])},on:{"input":function($event){if($event.target.composing){ return; }_vm.$set(_vm.val, _vm.currentLang, $event.target.value)}}})])}
__vue__options__.staticRenderFns = []
if (module.hot) {(function () {  var hotAPI = require("vue-hot-reload-api")
  hotAPI.install(require("vue"), true)
  if (!hotAPI.compatible) return
  module.hot.accept()
  if (!module.hot.data) {
    hotAPI.createRecord("data-v-14cac9d4", __vue__options__)
  } else {
    hotAPI.reload("data-v-14cac9d4", __vue__options__)
  }
})()}