export const AdminNav = {
	nav() {
		return [
			{
				slug: 'overview',
				url: 'admin',
				label: 'Start',
			},
			{
				url: 'config',
				slug: 'config',
				label: 'Config',
			},
			{
				url: 'logs',
				slug: 'logs',
				label: 'Logfiles',
			},
			{
				url: 'export',
				slug: 'export',
				label: 'export',
			},
			{
				url: 'test',
				slug: 'test',
				label: 'test',
			},
			{
				url: 'dev',
				slug: 'dev',
				label: 'dev',
			},
		]
	},
}