;(function(){
'use strict';

Object.defineProperty(exports, "__esModule", {
  value: true
});

var _line = require('../../charts/line');

var _line2 = _interopRequireDefault(_line);

var _moment = require('moment');

var _moment2 = _interopRequireDefault(_moment);

var _utils = require('../../../service/utils');

var _utils2 = _interopRequireDefault(_utils);

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

exports.default = {
  components: { InzChartLine: _line2.default },
  props: { panelData: Object, filter: Object },
  watch: {
    filter: {
      deep: true,
      handler: function handler() {
        var _this = this;

        this.show = false;
        this.$nextTick(function () {
          return _this.show = true;
        });
      }
    }
  },
  data: function data() {
    return {
      show: true,
      color: {
        primary: '#419af6',
        secondary: '#becddc'
      }
    };
  },

  computed: {
    width: function width() {
      return window.innerWidth > 0 ? window.innerWidth : screen.width;
    },
    opts: function opts() {
      return {
        height: 250,
        width: _utils2.default.screenWidth() < 700 ? _utils2.default.screenWidth() - 20 : 700,
        responsive: true,
        maintainAspectRatio: false,
        scales: {
          yAxes: [{
            ticks: {
              beginAtZero: true
            }
          }]
        }
      };
    },
    colorSets: function colorSets() {
      return [{ "borderColor": "#419af6", "backgroundColor": "#419af6" }, { "borderColor": "#becddc", "backgroundColor": "#becddc" }];
    },
    curDate: function curDate() {
      var m = (0, _moment2.default)();
      var F = this.filter;
      switch (F.type) {
        case 'week':
          m.add(F.start, 'weeks');
          break;
        case 'month':
          m.add(F.start, 'months');
          break;
        case 'year':
          m.add(F.start, 'years');
          break;
      }
      return m;
    }
  }
};
})()
if (module.exports.__esModule) module.exports = module.exports.default
var __vue__options__ = (typeof module.exports === "function"? module.exports.options: module.exports)
if (__vue__options__.functional) {console.error("[vueify] functional components are not supported and should be defined in plain js files using render functions.")}
__vue__options__.render = function render () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return (_vm.show && _vm.panelData && _vm.panelData.chart)?_c('div',{staticClass:"inz-panel-performance--chart mv-1"},[_c('inz-chart-line',{attrs:{"chartdata":_vm.chartData,"options":_vm.opts,"width":590,"height":300}})],1):_vm._e()}
__vue__options__.staticRenderFns = []
if (module.hot) {(function () {  var hotAPI = require("vue-hot-reload-api")
  hotAPI.install(require("vue"), true)
  if (!hotAPI.compatible) return
  module.hot.accept()
  if (!module.hot.data) {
    hotAPI.createRecord("data-v-f59e57ee", __vue__options__)
  } else {
    hotAPI.reload("data-v-f59e57ee", __vue__options__)
  }
})()}