;(function(){
'use strict';

Object.defineProperty(exports, "__esModule", {
  value: true
});

var _assign = require('babel-runtime/core-js/object/assign');

var _assign2 = _interopRequireDefault(_assign);

var _utils = require('../service/utils');

var _utils2 = _interopRequireDefault(_utils);

var _xhr = require('../service/xhr');

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

exports.default = {
  name: 'inz-cube-info-images',
  props: {
    items: Array,
    canDelete: { type: Boolean, default: false }
  },
  computed: {
    imageUrls: function imageUrls() {
      return this.items.map(function (it) {
        var a = (0, _assign2.default)({}, it);
        a.url = it.url.replace('view', 'preview');
        return a;
      });
    }
  },
  methods: {
    remove: function remove($i) {
      if (this.items[$i].id) {
        _xhr.files.rm(this.items[$i].id);
      }
      this.$emit('rmImage', $i);
    },
    download: function download($i) {
      var it = this.items[$i];
      if (it.check && it.check === true) {
        _utils2.default.checkFile(it.url).then(function () {
          return _utils2.default.downloadFile(it.url);
        }).catch(function (error) {
          return _utils2.default.handleXhrError(error, 'fileDownload');
        });
      } else {
        _utils2.default.downloadFile(it.url);
      }
    }
  }
};
})()
if (module.exports.__esModule) module.exports = module.exports.default
var __vue__options__ = (typeof module.exports === "function"? module.exports.options: module.exports)
if (__vue__options__.functional) {console.error("[vueify] functional components are not supported and should be defined in plain js files using render functions.")}
__vue__options__.render = function render () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"inz-cube-info-images"},_vm._l((_vm.items),function(it,$i){return _c('div',{directives:[{name:"b-tooltip",rawName:"v-b-tooltip:hover",arg:"hover"}],key:$i,staticClass:"inz-cube-info-images--item",attrs:{"title":it.title}},[_c('img',{attrs:{"src":it.url,"width":"120","alt":"preview image"},on:{"click":function($event){return _vm.download($i)}}}),_vm._v(" "),(_vm.canDelete)?_c('inz-btn',{attrs:{"icon":"remove","size":"sm"},on:{"click":function($event){return _vm.remove($i)}}}):_vm._e()],1)}),0)}
__vue__options__.staticRenderFns = []
if (module.hot) {(function () {  var hotAPI = require("vue-hot-reload-api")
  hotAPI.install(require("vue"), true)
  if (!hotAPI.compatible) return
  module.hot.accept()
  if (!module.hot.data) {
    hotAPI.createRecord("data-v-bd54ddf6", __vue__options__)
  } else {
    hotAPI.reload("data-v-bd54ddf6", __vue__options__)
  }
})()}