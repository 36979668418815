/**
 * Basic Config
 */
let gMapsInstance = null

export const cf = {
	getGmapsInstance() {
		return gMapsInstance;
	},
	setGmapsInstance(v) {
		gMapsInstance = v;
		return gMapsInstance;
	},
	dateFormatMysql: 'YYYY-MM-DD',
	dateFormat: 'DD.MM.YY',
	dateYearFormat: 'DD.MM.YYYY',
	dateTimeFormat: 'DD.MM.YY HH:mm',
	timeFormat: 'HH:mm',
	dateFormatShort: 'D.M.Y HH:mm',
	defaultModal: {
		title: 'Please Confirm',
		okVariant: 'primary',
		okTitle: 'YES',
		cancelTitle: 'NO',
		cancelVariant: 'dust',
		footerClass: 'p-2',
		hideHeaderClose: false,
		centered: true,
	},
	googleMapApiKey: 'AIzaSyDmremJVeWz3lPbRxGDAYQFwVjhu_TEah0',
	features: {
		rmRecord: true,                // Delete of Main Records (contract, contact, insurance,...)
		generateCertificate: true,      // Generate Certificate PDF
		contractCancel: true,           // Cancel Contracts
		contractConfirm: true,          // Send Contract Confirmation,
	},
	licenseFor: 'smart group AG',
}