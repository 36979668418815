;(function(){
'use strict';

Object.defineProperty(exports, "__esModule", {
  value: true
});

var _persistent = require('../store/persistent');

var _persistent2 = _interopRequireDefault(_persistent);

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

exports.default = {
  name: 'inz-apiswitch',
  methods: {
    changeApi: function changeApi(l) {
      _persistent2.default.commit('setApi', l);
      document.location.reload();
    }
  },
  computed: {
    currentLocale: function currentLocale() {
      return this.optsApi.find(function (it) {
        return _persistent2.default.state.api === it.id;
      }).text;
    },
    optsApi: function optsApi() {
      return [{ id: 'live', text: 'Production' }, { id: 'test', text: 'Staging' }, { id: 'dev', text: 'Development' }];
    }
  }
};
})()
if (module.exports.__esModule) module.exports = module.exports.default
var __vue__options__ = (typeof module.exports === "function"? module.exports.options: module.exports)
if (__vue__options__.functional) {console.error("[vueify] functional components are not supported and should be defined in plain js files using render functions.")}
__vue__options__.render = function render () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('b-nav',{staticClass:"inz-apiswitch-container"},[_c('li',{staticClass:"inz-apiswitch-icon"},[_c('fa',{attrs:{"icon":"server"}})],1),_vm._v(" "),(_vm.optsApi)?_c('b-nav-item-dropdown',{staticClass:"inz-apiswitch",attrs:{"text":_vm.currentLocale,"dropup":"","no-caret":""}},_vm._l((_vm.optsApi),function(it){return _c('b-dropdown-item',{key:it.id,attrs:{"href":"#"},on:{"click":function($event){return _vm.changeApi(it.id)}}},[_vm._v("\n      "+_vm._s(_vm.$t(it.text))+"\n    ")])}),1):_vm._e()],1)}
__vue__options__.staticRenderFns = []
if (module.hot) {(function () {  var hotAPI = require("vue-hot-reload-api")
  hotAPI.install(require("vue"), true)
  if (!hotAPI.compatible) return
  module.hot.accept()
  if (!module.hot.data) {
    hotAPI.createRecord("data-v-2f5d0970", __vue__options__)
  } else {
    hotAPI.reload("data-v-2f5d0970", __vue__options__)
  }
})()}