;(function(){
'use strict';

Object.defineProperty(exports, "__esModule", {
  value: true
});

var _cubeInfo = require('./cubeInfo');

var _cubeInfo2 = _interopRequireDefault(_cubeInfo);

var _lodash = require('lodash');

var _ = _interopRequireWildcard(_lodash);

function _interopRequireWildcard(obj) { if (obj && obj.__esModule) { return obj; } else { var newObj = {}; if (obj != null) { for (var key in obj) { if (Object.prototype.hasOwnProperty.call(obj, key)) newObj[key] = obj[key]; } } newObj.default = obj; return newObj; } }

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

exports.default = {
  name: 'ui-record-meta',
  components: { InzCubeInfo: _cubeInfo2.default },
  props: {
    infos: { type: Array },
    record: { type: Object },
    showChangedBy: { type: Boolean, default: true },
    showLatestLogin: { type: Boolean, default: true }
  },
  computed: {
    allInfos: function allInfos() {
      if (!_.isObject(this.record) && _.size(this.infos) < 1) {
        return null;
      }
      var res = [];
      var $R = this.record;

      if ($R) {
        if ($R.dateCreated) {
          res.push({ label: 'label.dateCreated', value: $R.dateCreated, type: 'datetime' });
        }
        if ($R.dateUpdated) {
          res.push({ label: 'label.dateUpdated', value: $R.dateUpdated, type: 'datetime' });
        }
        if (true === this.showChangedBy) {
          var lastChange = $R.updatedBy ? $R.updatedBy : $R.createdBy;
          if (lastChange) {
            res.push({ label: 'label.updatedBy', value: lastChange, 'type': 'contact' });
          }
        }
        if (this.showLatestLogin === true && $R.latestLogin) {
          res.push({ label: 'label.dateLatestLogin', value: $R.latestLogin, type: 'datetime' });
        }
        if ($R.exportInfo) {
          _.each($R.exportInfo, function (it) {
            return res.push({ label: 'label.recordExported', value: it.dateTransfer, type: 'datetime' });
          });
        }
      }

      _.each(this.infos, function (it) {
        return res.push(it);
      });

      return res;
    }
  }
};
})()
if (module.exports.__esModule) module.exports = module.exports.default
var __vue__options__ = (typeof module.exports === "function"? module.exports.options: module.exports)
if (__vue__options__.functional) {console.error("[vueify] functional components are not supported and should be defined in plain js files using render functions.")}
__vue__options__.render = function render () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return (_vm.allInfos)?_c('inz-box',{attrs:{"variant":"white"}},[_c('inz-cube-info',{attrs:{"size":"sm","infos":_vm.allInfos}})],1):_vm._e()}
__vue__options__.staticRenderFns = []
if (module.hot) {(function () {  var hotAPI = require("vue-hot-reload-api")
  hotAPI.install(require("vue"), true)
  if (!hotAPI.compatible) return
  module.hot.accept()
  if (!module.hot.data) {
    hotAPI.createRecord("data-v-36f6f9fa", __vue__options__)
  } else {
    hotAPI.reload("data-v-36f6f9fa", __vue__options__)
  }
})()}