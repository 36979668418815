;(function(){
'use strict';

Object.defineProperty(exports, "__esModule", {
  value: true
});

var _customer = require('./form/customer');

var _customer2 = _interopRequireDefault(_customer);

var _object = require('./form/object');

var _object2 = _interopRequireDefault(_object);

var _baseControl = require('../../../../../base/baseControl');

var _baseControl2 = _interopRequireDefault(_baseControl);

var _basics = require('../contact/form/basics');

var _basics2 = _interopRequireDefault(_basics);

var _contact = require('../contact/form/contact');

var _contact2 = _interopRequireDefault(_contact);

var _address = require('../contact/form/address');

var _address2 = _interopRequireDefault(_address);

var _birthday = require('../contact/form/birthday');

var _birthday2 = _interopRequireDefault(_birthday);

var _expandable = require('../../../../../components/expandable');

var _expandable2 = _interopRequireDefault(_expandable);

var _price = require('./form/price');

var _price2 = _interopRequireDefault(_price);

var _lodash = require('lodash');

var _ = _interopRequireWildcard(_lodash);

function _interopRequireWildcard(obj) { if (obj && obj.__esModule) { return obj; } else { var newObj = {}; if (obj != null) { for (var key in obj) { if (Object.prototype.hasOwnProperty.call(obj, key)) newObj[key] = obj[key]; } } newObj.default = obj; return newObj; } }

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

exports.default = {
  extends: _baseControl2.default,
  name: 'inz-export-contract-editor',
  computed: {
    infos: function infos() {
      var rec = this.val;
      var items = [];
      items.push({ label: 'label.partner', value: rec.partner.name });
      items.push({ label: 'label.priceOfContractConclusion', value: rec.priceOfContractConclusion, type: 'price' });
      items.push({ label: 'label.dateOfContract', value: rec.dateCreated, type: 'date' });
      if (_.isObject(rec.insuranceObject)) {
        items.push({ label: 'label.dateOfPurchase', value: rec.insuranceObject.dateOfPurchase, type: 'date' });
      }

      return items;
    }
  },
  components: {
    InzContractFormPrice: _price2.default,
    InzExpandable: _expandable2.default,
    InzContactFormBirthday: _birthday2.default,
    InzContactFormAddress: _address2.default,
    InzContactFormContact: _contact2.default,
    InzContactFormBasics: _basics2.default,
    InzContractFormObject: _object2.default,
    InzContractFormCustomer: _customer2.default
  }
};
})()
if (module.exports.__esModule) module.exports = module.exports.default
var __vue__options__ = (typeof module.exports === "function"? module.exports.options: module.exports)
if (__vue__options__.functional) {console.error("[vueify] functional components are not supported and should be defined in plain js files using render functions.")}
__vue__options__.render = function render () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return (_vm.val)?_c('div',{staticClass:"inz-export-contract-editor"},[_c('inz-box',{attrs:{"variant":"white"}},[_c('inz-expandable',{attrs:{"title":"Vertrag und Produkte","is-collapsed":false}},[_c('template',{slot:"content"},[_c('inz-contract-form-price',{model:{value:(_vm.val),callback:function ($$v) {_vm.val=$$v},expression:"val"}}),_vm._v(" "),_c('inz-infolist',{attrs:{"infos":_vm.infos}})],1)],2),_vm._v(" "),_c('inz-expandable',{attrs:{"title":"Kunde"}},[_c('template',{slot:"content"},[_c('inz-contact-form-basics',{model:{value:(_vm.val.customer),callback:function ($$v) {_vm.$set(_vm.val, "customer", $$v)},expression:"val.customer"}}),_vm._v(" "),_c('inz-contact-form-contact',{model:{value:(_vm.val.customer),callback:function ($$v) {_vm.$set(_vm.val, "customer", $$v)},expression:"val.customer"}}),_vm._v(" "),_c('inz-contact-form-address',{model:{value:(_vm.val.customer),callback:function ($$v) {_vm.$set(_vm.val, "customer", $$v)},expression:"val.customer"}}),_vm._v(" "),_c('inz-contact-form-birthday',{model:{value:(_vm.val.customer),callback:function ($$v) {_vm.$set(_vm.val, "customer", $$v)},expression:"val.customer"}})],1)],2),_vm._v(" "),_c('inz-expandable',{attrs:{"title":"Versichertes Objekt"}},[_c('template',{slot:"content"},[_c('inz-contract-form-object',{model:{value:(_vm.val),callback:function ($$v) {_vm.val=$$v},expression:"val"}})],1)],2)],1)],1):_vm._e()}
__vue__options__.staticRenderFns = []
if (module.hot) {(function () {  var hotAPI = require("vue-hot-reload-api")
  hotAPI.install(require("vue"), true)
  if (!hotAPI.compatible) return
  module.hot.accept()
  if (!module.hot.data) {
    hotAPI.createRecord("data-v-443c432a", __vue__options__)
  } else {
    hotAPI.reload("data-v-443c432a", __vue__options__)
  }
})()}